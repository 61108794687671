<template>
  <div>
    <common-banner :bannerData="standardData.bannerData"></common-banner>

    <service-intro :infoData="standardData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h2>就您的供应链而言，您获取信息的速度越快越好。</h2>
            <p>Crane Worldwide Logistics 允许您连接我们的 API 和 EDI 解决方案，以更快、更明智的战略决策实现可持续运营。</p>
            <p class="gt-block"><strong>API（应用程序编程接口）</strong> 和 <strong>EDI（电子数据交换）</strong>
              允许不同的计算机系统和应用程序以标准化和自动化的方式通信和交换信息。
              API使系统之间的实时数据交换成为可能，而EDI则促进了以标准化电子格式交换商业文件。</p>
            <h3>我们知道您已经在考虑减少资源消耗和提高可持续性。 我们也是。</h3>
            <p>API 和 EDI 提供系统之间的无缝连接，减少资源消耗并提高可持续性。
              它们还允许对装运、订单、集装箱跟踪、文件检索等进行实时数据可见性。</p>
            <p><a href="https://developers.craneww.com/"><img alt="物流技术提高货运可见性" class="align_center"
                                                              src="https://www.craneww.com/site/assets/files/3764/logistics_technology_enhances_shipment_visibility.750x0-is.png"
                                                              width="750"></a></p>
            <h3>API集成</h3>
            <p>应用程序编程接口 (API) 是一种接口，它提供对应用程序或数据库中的服务功能和数据的编程访问。</p>
            <p class="gt-block"><a href="https://developers.craneww.com/">Crane 不断增长的 API 集合</a>
              提供与我们的运输和仓库应用程序的集成，以实现实时/按需数据可见性。</p>
            <p>客户可以注册访问我们的 API。 注册过程完成后，客户将收到一个安全令牌以访问我们的测试和/或生产
              API，并可以开始发送和接收数据。</p>
            <h3>EDI 集成</h3>
            <p>EDI 或电子数据交换是一种在业务合作伙伴或组织之间以标准格式交换文档的安全方式。</p>
            <p class="gt-block">Crane 利用行业领先的任意对任意集成应用程序，使我们能够使用和创建各种格式的 EDI 文档。 <a
                href="https://developers.craneww.com/">使用我们的 EDI 消息</a>，客户在此供应链中获得可见性和可预测性，并可以做出更明智的决策。
            </p>
            <p><a href="/site/assets/files/3764/client_integration_standard_offering.pdf"><img
                alt="CWW 客户端集成标准产品" class="align_right hidpi"
                src="https://www.craneww.com/site/assets/files/3764/cww_client_integration_standard_offering.428x0-is-hidpi.png"
                width="428"></a></p>
            <h3>API 和 EDI 的优势</h3>
            <p class="gt-block"><strong>永续发展</strong></p>
            <ul>
              <li>减少纸张使用。</li>
              <li>自动化和简化流程。</li>
              <li>提高效率。</li>
            </ul>
            <p class="gt-block"><strong>速度</strong></p>
            <ul>
              <li>更快的交货时间。</li>
              <li>改进的数据可访问性。</li>
              <li>自动化流程。</li>
            </ul>
            <p class="gt-block"><strong>成本节约：</strong></p>
            <ul>
              <li>降低劳动力成本。</li>
              <li>降低间接成本。</li>
              <li>降低运输成本。</li>
            </ul>
            <p class="gt-block"><strong>提高准确性</strong></p>
            <ul>
              <li>提高数据准确性。</li>
              <li>改进的数据完整性。</li>
              <li>减少数据输入错误，无需手动输入，因此出现人为错误的空间更小。</li>
              <li>实时供应链监控是可能的，使您能够就您的库存和发货做出明智的决定。</li>
            </ul>
            <p class="gt-block"><strong>消除不良数据</strong></p>
            <ul>
              <li>提高数据质量。</li>
              <li>随时获取准确信息。</li>
              <li>及早识别和预防计费或运输中的错误。</li>
              <li>降低信息传输过程中数据丢失的风险。</li>
            </ul>
            <blockquote><p class="gt-block">这不仅仅是为了节省时间或金钱——而是为了更快地做出更明智的决策，这样您就可以花更少的时间担心物流问题，而更多地关注最重要的事情：您的客户。
              <strong><a
                  href="https://cranewwmktg.powerappsportals.com/cwwediapi_integration/">立即联系我们的集成专家！</a></strong>
            </p></blockquote>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">相关案例研究</h3>
        <press-list type="case"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {standardData} from "@/assets/mock/technology";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      standardData
    }
  }
}
</script>

<style scoped>

</style>