<template>
  <div>
    <common-banner :bannerData="purchaseData.bannerData"></common-banner>

    <service-intro :infoData="purchaseData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><p><img alt="PINPOINT标志" class="align_center"
                                                       src="https://www.craneww.com/site/assets/files/3080/pinpoint_logo.368x0-is.png"
                                                       width="368"></p>
            <h2><strong>什么是精准？</strong></h2>
            <p>PINPOINT 是 Crane Worldwide 采购订单 (PO) 管理解决方案的名称，可提供客户供应链中近乎实时的端到端项目可见性，直至
              PO、订单和项目详细信息。 PO 管理生命周期从 Crane 的客户创建 PO 开始，发给他们的供应商，结束于供应商完成或取消所有
              PO 项目数量。 客户、供应商和 CWW 的可见性是通过 Pinpoint 网络门户提供的。</p>
            <p>虽然 PINPOINT 代表我们的 PO 管理解决方案面向客户的品牌，但在内部，它包含多个集成应用程序，其核心是 Amber
              Road 的贸易自动化基于云的物流平台。 贸易自动化 (TA) 为我们的大部分采购订单管理功能提供支持，包括基于 Web
              的客户和供应商门户以及来自贸易自动化 Web 门户的独立报告。 贸易自动化与我们的 TMF Enterprise Forwarding
              核心系统直接集成，确保在 Crane 处理相关运输时无缝转换运输细节。</p>
            <h3><strong>PINPOINT 是如何工作的？</strong></h3>
            <p><a href="/site/assets/files/3080/how_pinpoint_works.png"><img alt="PINPOINT 的工作原理"
                                                                             class="align_center"
                                                                             src="https://www.craneww.com/site/assets/files/3080/how_pinpoint_works.1044x0-is.png"
                                                                             width="1044"></a></p>
            <h3><strong>为什么是 PINPOINT？</strong></h3>
            <ul>
              <li>省钱</li>
              <li>更短的行驶时间</li>
              <li>责任</li>
              <li>提升品牌曝光性</li>
              <li>报告</li>
            </ul>
            <h3><strong>PINPOINT 管理什么？</strong></h3>
            <ul>
              <li>上游跟踪和出厂日期</li>
              <li>管理预订和例外情况，采购订单结束和短期</li>
              <li>整合计划</li>
              <li>模式管理</li>
              <li>将运输详细信息链接到订单项目详细信息</li>
            </ul>
            <h3><strong>定制解决方案</strong></h3>
            <ul>
              <li>采购订单开始</li>
              <li>采购订单详情</li>
              <li>SKU/物品追踪</li>
              <li>采购订单拆分</li>
              <li>时间管理</li>
              <li>供应商管理</li>
            </ul>
            <blockquote><p class="gt-block"><strong>获得对上游采购订单的控制：可见性和供应商管理</strong></p>
              <p>当我们的一位汽车制造客户向我们提出上游进口供应链问题时，我们进行了深入研究以了解问题并提供解决方案。
                我们发现大多数问题都与订单和项目级别缺乏可见性、采购订单流程控制以及供应商管理有关。 我们提出了 Crane 的
                PINPOINT 解决方案来帮助客户控制他们的采购订单流程和供应商管理。
                这带来了可衡量的节省、新发现的效率和更高水平的客户满意度。</p>
              <p class="gt-block"><strong><a
                  href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%A1%88%E4%BE%8B%E7%A0%94%E7%A9%B6/%E9%87%87%E8%B4%AD%E8%AE%A2%E5%8D%95%E7%AE%A1%E7%90%86/">在此处阅读
                PINPOINT 案例研究</a></strong></p></blockquote>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">额外的资源</h3>
        <press-list></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {purchaseData} from "@/assets/mock/technology";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      purchaseData
    }
  }
}
</script>

<style scoped>

</style>