<template>
  <div>
    <common-banner :bannerData="addedData.bannerData"></common-banner>

    <service-intro :infoData="addedData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>增值物流服务</h1>
            <p>为了应对常见的行业挑战，Crane Worldwide Logistics 提供通常被称为 VAS 的增值服务，旨在满足客户的需求。 Crane
              Worldwide Logistics
              提供全方位的仓储服务，以确定我们物流客户的特定需求，并使用可增强供应链运营的最有效解决方案为其供应链增加价值。</p>
            <p>从简单到复杂的物流挑战，我们提供的物流解决方案将长期带来收益。
              我们的增值服务创建了物流交付模型，可以改善库存和运输成本，从而提高您的运营效率，从而节省时间和金钱。</p>
            <p>什么是增值服务？ 简单的定义是在仓库中执行通常在内部进行的功能的过程。&nbsp;</p>
            <p>对于实施例：</p>
            <ul>
              <li>集中配送服务，以解决卡车运力不足的问题</li>
              <li>紧急运输的时间紧迫服务</li>
              <li>合并过境业务以将货运合并到最终客户</li>
              <li>咨询服务，提供创新的解决方案来应对不断变化的国际贸易格局</li>
              <li>为您的库存需求和产品交付要求定制解决方案。
                支持产品定制的增值服务，例如产品检验、配套和包装、总装、贴标签/重新贴标签、捆绑等
              </li>
            </ul>
            <p>
              除了传统的运输和仓储服务，我们的物流专家还可以通过其他服务来改善您的运营流程，从而为您的供应链运营增加价值，从而使您可以专注于最终客户。</p>
            <h3>物流伙伴关系是成功的关键</h3>
            <p>与客户合作是成功的关键，我们的增值服务可实现各种不同的物流方案，以最大程度地减少库存和运输成本并优化供应链。
              如果您面临挑战并需要专家建议，请与团队联系。 我们的增值服务可让您满足客户的各种需求，同时缩短交货时间。</p>
            <div class="card card_size_lg bg_primary-dark">
              <div class="card__body c_white">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item spacing"><h2 class="h3 c_white">接收最新的物流新闻</h2>
                    <p class="text_size_lg">所有最新最快的市场信息可以直接发送到您的收件箱</p></div>
                  <div class="grid__item size_auto spacing text_align_center">
                    <div><a class="button button_size_lg button_color_secondary"
                            href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/">成为会员</a>&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">额外的资源</h3>
        <press-list type="case"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {addedData} from "@/assets/mock/service";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "air",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      addedData
    }
  }
}
</script>

<style scoped>

</style>