<template>
  <div>
    <div class="section section_size_lg bg_primary-dark">
      <div class="section__container container">
        <div class="section__intro type type_inverted"><h1 class="h1 c_white">现代奴隶制声明</h1>
          <p class="text_lead">我们承认，现代奴隶制是一个普遍存在的全球问题，任何行业或地区都无法幸免于此类危险。 因此，Crane
            Worldwide Logistics 采取了此处提到的措施来保护和促进我们业务和供应链中的人权。</p></div>
      </div>
      <img alt=""
           class="section__background" height="800" src="https://www.craneww.com/site/assets/files/3607/cww_modern_slavery_statement.1200x800.jpg" width="1200">
      <div class="section__screen bg_primary-dark"></div>
    </div>
    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h2>Crane Worldwide Logistics LLC 的现代奴隶制声明</h2>
          <p>本声明是根据 54 年英国现代奴隶制法案（“现代奴隶制法案”）第 2015 条作出的，并规定了 Crane Worldwide Logistics
            LLC、其附属公司和子公司（统称为“Crane Worldwide
            Logistics”、“我们、“我们”和“我们的”）正在采取措施解决我们的业务和供应链中现代奴隶制法案所定义的现代奴隶制风险。</p>
          <p>在我们的全球运营中，Crane Worldwide Logistics 致力于以合法、道德和对社会负责的方式开展业务，并培养遵守适用法律、规则和法规的文化，包括与人权相关的法律、规则和法规。
            这一承诺延伸到我们与供应商的关系。</p>
          <p>我们承认，现代奴隶制是一个普遍存在的全球问题，任何行业或地区都无法幸免于此类危险。 因此，Crane Worldwide
            Logistics 采取以下措施来保护和促进我们业务和供应链中的人权。</p>
          <h3>关于 Crane Worldwide Logistics 及其供应链</h3>
          <p class="gt-block">Crane Worldwide Logistics 是供应链服务的领导者，提供个性化的解决方案来解决客户的物流挑战。
            Crane Worldwide Logistics 总部位于德克萨斯州休斯顿，在 120 个国家/地区的 30 个地点开展业务，提供海运、空运和合同物流解决方案。
            该公司在英国有四个分支机构——阿伯丁、伦敦、伯明翰-米德兰兹和曼彻斯特。<br>由于我们的产品和服务组合的多样性，我们的供应链复杂且地域广泛。
            我们不从矿山、冶炼厂或精炼厂购买原材料，而是依靠我们的供应商向我们提供我们产品中所含的组件和材料。</p>
          <h3>相关政策和其他举措</h3>
          <p>为了在我们的业务和供应链中促进对社会负责、道德和合法的行为，Crane Worldwide Logistics
            实施了一套适用的政策和其他举措：</p>
          <ul>
            <li><strong>行为准则</strong>: Crane Worldwide Logistics
              商业行为准则定义了有关道德商业行为的基本原则和价值观，这些原则和价值观适用于我们的员工、承包商、顾问和董事会成员，以及我们的代理和供应商。
              Crane Worldwide Logistics 商业行为准则规定，此类个人和实体必须尊重并遵守我们公司开展业务所在国家、州和地区的适用法律、规则和法规。
              我们从广义上解释这些标准，并要求道德行为和遵守 Crane 全球物流行为准则，以确保现代奴隶制不存在于我们的供应链或我们业务的任何部分。
            </li>
            <li><strong>合规热线</strong>: Crane Worldwide Logistics 及其供应商的员工可在全球范围内使用 Crane Worldwide
              Logistics 合规热线。 鼓励员工和供应商使用合规热线报告任何非法、不道德或有问题的行为，包括但不限于疑似现代奴役事件或与涉嫌不遵守
              Crane Worldwide Logistics 行为准则有关的其他情况. 此类报告将在 Crane Worldwide Logistics Trade
              合规和/或人力资源部门的指导和指导下进行调查。 Crane Worldwide Logistics 禁止对善意提出疑虑、问题或投诉的任何个人进行报复。
              <ul>
                <li>电话：+1 (877) 571-3858</li>
                <li>电子邮箱： <a href="mailto:craneww@signius.com">craneww@signius.com</a></li>
                <li>网站： <a href="http://thecompliancepartners.com/crane">thecompliancepartners.com/crane</a></li>
              </ul>
            </li>
            <li><strong>供应商行为准则</strong>: Crane Worldwide Logistics 供应商行为准则概述了对公司直接供应商的基本期望。
              直接供应商应遵守其经营所在的每个国家、州和地方的所有适用法律、法规和法规，包括但不限于与劳工和就业、职业健康和安全、贸易相关的法律和法规和人权，以及奴隶制和人口贩运。
              Crane Worldwide Logistics 保留拒绝与任何不遵守适用法律、法规和法规的直接供应商开展业务的权利。
            </li>
            <li><strong>与奴役和人口贩运有关的政策声明</strong>: Crane 与奴隶制和人口贩运相关的全球物流政策声明确认了我们尊重和支持保护和促进人权的国际原则的承诺。
            </li>
            <li><strong>购买条款和条件</strong>: Crane Worldwide Logistics 采购条款和条件要求直接供应商遵守所有适用法律，包括与奴役和人口贩运有关的法律。
              鉴于我们努力防止供应链中的现代奴隶制以及我们与直接供应商的工作关系的性质，我们不会进行独立的、未经通知的供应商合规审计。
            </li>
          </ul>
          <h3>尽职调查</h3>
          <p>Crane Worldwide Logistics 对其供应商进行审查，并要求他们遵守公司的供应商行为准则，包括关于现代奴隶制的规定。
            Crane Worldwide Logistics 将对任何员工、供应商或第三方向其提出的任何现代奴役指控进行调查，并在适当的情况下采取有针对性的行动予以回应。
            这些行动可能包括停止与被认为与现代奴隶制有联系的任何一方的业务。</p>
          <h3>风险评估</h3>
          <p>Crane Worldwide Logistics 将继续监控其业务运营和关系，以确定可能存在现代奴隶制风险的领域。
            它将在这项工作中使用本报告中确定的工具和信息，并将更新其政策和实践以应对可能确定的任何风险。</p>
          <h3>产品培训</h3>
          <p>
            为了让我们的员工了解我们关于道德和合法商业行为的原则和政策，克瑞全球物流行为准则已在公司网站上公布，并在雇用时提供给所有新员工。</p>
          <p>
            此外，还向员工提供培训，以确保他们了解与现代奴隶制相关的法律法规，并提出要考虑的因素，以降低我们直接供应链中现代奴隶制的风险。</p>
          <h3>责任和进一步的步骤</h3>
          <p>为了降低我们业务和供应链中现代奴隶制的风险，Crane Worldwide Logistics 在合规、人力资源、财务、法律和行政部门之间采用了跨职能的协作方法。
            如果在英国或国际上发现或怀疑任何现代奴隶制事件，Crane Worldwide Logistics 将酌情采取纠正措施。</p>
          <p>Crane Worldwide Logistics 致力于不断改进其在这一领域的表现，并将继续采取行动来检测、更好地了解和解决我们业务和供应链中现代奴隶制的潜在风险。
            作为此过程的一部分，我们将定期审查我们的政策和程序，以帮助保持对相关法律和法规的遵守。</p>
          <h3>董事会批准</h3>
          <p>本声明构成 Crane Worldwide Logistics 截至 31 年 2022 月 XNUMX 日财政年度的英国现代奴隶制声明。</p>
          <p>CWL Holdings 于 22 年 2022 月 XNUMX 日批准了这份 UK Modern Slavery Statement, LLC 董事会，作为 Crane
            Worldwide Logistics LLC 的唯一成员，并将酌情每年进行审查和更新。</p>
          <p class="gt-block"><strong>Jared Crane - 总裁</strong></p>
          <blockquote><p class="gt-block"><a href="/site/assets/files/3607/cww_modern_slavery_statement_fy_2022.pdf">下载我们的现代奴隶制声明的副本。</a>
          </p></blockquote>
        </div>
      </div>
    </div>
    <quotation></quotation>
  </div>
</template>

<script>
import Quotation from "@/components/quotation.vue";

export default {
  name: "statement",
  components: {Quotation}
}
</script>

<style scoped>

</style>