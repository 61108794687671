<template>
  <div>
    <common-banner :banner-data="leaderData.bannerData"></common-banner>
    <div class="section section_size_lg">
      <div class="container container_size_sm spacing_xl">
        <div class="grid grid_size_lg justify_center">
          <div v-for="(item,index) in leaderData.list" :key="index" class="grid__item size_6 size_4_sm size_3_md">
            <a
                :href="'/leader?id='+item.id"
                class="d_block spacing text_align_center">
              <img
                  :src="item.avatar" alt=""
                  class="radius_circle" height="100" width="100">
              <div>
                <h3>{{ item.name }}</h3>
                <p>{{ item.position }}</p></div>
            </a>
          </div>

        </div>
      </div>
    </div>

    <div class="section bg_secondary">
      <div class="section__container container container_size_sm">
        <blockquote class="spacing_lg text_lead c_white text_align_center"><p>“我们的目标是继续建立一个挑战传统供应链方法的组织。
          通过使用最先进的技术和一组客户拥护者团队，我们致力于将客户放在第一位，创建创新的全球运输和物流服务，以高效，经济高效的方式应对客户的挑战。”</p>
          <footer><h3>Jim Crane</h3>
            <p>主席</p></footer>
        </blockquote>
      </div>
    </div>

    <join></join>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {leaderData} from "@/assets/mock/about";
import Join from "@/components/join.vue";

export default {
  components: {CommonBanner,Join},
  data() {
    return {
      leaderData
    }
  }
}
</script>

<style scoped>

</style>