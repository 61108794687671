<template>
  <div>
    <common-banner :bannerData="businessData.bannerData"></common-banner>

    <service-intro :infoData="businessData.info"></service-intro>
    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>经济高效的电子商务解决方案</h1>
            <p>我们热衷于帮助我们的客户信守对客户的承诺。 消费者现在比以往任何时候都更需要快速运输、端到端产品可见性和最佳客户服务。
              这就是为什么拥有可靠的电子商务物流合作伙伴可以帮助您的企业应对任何规模的挑战、最大限度地提高供应链效率、降低运输成本并改善整体客户体验。</p>
            <div class="card card_size_lg bg_primary-dark">
              <div class="card__body c_white">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item spacing"><h2 class="h3 c_white">电子商务货件追踪器</h2>
                    <p class="text_size_lg">快速检查您的电子商务发货进度。 访问起重机 eTower 平台</p></div>
                  <div class="grid__item size_auto spacing text_align_center">
                    <div><a class="button button_size_lg button_color_secondary" href="http://crane.etowertech.com/">立即追踪</a>&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p>&nbsp;</p>
            <p>我们强大的结构提供了灵活性，可以加速您的业务模式的增长，而无需在内部管理电子商务物流。
              通过将零售和电子商务整合到全渠道分销业务中，我们可以优化所需的基础设施并利用跨渠道协同效应使您的业务保持最佳状态。</p>
            <h2>速度、准确性和安心</h2>
            <p>让我们构建一个无缝集成库存能力、劳动力和高级自动化的定制解决方案。</p>
            <ul>
              <li>可靠的跟踪</li>
              <li>准时运输</li>
              <li>先进的技术</li>
              <li>全球客户支持</li>
            </ul>
            <h2>一流的物流解决方案</h2>
            <p>
              我们通过使用我们自学的货运服务、灵活的报关能力以及最后一英里交付，为从始发地到目的地直接发送电子商务包裹提供专门的通道服务。</p>
            <ul>
              <li>比快递或邮政更便宜、更快捷的解决方案</li>
              <li>减少运输时间和降低成本</li>
              <li>电子清关</li>
              <li>24/7 端到端可见性</li>
            </ul>
            <p class="gt-block"><strong>Crane Worldwide Logistics</strong> 为各种规模的电子商务企业提供可扩展的物流、履行和运输。
              您准备好保持在线业务正常运行了吗？ 单击下面的按钮以获取有关我们电子商务解决方案的更多信息。</p>
            <p><img alt="为企业主提供电子商务物流解决方案" class="align_center hidpi"
                    src="https://www.craneww.com/site/assets/files/3108/ecommerce_logistics_solutions_for_business_owners.png"
                    width="895"></p>
            <hr>
            <h2>更快更便宜的非正式入境</h2>
            <p>您是否将电子商务产品从中国运送到美国？ 我们的团队可以改进您的进口流程。</p>
            <blockquote><p class="gt-block"><strong>什么是第 321 节 86 型条目？</strong></p>
              <p>第 321 条第 86 条是进口到美国的货物的海关入境类型。 如果货物价值低于 800 美元，这种类型的入境允许进口货物而无需支付关税和税款。
                通过电子商务购买的商品通常在这个价值范围内。</p></blockquote>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">额外的资源</h3>
        <press-list type="market"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {businessData} from "@/assets/mock/service";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "air",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      businessData
    }
  }
}
</script>

<style scoped>

</style>