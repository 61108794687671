<template>
  <div>
    <common-banner :banner-data="contractData.bannerData"></common-banner>
    <service-intro :infoData="contractData.info"></service-intro>
    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>您需要强大的仓储物流解决方案吗？&nbsp;</h1>
            <p>
              我们的专家提供满足客户特定需求的仓储和物流解决方案。我们将提供熟练的运营经理和供应链设计专家，与您合作，为您的物流运营和配送服务提供最大价值。综合物流方法支持您业务运营的整体需求，提供咨询和及时的解决方案，我们的团队将作为关键合作伙伴与您合作，支持您的供应链决策。</p>
            <p>
              我们拥有全球范围内具有成本效益的仓储解决方案，使用最新技术来支持您的区域或全球运营，并通过集成解决方案成功制定您的供应链战略。</p>
            <p>利用我们灵活且可扩展的物流应用套件，Crane Worldwide Logistics 可以非常灵活、快速地实施您的仓库和配送计划，提供定制的物流解决方案来支持您的运营。
              此外，借助我们的“Any to Any”翻译工具集，我们可以利用客户现有的文件格式，通过集成来实施新项目。</p>
            <h3>仓库管理和履行</h3>
            <p class="gt-block"><em>收货、存储、拣货和配送</em></p>
            <ul>
              <li>我们强大的仓库管理系统 (WMS) 可以实时无纸化地优化移动和吞吐量。</li>
              <li>我们定制流程以适应客户的独特范围，但我们在单一系统平台和基础上进行。</li>
              <li>快速轻松地开拓新市场。 我们的平台提供了终极的可扩展性和灵活性。</li>
            </ul>
            <h3>库存管理</h3>
            <p class="gt-block"><em>拣货分配、时效、库存控制和产品完整性</em></p>
            <ul>
              <li>我们的客户通过使用我们的工具来管理库存水平，从而保持有效的足迹。</li>
              <li>有效轮换：我们的拣选分配策略通过确保客户的产品有效轮换来减少产品老化。
                <ul>
                  <li>先进先出 (FIFO)。</li>
                  <li>先过期先出 (FEFO)。</li>
                  <li>先制造先出 (FMFO)。</li>
                  <li>或者可以优化客户产品完整性的自定义策略。</li>
                </ul>
              </li>
              <li>自动警报：当产品接近保质期时，我们会提供自动通知。</li>
              <li>优化采购：我们启用端到端跟踪工具来监控库存水平并优化客户的支出。</li>
              <li>库存准确性：我们的团队将利用我们强大的周期盘点程序，帮助维护和报告准确的库存水平和财务状况。</li>
            </ul>
            <h3>数据和可见性</h3>
            <p class="gt-block"><em>报告、趋势和监控</em></p>
            <ul>
              <li>我们的在线门户实时提供客户库存和交易历史的全面可见性。
                <ul>
                  <li>直接通过专用门户对库存下订单。</li>
                  <li>使用交互式查询编辑、取消、批准订单。</li>
                  <li>随意运行报告，订阅电子邮件提醒，收藏收藏夹。</li>
                  <li>使用各种仪表板监控实时活动。</li>
                  <li>运行 KPI 指标以获得准时绩效。</li>
                  <li>通过与参与的承运人的交付进行跟踪和追踪。</li>
                </ul>
              </li>
              <li>我们提供可变且灵活的集成解决方案，将仓库里程碑数据提供给我们客户的 ERP（或其他）系统。
                <ul>
                  <li>API</li>
                  <li>EDI（XML、X12、CSV）</li>
                  <li>我们还提供标准规格或海关映射到您的规格。</li>
                </ul>
              </li>
            </ul>
            <h3>配电系统</h3>
            <p class="gt-block"><em>预订、运输和跟踪</em></p>
            <ul>
              <li>我们的解决方案为快递员提供自动预订，以减少错误和误发。</li>
              <li>利用我们的托管服务团队来优化负载整合和路由。</li>
              <li>添加自定义业务规则以优化收货人的成本和到达。</li>
              <li>我们提供标准文件（提单、装箱单、产品标签）或创建定制标签以满足您的业务或客户的要求。</li>
              <li>使用我们的在线门户跟踪和跟踪交付里程碑。</li>
            </ul>
            <h3>庭院管理</h3>
            <p class="gt-block"><em>集装箱轮换和滞留减少</em></p>
            <ul>
              <li>我们的专家与我们的客户合作，通过根据空闲时间轮换集装箱来避免滞留费。</li>
              <li>我们的码头预约日历管理车辆交通并跟踪整个院子里的运动。</li>
              <li>我们开发了登记和退房流程，以确保最高级别的货物安全。</li>
            </ul>
            <h3>我们可以提供多种物流解决方案，包括完整的合同物流能力：</h3>
            <p class="gt-block">仓储，仓储，远期库存， <strong>对外贸易区</strong>
              和保税解决方案、逆向物流、转运、原产地和目的地供应商整合计划、供应商管理库存
              (VMI)、供应商自有库存、增值流程、检验/维修/和轻型制造、零售合规、配套、池配送、熏蒸、出口包装等等。
              网络设计、项目管理、运输建模、合规与税务咨询。 除了我们的全球货运代理能力，包括 <strong><a
                  href="/service?type=air">空运</a></strong> 和 <strong><a
                  href="/service?type=sea">海运</a></strong> 我们支持的服务
              <strong>国际办事处的全球网络&nbsp;</strong>用于进出港运输。</p>
            <p>我们专业的合同物流团队将与您合作，全面了解您的物流挑战，并提供由专家设计的仓库和分销服务，以支持您现在和将来的供应链战略。
              需要仓库空间吗？ 寻求更多信息！</p>
            <hr>
            <h2>仓库聚光灯</h2>
            <p><img alt="起重机全球辛辛那提" class="align_right"
                    src="https://www.craneww.com/site/assets/files/1047/cincinnati_2.320x0-is.png" width="320">位于辛辛那提的
              3PL 仓库拥有超过 600 万平方英尺的 A 级工业仓储空间，地理位置优越，可增强分销和供应链网络，可直接通往附近的航空货运枢纽、大辛辛那提市场和周边中西部地区。
            </p>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">来自 Crane Worldwide 的最新消息……</h3>
        <press-list type="industry"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {contractData} from "@/assets/mock/service";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "contract",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      contractData
    }
  }
}
</script>

<style scoped>

</style>