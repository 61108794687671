<template>
<div v-if="currentLeader">
  <div v-html="currentLeader.detail"></div>
  <join></join>
</div>
</template>

<script>
import {leaderData} from "@/assets/mock/about";
import Join from "@/components/join.vue";

export default {
  name: "leader",
  components: {Join},
  data() {
    return{
      leaderData,
      currentLeader:null,
    }
  },
  mounted() {
    console.log(this.$route.query.id)
    console.log(this.leaderData.list)
    this.currentLeader = this.leaderData.list.find(item=>item.id === this.$route.query.id);
    console.log(this.currentLeader)
  }
}
</script>

<style scoped>

</style>