<template>
  <div>
    <common-banner :bannerData="energyData.bannerData"></common-banner>

    <service-intro :infoData="energyData.info"></service-intro>


    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type">
            <ul></ul>
            <h1>为什么选择 Crane Worldwide Logistics 来提供全球能源解决方案？</h1>
            <p>自成立以来，Crane Worldwide Logistics 一直专注于解决能源行业的问题。 我们制定新战略来降低当今能源物流公司的运输成本。
              我们理解并开发物流解决方案，通过以下方式缓解能源行业的独特问题：&nbsp;</p>
            <ul>
              <li>为我们的离岸交付导航季风和飓风季节的陷阱。</li>
              <li>优先考虑能源运输并为我们的客户制定具有成本效益的战略。</li>
              <li>与下游网络合作伙伴和合同合作以促进无缝切换。</li>
            </ul>
            <p class="gt-block">我们的供应链专业知识涵盖整个石油、天然气和 <a
                href="/industry?type=mining">采矿</a> 部门。 在行业领先技术的支持下，我们相信可能性，而不是局限性。
              无论我们是为天然气供应链提供服务，还是将货物运送到位于基础设施有限地区的设施，情况都是一样的。&nbsp;</p>
            <h2>什么是供应链能源解决方案？</h2>
            <p>供应链能源解决方案提供了必要的支持，以确保石油和天然气物流公司平稳高效地运行。
              能源货物物流专家在整个网络中工作，以消除海上和内陆设施缺货的风险。 物流能源网络依赖于不间断的能源供应物流链来保持所有流程的运行。
              对于经营敏感设备的公司来说尤其如此，它开始与项目物流能力相结合，将重型设备和机械运送到这些设施。&nbsp;</p>
            <ul>
              <li>石油和天然气运输。</li>
              <li>备件服务/采购/运输。</li>
              <li>协调努力以最大化资产生产。</li>
              <li>清洁能源供应商的物流服务。</li>
              <li>全面了解法律要求。</li>
              <li>出现问题时的响应能力（畜牧业）。</li>
              <li>能源运输跟踪。</li>
            </ul>
            <h2>能源物流与普通级供应链管理有何不同。</h2>
            <p>Crane Worldwide Logistics 提供真正的全球能源服务，重点是：</p>
            <ul>
              <li>能源物流包括采购、储存、运输（OTR 管道）和采矿部门。</li>
              <li>受不同国家的法律约束。</li>
              <li>需要专门的运输/重载考虑。</li>
              <li>对减少环境影响的重视程度更高。&nbsp;</li>
              <li>离岸和采矿作业的严酷、孤立的条件</li>
              <li>项目物流将重型设备和复杂机械搬迁和运送到不同地点——无论是海上还是内陆物流能源需求。</li>
            </ul>
            <p>Crane Worldwide Logistics 通过使设备脱机来帮助保护设备，并对出现的任何新问题做出快速反应。&nbsp;</p>
            <h2>全球能源服务和物流的类型。</h2>
            <p>我们的全球资源、全面的物流服务选择和信息技术是无可比拟的。
              我们提供的解决方案可让您的车队、现场和项目以最佳性能和生产水平运行。</p>
            <ul>
              <li>全球供应和再供应中心管理。</li>
              <li>采购订单管理和执行。</li>
              <li>专门的重型运输和钻机移动。</li>
              <li>钻机和海上物流支持。</li>
              <li>天然气公司的 OCTG 解决方案。</li>
              <li>可再生能源解决方案的选址和路线研究。</li>
              <li>全球饲养和代理服务。</li>
              <li>立管清洁服务。</li>
              <li>定位设备清理站。<br>&nbsp;</li>
            </ul>
          </div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">额外的资源</h3>
          <press-list type="case"></press-list>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {energyData} from "@/assets/mock/industry";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      energyData
    }
  }
}
</script>

<style scoped>

</style>