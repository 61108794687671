<template>
  <div>
    <common-banner :bannerData="projectData.bannerData"></common-banner>

    <service-intro :infoData="projectData.info"></service-intro>
    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>什么是项目物流？</h1>
            <p>项目物流是一种专门的运输形式，用于处理最重、最复杂的货物需求。 项目管理物流几乎也适用于任何行业，但在机械、制造、石油和天然气以及采矿行业尤其普遍。&nbsp;</p>
            <p>这种流行归因于对敏捷项目物流的需求不断增加，以应对不可预见的挑战和需求。&nbsp;</p>
            <p>例如，一家大型机械制造商可能需要运输重量超过400吨的物品，并且其尺寸可能需要用传统货船进行修改。
              船舶可能需要配备额外的压载物或设备来稳定物品，并且可能需要改进传统的固定能力。</p>
            <p>这就是为什么与能够处理这些独特细微差别的优质项目物流公司合作至关重要。&nbsp;</p>
            <h1>起重机项目物流解决方案如何帮助您？</h1>
            <p>我们的项目物流管理技能使我们能够反复设计和执行多式联运和物流解决方案。
              无论是满足项目货运需求还是与制造商合作管理始发地或目的地的运输，时间表和预算遵守也是处理这些负载时的核心考虑因素。&nbsp;</p>
            <p class="gt-block">超限/异常和重型货物运输，包括散杂货船租赁和部分租赁、滚装船、集装箱船的“UC”模式、驳船等都包含在我们的服务中。&nbsp;<span
                style="font-size:11pt; font-variant:normal; white-space:pre-wrap"><span
                style="font-family:Roboto,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span
                style="font-style:normal"><span
                style="text-decoration:none">Crane Project 物流的其他功能包括：</span></span></span></span></span></span>
            </p>
            <ul>
              <li>可变和专业服务，例如船舶备件物流和处理； 技术设计与咨询； 船舶期租，包括近海供应船 (OSV)/起锚拖船
                (AHT)、驳船、船员船和工程师，随时准备提供协助。&nbsp;
              </li>
              <li>项目物流 - 作为您的项目运输合作伙伴，方法说明、项目规划、咨询和路线调查都可以作为完整服务的一部分提供。
                与我们的工程团队相结合，克瑞可以提供首屈一指的世界级体验。 &nbsp;
              </li>
              <li>还提供海事服务，例如船舶代理、饲养、加油、采购和租赁、船员处理和签证/移民处理。</li>
            </ul>
            <p>从您的项目讨论开始，Crane Worldwide Logistics 项目物流专家就可以为您的供应链要求提供指导和支持。 凭借在世界各地运送
              OOG 和重型起重货物的经验，我们的团队将与您的工程师/员工合作，以确保您拥有最佳的运输选择。&nbsp;</p>
            <p>并非所有货物都能整齐地存放在集装箱中！ 我们完整的项目物流能力可以支持您的个性化需求。&nbsp;</p>
            <h3>其他利基服务</h3>
            <p>Crane Worldwide Logistics 开发了一系列适合近海行业的利基解决方案，包括船舶代理、管理服务、船员管理和杂项服务，包括加油、租赁和采购服务。
              除了我们的项目物流能力外，我们还可以支持您独特的物流需求。&nbsp;</p>
            <p>与起重机项目物流团队成员联系以开始使用。&nbsp;</p></div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">...了解更多</h3>
         <press-list type="case"></press-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {projectData} from "@/assets/mock/service";
import CommonBanner from "@/components/commonBanner.vue";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "land",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      projectData
    }
  }
}
</script>

<style scoped>

</style>