<template>
  <div>
    <common-banner :bannerData="manageData.bannerData"></common-banner>

    <service-intro :infoData="manageData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>通过管理运输建立有弹性的供应链</h1>
            <p>我们结合行业专业知识和工具来帮助客户协调他们的供应链网络。
              我们的方法将世界一流的技术，经过验证的流程以及合作伙伴关系与精选的既有运营商相结合，以提供单一的运输资源来满足您的业务需求。</p>
            <h2>内部解决方案架构师和项目经理</h2>
            <p class="gt-block">在运送货物时，我们的专家可以通过提供灵活的解决方案，专门的支持和端到端的可见性来改善您的供应链。
              我们提供量身定制的解决方案，通过减少您在设备，软件，设施和人员方面的投资来帮助您的企业降低成本。 我们的 <a
                  id="top-notch technology" href="#top-notch technology" name="top-notch technology">一流的技术</a>
              专家团队将为您的所有地面运输需求提供无缝的体验。</p>
            <p class="gt-block">您的伙伴关系对我们至关重要。 我们致力于长期提供价值，我们通过拥抱我们的价值来实现 <a
                href="/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC/%E8%B5%B7%E9%87%8D%E6%9C%BA%E6%96%B9%E5%BC%8F/">七个原则</a>。
              我们的供应链专业人员将监视策略和活动，以确保该计划现在和以后都能满足您的业务目标。</p>
            <h2>价值传递路线图</h2>
            <p>
              无论您的公司面临的是需要与货运供应商打交道，还是复杂的运费结构，亦或是手动订购和开票流程，我们都能为您提供相应地服务。我们能全面了解您的业务需要，这对解决物流难题来说是至关重要的。</p>
            <p><a href="/site/assets/files/2763/ctms_value_delivery_roadmap.jpg"><img
                alt="Crane Worldwide Logistics TMS价值交付路线图" class="align_center hidpi"
                src="https://www.craneww.com/site/assets/files/2763/ctms_value_delivery_roadmap.jpg" width="847"></a>
            </p>



            <p>&nbsp;</p></div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">额外的资源</h3>
      <press-list type="case"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {manageData} from "@/assets/mock/service";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "air",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      manageData
    }
  }
}
</script>

<style scoped>

</style>