<template>
  <div class="grid grid_l">
    <template v-for="(item,index) in currentList">
      <div v-if="index<4" :key="index" class="grid__item size_12 size_6_sm">
        <div class="spacing">
          <div class="media">
            <a
                :href="'/newsDetail?type=market&id='+item.id"
                class="media__image size_3">
              <img
                  :src="item.image || item.bannerImage" class="radius"
                  height="150"
                  width="150">
            </a>
            <div class="media__body">
              <a :href="'/newsDetail?type=market&id='+item.id">
                <h2 class="h4">{{ item.title }}</h2>
              </a>
              <p>{{ item.desc }}</p>
              <a
                  :href="'/newsDetail?type=market&id='+item.id"
                  class="button button_outline_secondary">
                <span>了解更多</span>
                <svg class="icon" role="img">
                  <use xlink:href="#icon-arrow-right"></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import {caseData, industryData, marketData} from "@/assets/mock/news";

export default {
  name: "pressList",
  props:{
    type:{
      type: String,
      default:'industry'
    }
  },
  data() {
    return {
    }
  },
  computed: {
    currentList() {
      let list = [];
      if(this.type === 'market'){
        list = marketData.list
      }else if(this.type === 'case'){
        list = caseData.list
      }else if(this.type === 'industry'){
        list = industryData.list
      }
      return list;
    }
  }
}
</script>

<style scoped>

</style>