<template>
  <div>
    <common-banner :bannerData="brokerData.bannerData"></common-banner>

    <service-intro :infoData="brokerData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>您的报关行</h1>
            <p class="gt-block">
              作为您在美国的报关行和远程位置归档器（RLF），我们运营着五个区域报关行执行中心（<strong>拉雷多</strong>,&nbsp;<strong>底特律</strong>,&nbsp;
              <strong>休斯顿</strong>,&nbsp;<strong>亚特兰大</strong><strong>,&nbsp;</strong>和<strong>洛杉矶</strong>），其中包括进口货物的北部和南部边境入境口岸。
              在Crane Worldwide
              Logistics，我们可以在美国的任何入境口岸提交条目，也可以作为报关行提交所有类型的条目，包括临时进口，通行证，仓库和外贸区条目，并帮助您了解相关信息。海关通关规则。
            </p>
            <p>Crane Worldwide Logistics海关经纪服务通过符合自动化商业环境的技术平台运作，并通过参与反恐海关与贸易伙伴关系来遵守严格的安全标准。
              在起重机上，“预检”是标准配置，对于空运，它是“起吊”的标准，从海上到货需要五天。 我们还提供24/7服务。</p>
            <ul>
              <li>全面的报关服务——所有港口、所有入境类型、所有 PGA、ISF、In-Bonds</li>
              <li>以客户为中心– T＆I计划，客户特定的SOP，QBR</li>
              <li>执行–符合自动化商业环境（ACE）</li>
              <li>合规性–政策和程序，入职，审核，培训和发展</li>
              <li>海关贸易反恐伙伴关系 (C-TPAT) 认证，有关美国海关与边境保护局 C-TPAT 计划的更多信息 <a
                  href="https://www.cbp.gov/border-security/ports-entry/cargo-security/ctpat">这里。</a>&nbsp;
              </li>
              <li>提供集成的供应链和“仅限经纪人”服务</li>
              <li>可以在下班后，周末和假日放假后提供支持</li>
              <li>远程位置文件管理器（RLF）</li>
              <li>电子数据交换（EDI）和平面文件功能</li>
              <li>预清除是标准协议</li>
              <li>活跃的南部和北部边境申报人</li>
              <li>基于Web的客户端门户，用于报告生成，数据分析和文档检索</li>
            </ul>
            <h3><strong>专注于我们全球客户在海关服务方面的挑战</strong></h3>
            <p>Crane Worldwide 的全球报关业务（包括加拿大报关业务）为进出口商提供各种服务，包括但不限于：关税管理、增值税延期设置、临时进出口管理、到岸成本管理、分类项目、退税，审计评估。
              全球贸易可能很复杂，我们的海关专家随时为您的业务增长提供支持，让您高枕无忧。</p>
            <p>我们目前在多个地区获得 AEO 认证，包括英国、爱尔兰、荷兰和台湾。
              我们与您所在国家/地区的海关当局密切合作，以确保您的进出口货物的无缝流动。</p>
            <p class="gt-block">让我们帮助您满足您的国际贸易要求，并通过我们的海关代理支持您的运输业务，我们的海关专家团队随时为您提供帮助！
              您可以阅读我们所有最新的贸易咨询通知 <strong><a
                  href="/news?type=trade">这里。</a></strong>
            </p>
            <p class="gt-block" style="text-align: center;"><a center=""
                                                               class="button button_size_lg button_color_secondary"
                                                               href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/"
                                                               onclick="ga('send', { &nbsp;&nbsp;hitType: 'event', &nbsp;&nbsp;eventCategory: 'Register', &nbsp;&nbsp;eventAction: 'Sign Up', &nbsp;&nbsp;eventLabel: 'Customs Broker' });"
                                                               rel="nofollow">注册以接收我们的时事通讯</a></p></div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">...了解更多</h3>
        <press-list type="case"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import ServiceIntro from "@/components/serviceIntro.vue";
import {brokerData} from "@/assets/mock/service";
import PressList from "@/components/pressList.vue";

export default {
  name: "broker",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      brokerData
    }
  }
}
</script>

<style scoped>

</style>