<template>
  <div>
    <common-banner :bannerData="goodsData.bannerData"></common-banner>

    <service-intro :infoData="goodsData.info"></service-intro>

    <div class="section bg_secondary">
      <div class="section__container container container_size_sm">
        <blockquote class="spacing_lg text_lead c_white text_align_center"><p>
          “我们的目标是通过我们的国际网络，利用最具成本效益和最可靠的供应链选项，为消费品贸易提供灵活敏捷的运输、存储和增值流程。”</p>
          <footer></footer>
        </blockquote>
      </div>
    </div>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>供应链：消费品&nbsp;</h1>
            <p>与供应链合作伙伴一起规划和合作可以提高效率，以克服消费品快节奏环境中不可预见的挑战。
              预测可能是一个挑战，供应链流程中的灵活性（包括运输方式、仓库位置和准确的库存可见性）可以对确保供应满足需求产生影响。
              Crane Worldwide 专注于从零部件到成品的快速消费品物流
              (FMCG)，可以通过实时可见的完整仓储服务、运输和最终交付来支持您的订单履行需求。</p>
            <h2>快速消费品（FMCG物流）</h2>
            <p class="gt-block">快速消费品是需求量大、周转率高、保质期短的非耐用消费品。
              此类别中的产品包括食品（包装食品、乳制品、面包和烘焙食品）、饮料、化妆品、盥洗用品、办公用品、非处方药、清洁产品、塑料制品和小型消费电子产品。
              这些商品的利润率很低，占全球消费者支出的一半以上。 供应链效率是具有实时可见性和高效运营的关键竞争优势。 Crane
              Worldwide 与我们的快速消费品客户合作，以确保在全球范围内实现具有成本效益的运营并降低总体运输成本。 和 <a
                  href="/service?type=contract">合同物流</a>, 货代
              (<a href="/service?type=air">AIR</a> 和 <a
                  href="/service?type=sea">海洋</a>), <a
                  href="/service?type=index">增值服务</a>
              和 <a
                  href="/service?type=index">电子商务解决方案</a>，您的综合物流解决方案可以量身定制，以满足您运营的确切需求。
            </p>
            <h2>服装物流</h2>
            <p class="gt-block">随着季节的变化，时尚物流和库存管理可能会很复杂，从高端设计和时装到高街时尚和运动装，克瑞全球物流以灵活性和适应性来支持不断变化的市场需求和季节性调整。
              我们的货运代理和物流服务与服装行业的零售商和制造商合作，可以通过最先进的技术和服务满足您的国际需求。 <a
                  href="/%E7%89%A9%E6%B5%81%E6%8A%80%E6%9C%AF/">实时可见性</a> 支持每一个采购订单。
              凭借从亚洲集运到全面合同物流服务的服装物流方面的丰富经验，请联系团队了解更多信息。</p>
            <h2>消费电子产品和玩具</h2>
            <p>最新趋势需要高度关注物流运营，随着受欢迎的产品达到需求高峰，可以触发 Crane Worldwide
              的全方位服务零售物流运营和供应链解决方案，以在需求高峰期间支持您的消费电子业务，跟踪您的库存并为您的客户提供服务期望在当今竞争激烈的零售市场..&nbsp;</p>
            <h2>生活方式品牌</h2>
            <p>许多生活方式品牌关注客户的兴趣、愿望和动机。 同样，Crane Worldwide Logistics 将专注于您的物流模型，以支持您期望的结果。
              从体育行业的生活方式选择到最新的时尚趋势，我们的目标是专注于您的物流运营，包括运营中心和配送中心，以确保您的供应链跨境顺畅运行。
              个人风格对消费者很重要，我们相信 B2B 物流运营也是如此，我们会灵活地满足您的物流需求。</p>
            <h2>饮料&nbsp;</h2>
            <p>从核心原料的运输和储存到最终产品的配送，克瑞全球以灵活的方式支持您的运营，以确保您的制造流程和零售店备有充足的必需品，让您的品牌保持在货架上。&nbsp;</p>
            <blockquote><h3><strong>加快上市速度</strong></h3>
              <p>交付期望很高，从额外的货运能力到仓库支持、跨境运营和增值解决方案，Crane Worldwide Logistics
                与我们的客户合作，以敏捷性和灵活性找到最具成本效益和效率的供应链解决方案。</p></blockquote>
            <p>&nbsp;</p></div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">行业洞见</h3>
          <press-list type="market"></press-list>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {goodsData} from "@/assets/mock/industry";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      goodsData
    }
  }
}
</script>

<style scoped>

</style>