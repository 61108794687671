<template>
  <div>
    <common-banner :bannerData="miningData.bannerData"></common-banner>

    <service-intro :infoData="miningData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>矿业物流</h1>
            <p class="gt-block">Crane Worldwide Logistics 为采矿业提供 <strong>全球运输解决方案</strong>,
              <strong>综合物流</strong>, <strong>项目管理</strong>， 和更多。 我们的物流服务
              <strong>提供端到端解决方案</strong> 通过实时车队跟踪、供应链映射和其他服务帮助您更好地管理您的业务。</p>
            <p class="gt-block">服务客户的最佳方式是为他们提供 <strong>能力</strong>、工具和资源来更好地管理他们的业务。
              我们在您的每一步都在这里为您服务，这样您就可以专注于最重要的事情：您的业务并确保您的矿场继续有效运营。&nbsp;
            </p>
            <h2>采矿业物流解决方案</h2>
            <p class="gt-block">采矿业是一个复杂的行业，而且每天都在变得更加复杂。
              随着行业的发展，公司需要找到扩大运营规模的方法，同时提高效率和降低成本，这就是物流的用武之地。我们提供全面的运输和项目物流，涵盖从设计到实施以及其间的一切。
              我们将帮助您的团队创建满足您需求的物流解决方案，无论是运输工程、现货装运、路线研究还是基本成本影响分析。
              我们的目标是帮助您取得成功，这样您就可以专注于最重要的事情： <strong>矿业！</strong></p>
            <p><img alt="采矿和矿产物流" class="align_center hidpi"
                    src="https://www.craneww.com/site/assets/files/3635/mining_and_mineral_logistics.png" width="597">
            </p>
            <h3>采矿业服务</h3>
            <p>为采矿业提供全方位服务的物流解决方案。
              我们的专家可以通过交钥匙项目执行和灵活的解决方案解决任何规模的供应链挑战，以便在必要时快速适应。</p>
            <ul>
              <li>全球海运、空运、陆运和铁路运输</li>
              <li>大件货物运输</li>
              <li>租船、起重、散货、积载和装卸服务</li>
              <li>仓库管理和材料控制</li>
              <li>供应链设计与工程</li>
              <li>物流顾问</li>
              <li>完整的项目管理</li>
              <li>风险分析</li>
              <li>成本影响分析</li>
              <li>运输工程和路线研究</li>
              <li>基础设施调节</li>
              <li>质量、健康、安全和环境 (QHSE) 系统分析</li>
              <li>项目货物海关管理</li>
              <li>逆向物流</li>
              <li>全球控制塔</li>
              <li>应急管理</li>
              <li>货运保险</li>
            </ul>
            <p><a
                href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%A1%88%E4%BE%8B%E7%A0%94%E7%A9%B6/%E7%9F%BF%E5%B1%B1%E8%AE%BE%E5%A4%87%E7%89%A9%E6%B5%81/"><img
                alt="矿业物流案例研究" class="align_center hidpi"
                src="https://www.craneww.com/site/assets/files/3635/mining_logistics_case_study.jpg" width="597"></a>
            </p>
            <h3>采矿和矿产物流</h3>
            <p>我们提供可靠且受人尊敬的物流来满足您的采矿需求。
              我们的专家已经确定了采矿项目各个阶段的共同挑战，包括深入勘探、可行性研究、施工、调试和退役阶段，以开发定制和可扩展的解决方案，以优化全球采矿供应链。
              我们的专业知识可以为矿业组织提供支持，无论他们处于哪个阶段。</p>
            <ul>
              <li>矿产勘探、钻探和开采公司</li>
              <li>机械和工具供应商</li>
              <li>矿物加工厂</li>
              <li>矿产进口商</li>
              <li>矿产出口商</li>
              <li>矿物加工厂</li>
              <li>土木工程承包商</li>
            </ul>
            <h3>矿业公司的物流管理</h3>
            <p class="gt-block">我们的采矿和矿物专家开发并测试了一整套物流解决方案，以解决采矿公司在项目生命周期中面临的许多问题。
              我们利用最新的 <strong><a href="/%E7%89%A9%E6%B5%81%E6%8A%80%E6%9C%AF/">供应链技术</a></strong>&nbsp;应对这些挑战：
            </p>
            <ul>
              <li>管理往返偏远地区的物流</li>
              <li>管理复杂的多式联运货运要求</li>
              <li>轨道车车队的资产利用</li>
              <li>管理风险</li>
              <li>缺乏专门的资源和系统</li>
            </ul>
            <blockquote><p class="gt-block">您准备好分析您的采矿流程、解决效率低下问题并将确定的节省直接用于您的利润线了吗？
              <a href="/quotation"><strong>立即与我们联系！</strong></a></p></blockquote>
          </div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">最新消息</h3>
          <press-list type="case"></press-list>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {miningData} from "@/assets/mock/industry";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      miningData
    }
  }
}
</script>

<style scoped>

</style>