import {serviceIndexData} from "@/assets/mock/service";

export const industryIndexData = {
  bannerData: {
    title: '行业应用',
    desc: "所有行业都面临着独特的运输和物流挑战，我们的目标是为客户提供量身定制的供应链解决方案，以满足他们的个性化需求。",
    image: require("@/assets/images/industry/banner.jpg")
  },
  industryList: [
    {
      name: '航空航天',
      desc: '为每项任务提供无与伦比的精确度、及时性和专业知识，开创航空航天和太空物流的先河。',
      path: '/industry?type=aerospace'
    },
    {
      name: '汽车行业',
      desc: '汽车供应链和物流解决方案，使我们的客户能够克服其物流和供应链方面的挑战。',
      path: '/industry?type=car'
    },
    {
      name: '消费品',
      desc: '在 Crane Worldwide Logistics，我们的快速消费品行业专家专注于从产品开发开始到最终最终用户交付的产品供应链的整体视图。 ',
      path: '/industry?type=goods'
    },
    {
      name: '邮轮，航海和款待',
      desc: '邮轮物流非常复杂，因此要确保在停靠港为邮轮准备好零件和维护，并补充船上的库存。 我们在这里为您提供帮助！',
      path: '/industry?type=cruise'
    },
    {
      name: '能源',
      desc: '先进的全球能源服务和物流为世界提供动力并保护您的投资和基础设施',
      path: '/industry?type=energy'
    },
    {name: '政府', desc: '成熟的供应链解决方案支持众多政府部门。', path: '/industry?type=government'},
    {name: '高科技行业', desc: '为高科技行业的客户提供优化的物流和供应链解决方案。', path: '/industry?type=tech'},
    {name: '工业', desc: '独特的供应链服务可解决制造和工业物流挑战。', path: '/industry?type=industrial'},
    {name: '生命科学', desc: '创建优化的生命科学供应链物流解决方案。', path: '/industry?type=life'},
    {
      name: '采矿',
      desc: '我们的行业专业知识可为您的采矿项目提供值得信赖且可扩展的物流服务，包括运输和储存金属、非金属和能源矿物以及多种类型的机械。',
      path: '/industry?type=mining'
    },
  ]
}

export const aerospaceData = {
  bannerData: {
    title: '航空航天供应链解决方案',
    desc: "Crane Worldwide Logistics了解您在航空货运和物流方面的挑战，并已开发并交付了独特的定制航空物流解决方案，以满足您所需的复杂运输和运输要求。",
    image: require("@/assets/images/industry/banner-01.jpg")
  },
  info: {
    title: '最佳空运服务——为您提供帮助！',
    desc: "如需任何空运、空运货物和包机需求，请联系我们专业的空运专家。我们将以最便捷、最具成本效益的空运解决方案支持您的业务，满足您持续的需求。",
    image: require("@/assets/images/industry/intro-01.jpg")
  }
}
export const carData = {
  bannerData: {
    title: '汽车物流服务',
    desc: "我们专业的全球汽车物流服务团队在为汽车行业创建和管理复杂的供应链解决方案（从汽车零部件、生产零部件、售后市场到成品车物流 (FVL)）方面拥有国际经验。 我们共同确保客户的端到端 (E2E) 运营更加精简、更快且更具成本效益。",
    image: require("@/assets/images/industry/banner-02.jpg")
  },
  info: {
    title: '',
    desc: "",
    image: ''
  }
}
export const goodsData = {
  bannerData: {
    title: '消费品——敏捷的综合物流解决方案',
    desc: "在 Crane Worldwide Logistics，我们的消费品行业专家专注于从产品开发开始到最终用户交付的产品供应链的整体视图，以支持不断增长的消费者需求。",
    image: require("@/assets/images/industry/banner-02.jpg")
  },
  info: {
    title: '保税仓储 - 需要帮助吗？',
    desc: "当使用海关保税仓库时，货物可以保留直到解决关税和检查问题。 在某些情况下，出口商可以推迟支付关税和税款，直到货物被购买。 保税仓储可以显着节省成本，并允许将货物存放在离最终用户更近的地方。 凭借遍布全球的保税设施，我们的消费品物流专家将通过敏捷灵活的解决方案支持您的运营。",
    image: require("@/assets/images/industry/intro-03.jpg"),
  }
}
export const cruiseData = {
  bannerData: {
    title: '邮轮物流，海事解决方案和酒店服务',
    desc: "Crane Worldwide为邮轮，船舶和酒店业提供物流专业知识，提供了一种动态方法来创建定制的供应链解决方案，以解决您的物流挑战。",
    image: require("@/assets/images/industry/banner-04.png")
  },
  info: {
    title: '为游轮、船舶和酒店业定制的解决方案',
    desc: "我们为世界各地的邮轮、海运和酒店供应链提供物流支持。我们为港口、干船坞和新建船舶提供定制的邮轮物流解决方案。我们专注于长期物流解决方案，为邮轮公司及其现在和未来的供应链挑战提供供应链支持。",
    image: require("@/assets/images/industry/intro-04.jpg"),
  }
}
export const energyData = {
  bannerData: {
    title: '完整的全球能源服务',
    desc: "从运营到地理以及介于两者之间，能源行业需要 Crane Worldwide Logistics 的严格标准和绝对的全球物流重点，以支持全球能源服务的卓越运营。",
    image: require("@/assets/images/industry/banner-05.jpg")
  },
  info: {
    title: '油气物流行业概况',
    desc: "石油和天然气行业正在以惊人的速度发展，更大的发展意味着重新关注石油和天然气物流公司提供新的有效解决方案的能力。 根据 IBISWorld 的数据，尽管预计下降 737.3%，但石油钻探和天然气开采行业的市场规模在 2023 年将达到约 7.9 亿美元。 与此同时，不确定性无处不在，能源公司正在寻找提高可操作性和效率的方法。 这始于有效的伙伴关系。",
    image: require("@/assets/images/industry/intro-05.jpeg"),
  }
}
export const governmentData = {
  bannerData: {
    title: '政府与国防',
    desc: "Crane Worldwide Logistics 为许多政府部门提供独特的物流解决方案，以应对不断变化的环境中的供应链挑战。",
    image: require("@/assets/images/industry/banner-06.jpg")
  },
  info: {
    title: '全面、安全和合规的服务',
    desc: "我们的物流专业人员拥有专业知识和经过验证的策略，可以为代理机构提供能够提高整个供应链效率的解决方案。",
    image: require("@/assets/images/industry/intro-06.jpg"),
  }
}
export const techData = {
  bannerData: {
    title: '高科技行业',
    desc: "高科技和电信行业处于不断创新的状态。 我们的全球物流专家将为您提供帮助！",
    image: require("@/assets/images/industry/banner-07.jpg")
  },
  info: {
    title: '白手套服务：保持资本设备的质量和控制',
    desc: "高价值货物需要精心照料。 我们的白手套服务可确保整个运输和交付过程中的端到端可见性和定制运输。 无论是数据架还是固定设备，这些物品都需要根据其独特要求量身定制的专业货运服务。 数据中心搬迁和建设背后的一些设备也是如此。",
    image: require("@/assets/images/industry/intro-07.jpg"),
  }
}
export const industrialData = {
  bannerData: {
    title: '工业物流',
    desc: "不断增加的材料和劳动力成本以及市场中的持续压力迫使许多制造公司调整其整体供应链战略并改变其经营方式。 COVID 大流行迫使工业和制造公司重新评估其供应链流程。 我们是来帮忙的。",
    image: require("@/assets/images/industry/banner-07.jpg")
  },
  info: {
    title: '越南的崛起',
    desc: "中美之间持续的贸易紧张局势被认为给越南等邻国增加了一些利益。越南正面临来自中国制造商的外资涌入，这些制造商正在战略性地安排其生产设施，以避免持续征收的关税。",
    image: require("@/assets/images/industry/intro-07.jpg"),
  }
}
export const lifeData = {
  bannerData: {
    title: '生命科学',
    desc: "Crane Worldwide Logistics了解生命科学和医疗保健行业独特的供应链的演变。",
    image: require("@/assets/images/industry/banner-08.jpg")
  },
  info: {
    title: '',
    desc: "",
    image: "",
  }
}
export const miningData = {
  bannerData: {
    title: '采矿和矿产供应链解决方案',
    desc: "我们的行业专业知识可为您的采矿项目提供值得信赖且可扩展的物流服务，包括运输和储存金属、非金属和能源矿物以及多种类型的机械。",
    image: require("@/assets/images/industry/banner-08.jpg")
  },
  info: {
    title: '通过重新检查您的采矿供应链来发现隐藏的利润',
    desc: "与专业的 3PL 提供商合作有很多好处，其中之一就是让您高枕无忧。 我们提供有针对性的解决方案，可降低运营成本并提高服务水平。 我们专注于让您的团队腾出时间专注于战略计划。",
    image: require("@/assets/images/industry/intro-07.jpg"),
  }
}