<template>
  <div>
    <common-banner :banner-data="newsIndexData.bannerData"></common-banner>
    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h1><strong>最新物流行业新闻资讯</strong></h1>
          <p>
            <img alt="站在卡车和集装箱船前的人" class="align_right" src="@/assets/images/news/index-01.png" width="320">
            浏览下方以发现我们最新发布的行业信息
          </p>
          <ul>
            <li><a href="/news?type=market">市场动态</a></li>
            <li><a href="/news?type=industry">行业洞察文章</a></li>
            <li><a href="/news?type=trade">贸易咨询</a></li>
            <li><a href="/news?type=podcast">播客系列</a></li>
            <li><a href="/news?type=case">客户案例</a></li>
            <li><a href="?type=guide">国际贸易术语指南</a>
            </li>
          </ul>
          <p>&nbsp;</p>
          <div class="card card_size_lg bg_primary-dark">
            <div class="card__body c_white">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item spacing"><h2 class="h3 c_white">接收最新的物流新闻</h2>
                  <p class="text_size_lg">所有最新最快的市场信息可以直接发送到您的收件箱</p></div>
                <div class="grid__item size_auto spacing text_align_center">
                  <div><a class="button button_size_lg button_color_secondary"
                          href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/">成为会员</a>&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid_md">
            <div v-for="(item,index) in newsIndexData.navList" :key="index" class="grid__item grid__item_fill size_6">
              <a :href="item.path" class="listing__item">
                <svg aria-hidden="true" class="icon listing__icon" role="img">
                  <use xlink:href="#icon-arrow-right"></use>
                </svg>
                <div class="listing__body">
                  <h3 class="listing__title">{{ item.name }}</h3>
                  <p>{{ item.desc }}</p>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {newsIndexData} from "@/assets/mock/news";

export default {
  name: "news",
  components: {CommonBanner},
  data() {
    return {
      newsIndexData
    }
  }
}
</script>

<style scoped>

</style>