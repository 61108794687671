<template>
  <div>

    <div class="section section_size_s bg_primary-dark">
      <div class="section__container container">
        <div class="section__intro type type_inverted"><p></p>
          <h1 class="c_white">{{ currentGuide.title }}</h1></div>
      </div>
      <div class="section__screen bg_primary-dark"></div>
    </div>


    <div class="section">
      <div class="container container_size_xs">
        <div class="type">
          <div class="type" v-html="currentGuide.detail"></div>

          <hr>
          <h2>有关 Incoterms 的更多详细信息：&nbsp;</h2>
          <p>单击下面以获取有关运输条款的更多信息：</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=5">FCA</a>
          </strong>(免费承运人)&nbsp;</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=6">CPT</a></strong>
            （运费付至）&nbsp;</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=7">CIP</a>
          </strong>（运费和保险付给）&nbsp;</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=8">DAP</a></strong>
            （当场交货）&nbsp;</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=9">DPU</a></strong>
            （在卸货地点交付）&nbsp;</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=10">DDP</a></strong>
            (完税交货)</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=11">FAS</a></strong>
            （船边免费）</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=1">FOB</a></strong>
            （免费董事会）</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=2">CFR</a></strong>
            （成本加运费）</p>
          <p class="gt-block"><strong><a
              href="/guideDetail?id=3">CIF</a></strong>
            （成本，保险费加运费）</p>
          <p class="gt-block" style="text-align: center;">
            <a center="" class="button button_size_lg button_color_secondary"
               href="/news?type=guide">回到国际贸易术语</a>
          </p>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {guideData} from "@/assets/mock/news";

export default {
  name: "guideDetail",
  data() {
    return {
      guideData,
      currentGuide: null,
    }
  },
  mounted() {
    this.currentGuide = guideData.list.find(item => item.id === this.$route.query.id);
  }
}
</script>

<style scoped>

</style>