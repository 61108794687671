<template>
  <div>
    <common-banner :bannerData="techData.bannerData"></common-banner>

    <service-intro :infoData="techData.info"></service-intro>

    <div class="section bg_secondary">
      <div class="section__container container container_size_sm">
        <blockquote class="spacing_lg text_lead c_white text_align_center"><p>“在 Crane
          Worldwide，我们通过合作方式关注客户，在高科技行业，我们知道‘客户体验’至关重要。
          我们的团队专注于开发解决方案，以实现最佳‘体验’的方式将产品交付到客户的首选地点。”</p>
          <footer></footer>
        </blockquote>
      </div>
    </div>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>高科技行业</h1>
            <p>高科技物流不仅仅局限于消费电子产品，还深入研究支持它们的重要基础设施，例如数据中心。
              这些中心对于存储和管理大量数字信息至关重要，其基础设施极其敏感，在运输时需要格外小心。
              我们的全球专家随时准备指导您解决这两个领域的复杂问题，确保您的运营顺利进行。</p>
            <h2>卓越的重载 OOG 高科技服务</h2>
            <p>数据行业的增长往往与运输大量非常规设备的挑战齐头并进。 这些超限货物（例如大型服务器存储架）需要采用重载运输方式，并且不吝惜护理。
              每件物品的运输都必须极其小心和精确。 与多个供应商协调、了解国际海关以及确保及时交货只是所涉及的复杂问题中的一小部分。
              此类设备的精致特性进一步强调了对白手套专业操作的需求。&nbsp;</p>
            <h2>高科技供应链服务</h2>
            <p>高科技供应链容易受到市场变化的影响；
              我们的高科技部门提供量身定制的服务，以满足客户从数据服务器机架到成品的特定供应链需求，除了货运代理服务外，我们还提供各种供应链解决方案，包括但不限于：</p>
            <ul>
              <li>白手套服务：运输和交付、供应商管理/原产地整合和供应商管理库存 (VMI)</li>
              <li>还提供网络设计和运输优化服务，包装咨询以及轻型组装和测试服务。&nbsp;</li>
              <li>逆向物流/ RMA管理</li>
            </ul>
            <p>从使用专业白手套服务运输数据机架到通过空运运输高价值货物，我们的技术团队将与您合作，为您的企业确定最有效和最具成本效益的运输方式。&nbsp;</p>
            <h3>我们了解您的高科技物流挑战&nbsp;</h3>
            <p>在Crane Worldwide Logistics，我们以学习的目的倾听自己的心，并与客户采取合作伙伴关系的方法，为您的市场敏感供应链提供最佳的供应链服务。&nbsp;</p>
          </div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">了解更多......</h3>
          <press-list></press-list>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {techData} from "@/assets/mock/industry";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      techData
    }
  }
}
</script>

<style scoped>

</style>