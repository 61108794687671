<template>
  <div>
    <div class="glide glide--ltr glide--carousel glide--swipeable">
      <div class="glide__track" data-glide-el="track">
        <ul class="glide__slides" style="width: 100%">
          <li v-for="(item,index) in bannerList" :key="index" class="glide__slide">
            <div class="section section_size_xl bg_primary-dark">
              <div class="section__container container">
                <div class="size_8_md mx_auto spacing text_align_center c_white">
                  <h1 class="h2 c_white">{{ item.title }}</h1>
                  <p class="text_lead">{{ item.desc }}</p>
                  <p class="gt-block mt_3">
                    <a
                        :href="item.btnPath"
                        class="button button_size_lg button_color_secondary">{{ item.btnTitle }}</a>
                  </p>
                </div>
              </div>
              <img
                  alt=""
                  class="section__background" height="650"
                  :src="item.image"
                  width="1400">
              <div class="section__screen bg_primary-dark"></div>
            </div>
          </li>
        </ul>
      </div>
      <div class="glide__arrows" data-glide-el="controls">
        <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
          <svg class="icon" role="img">
            <use xlink:href="#icon-arrow-left"></use>
          </svg>
        </button>
        <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
          <svg class="icon" role="img">
            <use xlink:href="#icon-arrow-right"></use>
          </svg>
        </button>
      </div>
      <div class="glide__bullets" data-glide-el="controls[nav]">
        <button class="glide__bullet glide__bullet--active" data-glide-dir="=0"></button>
        <button class="glide__bullet" data-glide-dir="=1"></button>
        <button class="glide__bullet" data-glide-dir="=2"></button>
        <button class="glide__bullet" data-glide-dir="=3"></button>
      </div>
    </div>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="section__intro"><h2 class="h2">您的 3PL 物流公司：合作关系至关重要</h2>
          <p class="text_lead">从通过我们的海运服务（FCL 或 LCL）运输集装箱或对时间要求严格的国际空运货物，到提供带有增值服务的复杂定制合同物流解决方案，Crane
            Worldwide 拥有丰富的物流管理经验和供应链专业知识，可帮助您的公司处理细节，让您可以专注于您的客户。</p></div>
        <div class="grid grid_md">
          <div class="grid__item">
            <div class="card card_theme_shift">
              <div class="card__body type type_inverted">
                <div><small class="text_uppercase">通过浏览</small>
                  <h3 class="h3 c_white no_pad">服务</h3></div>
                <p>作为提供全方位服务的全球货运代理和合同物流提供商，我们提供由仓库解决方案支持的空运和海运服务，为您解决供应链挑战提供一切所需。
                  我们的解决方案将能满足您所有的全球进出口需求。</p>
                <p class="gt-block"><a class="button button_theme_brackets button_inverted"
                                       href="/service?type=index"
                                       title="按服务浏览">了解更多</a></p></div>
              <img alt="按服务浏览" class="card__background"
                   :src="serviceIndexData.bannerData.image"></div>
          </div>
          <div class="grid__item">
            <div class="card card_theme_shift">
              <div class="card__body type type_inverted">
                <div><small class="text_uppercase">通过浏览</small>
                  <h3 class="h3 c_white no_pad">行业应用</h3></div>
                <p>
                  航空航天，政府，汽车，全球专业服务/项目物流，能源，高科技，工业，生命科学和零售，我们为您的特定行业提供专门的独特供应链解决方案，为您面临的挑战提供物流专业知识。</p>
                <p class="gt-block"><a class="button button_theme_brackets button_inverted" href="/industry?type=index"
                                       title="按行业浏览">了解更多</a></p></div>
              <img alt="按行业浏览" class="card__background"
                   :src="industryIndexData.bannerData.image"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="section bg_shade">
      <div class="section__container container container_size_sm">
        <div class="section__intro mb_1"><h2 class="h2">行业洞见</h2></div>
        <div class="grid grid_md">
          <div class="grid__item grid__item_fill">
            <div class="card card_size_lg justify_end">
              <div class="card__body flex_none type type_inverted">
                <div><small class="text_uppercase">{{ industryData.list[0].time }}</small>
                  <h3 class="h3 c_white no_pad">{{ industryData.list[0].title }}。</h3></div>
                <p>{{ industryData.list[0].desc }}</p>
                <p class="gt-block"><a
                    class="button button_theme_brackets button_inverted"
                    :href="'/newsDetail?type=industry&id='+industryData.list[0].id"
                    :title="industryData.list[0].title ">阅读文章</a>
                </p></div>
              <img alt=""
                   class="card__background"
                   :src="industryData.list[0].image">
              <div class="card__screen"></div>
            </div>
          </div>
          <div class="grid__item spacing_lg">
            <div class="card card_size_lg">
              <div class="card__body type">
                <div><small class="text_uppercase">{{ industryData.list[1].time }}</small>
                  <h3 class="h3 no_pad">{{ industryData.list[1].title }}</h3></div>
                <p>{{ industryData.list[1].desc }}</p>
                <p class="gt-block"><a
                    class="button button_theme_brackets"
                    :href="'/newsDetail?type=industry&id='+industryData.list[1].id"
                    title="阅读：客户咨询：阿联酋货运市场更新">阅读文章</a></p></div>
            </div>
            <div class="card card_size_lg">
              <div class="card__body type">
                <div><small class="text_uppercase">{{ industryData.list[2].time }}</small>
                  <h3 class="h3 no_pad">{{ industryData.list[2].title }}</h3></div>
                <p>{{ industryData.list[2].desc }}</p>
                <p class="gt-block"><a
                    class="button button_theme_brackets"
                    :href="'/newsDetail?type=industry&id='+industryData.list[2].id"
                    title="阅读：圣保罗的 3PL 格局">阅读文章</a></p></div>
            </div>
          </div>
        </div>
      </div>
      <img alt="" class="section__background"
           height="911" src="https://www.craneww.com/site/templates/dist/img/section-texture.jpg" width="1530"></div>

    <div class="section ">
      <div class="container container_size_sm">
        <div class="grid grid_size_xl grid_md align-items_center">
          <div class="grid__item type"><h2>顶级第三方物流公司</h2>
            <p>作为 3PL 行业中发展最快的物流公司之一，Crane
              一直致力于提供专业解决方案，以解决客户的物流挑战。我们的空运、海运和仓储解决方案可在全球范围内为您提供！需要空运、海运还是需要仓储或货运服务？需要报关支持？我们可以为您提供帮助。</p>
          </div>
          <div class="grid__item">
            <div class="grid grid_size_sm">
              <div class="grid__item size_6"><a
                  class="button button_block button_outline_secondary button_size_lg"
                  href="/about?type=ourValue">我们的价值</a>
              </div>
              <div class="grid__item size_6"><a
                  class="button button_block button_outline_secondary button_size_lg"
                  href="/about?type=ourValue">社区支持</a>
              </div>
              <div class="grid__item size_6"><a class="button button_block button_outline_secondary button_size_lg"
                                                href="/about?type=compliance">合规性</a>
              </div>
              <div class="grid__item size_6"><a
                  class="button button_block button_outline_secondary button_size_lg"
                  href="/about?type=leader">领导团队</a></div>
              <div class="grid__item size_6"><a
                  class="button button_block button_outline_secondary button_size_lg"
                  href="/about?type=careers">职业机会</a></div>
              <div class="grid__item size_6"><a
                  class="button button_block button_outline_secondary button_size_lg"
                  href="/about?type=sustain">永续发展</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section bg_shade">
      <div class="container container_size_sm">
        <div class="grid grid_md grid_size_xl align-items_center">
          <div class="grid__item">
            <div class="grid grid_size_sm">
              <div class="grid__item size_4 size_3_sm size_4_md size_3_lg"><img
                  alt="丹尼斯·凯塞多" class="radius_circle"
                  height="100" src="@/assets/images/about/careers-01.jpg"
                  width="100"></div>
              <div class="grid__item size_4 size_3_sm size_4_md size_3_lg"><img
                  alt="斯科特鲍曼" class="radius_circle"
                  height="100" src="@/assets/images/about/careers-02.jpg"
                  width="100"></div>
              <div class="grid__item size_4 size_3_sm size_4_md size_3_lg"><img
                  alt="梅根·拉森（Megan Larsen）-Crane Worldwide Logistics" class="radius_circle"
                  height="100" src="@/assets/images/about/careers-03.jpg"
                  width="100"></div>
              <div class="grid__item size_4 size_3_sm size_4_md size_3_lg"><img
                  alt="伊万亚历山大 - 起重机全球物流"
                  class="radius_circle" height="100"
                  src="@/assets/images/about/careers-04.jpg" width="100">
              </div>
              <div class="grid__item size_4 size_3_sm size_4_md size_3_lg"><img
                  alt="Grace Yang-起重机全球物流" class="radius_circle"
                  height="100" src="@/assets/images/about/careers-05.jpg" width="100">
              </div>
              <div class="grid__item size_4 size_3_sm size_4_md size_3_lg"><img
                  alt="Carmen Gutierrez"
                  class="radius_circle" height="100"
                  src="@/assets/images/about/careers-06.jpg" width="100"></div>
              <div class="grid__item size_4 size_3_sm size_4_md size_3_lg"><img
                  alt=""
                  class="radius_circle" height="100"
                  src="@/assets/images/about/careers-07.png" width="100"></div>
            </div>
          </div>
          <div class="grid__item type"><h2 class="h2">寻找供应链职业？</h2>
            <p class="text_lead">我们正在招聘！ 作为供应链解决方案中增长最快的物流提供商之一，我们一直在寻找有活力的人才加入我们的团队，并实现我们在3PL行业的未来增长计划。
              无论您是经验丰富的物流专业人士，国际贸易专家，卡车司机，还是新近毕业的物流专业人士，请加入我们的全球团队，并通过Crane
              Worldwide Logistics实现您的物流职业理想。 物流工作！</p>
            <p class="gt-block"><a class="button button_size_lg button_theme_brackets"
                                   href="/about?type=careers">加入我们的团队！</a>
            </p></div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Glide from "@glidejs/glide"
import {industryData} from "@/assets/mock/news";
import {serviceIndexData} from "@/assets/mock/service";
import {industryIndexData} from "@/assets/mock/industry";

export default {
  name: "index",
  data() {
    return {
      industryData,
      serviceIndexData,
      industryIndexData,
      bannerList: [
        {
          title: 'Crane Worldwide 提供更好的客户体验',
          desc: '定制的供应链解决方案、多式联运和仓储为您的业务提供支持',
          btnTitle: '我们的服务',
          btnPath: '/service?type=index',
          image: require("@/assets/images/home/banner-01.jpg"),
        },
        {
          title: '物流职业：加入首选雇主',
          desc: '我们的员工就是我们的业务，我们致力于在员工的整个物流职业生涯中实现最高的满意度。',
          btnTitle: '加入我们的团队',
          btnPath: '/about?type=careers',
          image: require("@/assets/images/home/banner-02.jpg"),
        },
        {
          title: '阅读 Crane Worldwide Logistics 的市场更新',
          desc: '我们的专家不断努力，让您了解空运、海运、陆运和铁路货运的最新情况',
          btnTitle: '获取最新',
          btnPath: '/news?type=market',
          image: require("@/assets/images/home/banner-03.png"),
        },
        {
          title: '有所作为',
          desc: '为我们的客户、我们的社区以及彼此带来改变。 详细了解 Crane Worldwide 可以为您的组织增加的价值。',
          btnTitle: '我们的服务',
          btnPath: '/about?type=ourValue',
          image: require("@/assets/images/home/banner-04.png"),
        },
      ]
    }
  },
  mounted() {
    this.renderSwiper()
  },
  methods: {
    renderSwiper() {
      new Glide('.glide').mount({});
    }
  }
}
</script>

<style lang="scss" scoped>

</style>