<template>
  <div id="app">
    <custom-header></custom-header>
    <custom-nav></custom-nav>
    <router-view />
    <custom-footer></custom-footer>
  </div>
</template>

<script>
import CustomHeader from "@/components/customHeader.vue";
import CustomNav from "@/components/customNav.vue";
import CustomFooter from "@/components/customFooter.vue";

export default {
  components: {CustomFooter, CustomNav, CustomHeader},
  mounted() {

  },
  methods:{

  }
}
</script>


