<template>
  <div>
    <common-banner :banner-data="ourValueData.bannerData"></common-banner>

    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h2>为什么选择Crane Worldwide Logistics？&nbsp;</h2>
          <p>Crane Worldwide
            的愿景是以最高水平为我们的客户提供卓越的全球贸易，为我们的团队成员创造成就感，并提升我们工作所在的社区。每一天，我们都会有所作为，并渴望离开物流行业的一项遗产，旨在改善全球数百万人的生活质量。&nbsp;</p>
          <ul>
            <li>凭借在物流领域的悠久历史，我们员工的专业知识、深厚的行业知识和积极进取的态度对我们的全球和本地客户产生了影响。&nbsp;</li>
            <li>作为一家私营家族企业，我们采用扁平结构，可以快速做出决策。 这使团队能够为我们的客户提供最佳解决方案。</li>
            <li>合作伙伴关系会产生影响，通过与客户的有机增长，我们已经发展出具有本地代表性的全球足迹，并继续发展壮大。
              我们将在客户需要我们的地方进行投资。
            </li>
            <li>我们的技术平台是内部开发的，全面了解客户对实时信息的需求。</li>
            <li>我们在我们经营所在的社区有所作为。 我们的 <a
                href="/about?type=ourValue">Crane关怀</a>&nbsp;倡议深深植根于我们的企业文化。
            </li>
          </ul>
          <p>我们生活在非同寻常的时代，全球市场不断变化。 我们灵活而充满活力的方法以及我们的价值主张，通过我们的技术，服务和人员为客户提供了奉献精神。&nbsp;</p>
          <hr>
          <h2>我们的价值主张</h2>
          <ul>
            <li><strong>技术</strong> -以用户为中心的工具可实现端到端供应链的可见性</li>
            <li><strong>产品指南</strong> -总是超出预期的专业知识</li>
            <li><strong>团队</strong> -训练有素的人，无与伦比的奉献精神&nbsp;</li>
          </ul>

          <p class="gt-block">我们灵活，反应灵敏且充满活力，随时为您提供支持 <strong><a
              href="/service?type=index">全套解决方案</a></strong> 在这个充满挑战的时刻。 我们拥有的
            <strong><a href="/industry?type=index">行业应用方面:</a></strong> 专业知识可为您的组织提供国际物流支持，我们将为您提供帮助！
          </p>
          <hr>
          <h2>我们的文化</h2>
          <p>
            我们确保我们的业务关系由核心原则驱动，以个性化的方式满足客户的物流需求，同时为我们在世界各地的员工创造一个良好的工作场所。</p>
          <ul>
            <li>在Crane Worldwide，我们相信 <strong>一种紧迫感&nbsp;</strong></li>
            <li>在Crane Worldwide，我们相信 <strong>使卓越成为习惯</strong></li>
            <li>在Crane Worldwide，我们相信 <strong>创新和可能性，而不是局限性</strong></li>
            <li>在Crane Worldwide，我们相信 <strong>创造一个工作的好地方</strong></li>
            <li>在Crane Worldwide，我们相信 <strong>成为客户倡导者</strong></li>
          </ul>
          <p class="gt-block">什么是物流供应商？ 对于我们来说，它是您长期的合作伙伴，并通过创新的技术，出色的人才和 全球位置网络 这样您就可以专注于您的核心业务。 如果我们可以支持您，请联系我们！&nbsp;
          </p></div>
      </div>
    </div>

    <quotation></quotation>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {ourValueData} from "@/assets/mock/about";
import Quotation from "@/components/quotation.vue";

export default {
  components: {Quotation, CommonBanner},
  data() {
    return {
      ourValueData
    }
  }
}
</script>

<style scoped>

</style>