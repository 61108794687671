<template>
  <div>
    <common-banner :bannerData="aerospaceData.bannerData"></common-banner>

    <service-intro :infoData="aerospaceData.info"></service-intro>


    <div class="section bg_secondary">
      <div class="section__container container container_size_sm">
        <blockquote class="spacing_lg text_lead c_white text_align_center"><p>“Crane Worldwide Logistics
          了解我们的物流紧迫性，并无缝地实现了它。 他们的客户服务是我们在物流行业所见过的无与伦比的。 我会向我们的客户推荐
          Crane Worldwide Logistics，因为他们是真正的专业人士，并提供可以信赖的量身定制的、对时间敏感的解决方案。”</p>
          <footer><h3 class="c_white">运营主管Ben Pinington</h3>
            <p class="c_white">艾尔芬</p></footer>
        </blockquote>
      </div>
    </div>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>需要全球航空物流服务和支持吗？&nbsp;</h1>
            <p>Crane Worldwide Logistics 了解您的航空货运和物流挑战。 我们开发并提供了独特的定制航空航天物流解决方案，以满足您所需的复杂运输要求。
              事实上，航空航天物流服务可以比您对传统航空航天需求的想象走得更远，甚至超出这个世界。&nbsp;</p>
            <p>航空航天业因其独特的物流需求而脱颖而出，这些需求通常具有不可预测性、紧迫性和精确性的特点。
              无论是运输机翼或发动机等异常大型部件的要求、需要立即交付零件的地面飞机 (AOG) 情况的紧迫性，还是与处理危险材料
              (Hazmat) 相关的安全协议，挑战都是多方面的。&nbsp;</p>
            <p>在如此高风险的环境中，拥有一个了解这些复杂问题的物流合作伙伴至关重要。 Crane Worldwide Logistics
              可以为您提供全方位的航空航天物流服务，以满足所有这些需求和尚未出现的需求。&nbsp;</p>
            <p class="gt-block"><strong>以下是与我们合作提供高质量航空航天相关货物运输的一些核心优势：</strong></p>
            <ul>
              <li>全面的门到门供应链解决方案 <a
                  href="/service?type=sea">海洋，</a> 轨， <a
                  href="/service?type=air">AIR</a> 或完整的 3PL 运营，包括 <a
                  href="/service?type=contract">仓储</a>.
              </li>
              <li>我们的全球团队提供的海关合规咨询有助于指导进出口操作，确保您的货物顺利通关。</li>
              <li>通过认识到一些航空航天运输的即时性需求，促进手提运输要求的满足。&nbsp;</li>
              <li>我们的专业知识不仅限于物流，还延伸到航空航天业，确保解决方案能够满足您的具体挑战。</li>
              <li>
                我们是积极进取的专家，提供灵活的物流服务，例如时间紧迫的服务，可以快速适应您不断变化的需求，确保流畅、无忧的物流体验。
              </li>
              <li>我们团队的端到端咨询和执行管理提供了宝贵的见解，可以将您的货物准确地运送到需要的地方。</li>
            </ul>
            <h2>太空物流 - 无限远？</h2>
            <p class="gt-block">随着航天工业不断探索这个世界之外的领域，需要整体规划和相关的后勤要求，以确保任务按时完成。&nbsp;<br>从发射前移动卫星到通过地面运输对太空任务的基本设备的紧急需求，航空航天公司的先进物流是该过程的关键组成部分。
            </p>
            <p>Crane Worldwide Logistics 可以通过材料的移动、存储和相关供应链要求来支持您的太空项目。&nbsp;</p>
            <p>航天工业对未知领域的追求需要卓越的后勤精度。 确保任务及时且准确至关重要。 Crane Worldwide Logistics
              处于这些需求的最前沿，为您的太空项目提供无与伦比的支持。</p>
            <ul>
              <li><strong>及时性至关重要。</strong> 发射窗口极其有限。 无论是在发射前移动卫星，还是运输太空任务的重要设备，我们都能确保准时，符合您严格的时间表。
              </li>
              <li><strong>坚定不移的监管链标准</strong>。 每个航天器部件的完整性至关重要。
                我们维持严格的监管链协议，以保证每件物品从源头到目的地的真实性和安全性。
              </li>
              <li><strong>细腻而精确的处理。</strong> 许多太空物品都非常脆弱。 我们的白手套搬运服务确保敏感设备得到最小心的处理，避免任何可能影响您任务的损坏。
              </li>
              <li><strong>敏感的自然承认。</strong> 认识到许多空间组件都有独特的要求，我们提供专门的存储和移动解决方案，以保持其完整性。
              </li>
            </ul>
            <h2>了解您的航空航天供应链挑战&nbsp;</h2>
            <p>我们以空中交通管制的精确度满足您的所有航空航天物流需求，规划您供应链中的每个机会。
              我们对关键通信的关注为客户提供了从启动到交付的无与伦比的供应链可见性和安全性。</p>
            <p class="gt-block">我们知道航空航天业有其独特的物流挑战，而 Crane Worldwide Logistics
              是一家全球合作伙伴，拥有航空航天供应链解决方案独特的成熟专业知识。我们的航空航天物流团队随时做好 24/7/365
              的准备，提供安全、可靠的供应链解决方案，无论是空运还是为真正的超凡之物做好准备。即使需要时间紧迫的服务，克瑞航空航天垂直团队也会随时为您提供支持！
              <a href="/quotation">今天请求报价。</a></p></div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">了解更多......</h3>
          <press-list ></press-list>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {aerospaceData} from "@/assets/mock/industry";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      aerospaceData
    }
  }
}
</script>

<style scoped>

</style>