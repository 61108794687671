<template>
  <div>
    <common-banner :banner-data="quotationData.bannerData"></common-banner>


    <div class="section">
      <div class="container container_size_xs">
        <div class="section__body type">

          <h1>联系我们的全球物流专家寻求建议。 货运报价。 物流报价。</h1>

          <p>我们的全球物流团队可为您的物流和货运业务提供支持。 请在下方提出您的询问，我们将为您联系代表，讨论您的物流挑战并确定最佳解决方案来帮助您！
            我们可以为您提供运费报价、仓库服务报价、物流支持等。</p>

          <blockquote>
            <p>Crane Worldwide Logistics 不处理个人物品的装运或运输。</p>
          </blockquote>

          <div data-correlation-establishing="true" data-error-message="An error occurred during your submission."
               data-form-block-id="10344506-458d-ed11-81ac-0022480816f1" data-is-loading="true">&nbsp;<meta
              name="layout-editable" type="xrm/designer/setting" value="marketing-designer-layout-editable">
            <meta datatype="text" label="Layout max width" name="layout-max-width" type="xrm/designer/setting"
                  value="600px">
            <form data-container="true" style="">

              <div data-layout="true" tabindex="0">
                <div class="" data-section="true" style="padding: 10px;">
                  <div class="containerWrapper" style="display: flex;
            width: 100%;
        ;flex-wrap: wrap;">
                    <div style="clear:both;"></div>
                    <div class="columnContainer" data-container="true" data-container-width="100"
                         style="display: block; min-height: 70px; min-width: 20px; width: calc(100% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;    word-wrap: break-word; word-break: break-word;   word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Text">
                        <p>
                          <strong>
                            <span
                                style="font-size:20px;">Contact Information</span>
                          </strong>
                        </p>
                      </div>
                      <div data-editorblocktype="Field-dropdown" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true">
                            <label class="lp-ellipsis"
                                   for="1db96668-218d-ed11-81ac-002248081ae5"
                                   title="">
                              Have you done business with us?
                              <span class="lp-required" style="color: rgb(255, 0, 0);">*</span>
                            </label>
                            <select
                                id="1db96668-218d-ed11-81ac-002248081ae5" aria-required="true"
                                class="lp-form-fieldInput"
                                name="1db96668-218d-ed11-81ac-002248081ae5" required="required"
                                style="width: 100%; box-sizing: border-box;" title="">
                              <option value=""></option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select></div>
                        </div>
                      </div>
                    </div>
                    <div style="clear:both;"></div>
                  </div>
                </div>
                <div class="" data-section="true" style="padding: 10px;">
                  <div class="containerWrapper" style="display: flex;
            width: 100%;
        ;flex-wrap: wrap;">
                    <div style="clear:both;"></div>
                    <div class="columnContainer" data-container="true" data-container-width="50"
                         style="display: block; min-height: 70px; min-width: 15px; width: calc(50% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;     word-wrap: break-word; word-break: break-word;    word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-text" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true">
                            <label class="lp-ellipsis"
                                   for="3f746946-34b4-442c-a677-e232cdd2bc40"
                                   title="">
                              First Name
                              <span
                                  class="lp-required" style="color: rgb(255, 0, 0);">*</span>
                            </label>
                            <input
                                id="3f746946-34b4-442c-a677-e232cdd2bc40" aria-required="true"
                                class="lp-form-fieldInput"
                                data-ms-editor="true" name="3f746946-34b4-442c-a677-e232cdd2bc40"
                                pattern=".*\S+.*" placeholder="" required="required" spellcheck="false"
                                style="width: 100%; box-sizing: border-box;" title="" type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columnContainer" data-container="true" data-container-width="50"
                         style="display: block; min-height: 70px; min-width: 15px; width: calc(50% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;     word-wrap: break-word; word-break: break-word;    word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-text" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true">
                            <label class="lp-ellipsis"
                                   for="e1dfc514-f301-4cb2-855a-4c8fa8331207"
                                   title="">
                              Last Name
                              <span
                                  class="lp-required" style="color: rgb(255, 0, 0);">*</span>
                            </label>
                            <input
                                id="e1dfc514-f301-4cb2-855a-4c8fa8331207" aria-required="true"
                                class="lp-form-fieldInput"
                                data-ms-editor="true" name="e1dfc514-f301-4cb2-855a-4c8fa8331207"
                                pattern=".*\S+.*" placeholder="" required="required" spellcheck="false"
                                style="width: 100%; box-sizing: border-box;" title="" type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="clear:both;"></div>
                  </div>
                </div>
                <div class="emptyContainer" data-section="true" style="padding: 10px;">
                  <div class="containerWrapper" style="display: flex;
            width: 100%;
        ;flex-wrap: wrap;">
                    <div style="clear:both;"></div>
                    <div class="columnContainer" data-container="true" data-container-width="33.33"
                         style="display: block; min-height: 70px; min-width: 13.3333px; width: calc(33.33% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;     word-wrap: break-word; word-break: break-word;    word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-email" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true">
                            <label class="lp-ellipsis"
                                   for="7f685ebb-7c54-4cff-a1bc-772562d25c38"
                                   title="Please enter a valid email address like example@email.com">E-mail<span
                                class="lp-required" style="color: rgb(255, 0, 0);">*</span>
                            </label>
                            <input
                                id="7f685ebb-7c54-4cff-a1bc-772562d25c38" aria-required="true"
                                class="lp-form-fieldInput"
                                name="7f685ebb-7c54-4cff-a1bc-772562d25c38"
                                pattern="[^@\s\\&quot;<>\)\(\[\]:;,.]+(([.]{1}[^@\s\\&quot;<>\)\(\[\]:;,.]+)+?|)@([^@\s\\&quot;<>\)\(\[\]\+:;,\.\-]+(((\.|\+|-|--)[^@\s\\&quot;<>\)\(\[\]+:;,.\-]+)+?|)([.][^0-9@\s\\&quot;<>\)\(\[\]+:;,.\-]+)+?)"
                                placeholder="" required="required" style="width: 100%; box-sizing: border-box;"
                                title="Please enter a valid email address like example@email.com" type="email">
                          </div>
                        </div>


                      </div>
                    </div>
                    <div class="columnContainer" data-container="true" data-container-width="33.33"
                         style="display: block; min-height: 70px; min-width: 13.3333px; width: calc(33.33% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;     word-wrap: break-word; word-break: break-word;    word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-text" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="false">
                            <label class="lp-ellipsis"
                                   for="7646c7aa-3a6d-4b55-9a82-4d7a255a7eb3"
                                   title="">
                              Job Title
                            </label>
                            <input
                                id="7646c7aa-3a6d-4b55-9a82-4d7a255a7eb3" class="lp-form-fieldInput"
                                data-ms-editor="true"
                                name="7646c7aa-3a6d-4b55-9a82-4d7a255a7eb3" placeholder="" spellcheck="false"
                                style="width: 100%; box-sizing: border-box;" title="" type="text">
                          </div>
                        </div>


                      </div>
                    </div>
                    <div class="columnContainer" data-container="true" data-container-width="33.33"
                         style="display: block; min-height: 70px; min-width: 13.3333px; width: calc(33.33% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;     word-wrap: break-word; word-break: break-word;    word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-phone" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true">
                            <label class="lp-ellipsis"
                                   for="ac6a065d-364e-40d6-9a19-d9bf1ed4aa3e"
                                   title="">
                              Phone Number
                              <span
                                  class="lp-required" style="color: rgb(255, 0, 0);">*</span>
                            </label>
                            <input
                                id="ac6a065d-364e-40d6-9a19-d9bf1ed4aa3e" aria-required="true"
                                class="lp-form-fieldInput"
                                data-requirederrormessage="N" name="ac6a065d-364e-40d6-9a19-d9bf1ed4aa3e"
                                placeholder="" required="required" style="width: 100%; box-sizing: border-box;" title=""
                                type="tel">
                          </div>
                        </div>


                      </div>
                    </div>
                    <div style="clear:both;"></div>
                  </div>
                </div>
                <div class="" data-section="true" style="padding: 10px;">
                  <div class="containerWrapper" style="display: flex; width: 100%;flex-wrap: wrap;">
                    <div style="clear:both;"></div>
                    <div class="columnContainer" data-container="true" data-container-width="33.33"
                         style="display: block; min-height: 70px; min-width: 13.3333px; width: calc(33.33% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;    word-wrap: break-word; word-break: break-word;   word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-text" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true">
                            <label class="lp-ellipsis"
                                   for="ac9ddb60-616f-4f12-b4e2-9202f688ed2f"
                                   title="">
                              Company Name
                              <span
                                  class="lp-required" style="color: rgb(255, 0, 0);">*</span>
                            </label>
                            <input
                                id="ac9ddb60-616f-4f12-b4e2-9202f688ed2f" aria-required="true"
                                class="lp-form-fieldInput"
                                data-ms-editor="true" name="ac9ddb60-616f-4f12-b4e2-9202f688ed2f"
                                pattern=".*\S+.*" placeholder="" required="required" spellcheck="false"
                                style="width: 100%; box-sizing: border-box;" title="" type="text">
                          </div>
                        </div>


                      </div>
                    </div>
                    <div class="columnContainer" data-container="true" data-container-width="33.33"
                         style="display: block; min-height: 70px; min-width: 13.3333px; width: calc(33.33% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;    word-wrap: break-word; word-break: break-word;   word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-text" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true"><label class="lp-ellipsis"
                                                                                       for="adeba4a5-e18d-ed11-81ac-002248081a5e"
                                                                                       title="">Company Website<span
                              class="lp-required" style="color: rgb(255, 0, 0);">*</span></label><input
                              id="adeba4a5-e18d-ed11-81ac-002248081a5e" aria-required="true" class="lp-form-fieldInput"
                              name="adeba4a5-e18d-ed11-81ac-002248081a5e" pattern=".*\S+.*" placeholder=""
                              required="required" style="width: 100%; box-sizing: border-box;" title="" type="text">
                          </div>
                        </div>


                      </div>
                    </div>
                    <div class="columnContainer" data-container="true" data-container-width="33.33"
                         style="display: block; min-height: 70px; min-width: 13.3333px; width: calc(33.33% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;    word-wrap: break-word; word-break: break-word;   word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-lookup" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true"><label class="lp-ellipsis"
                                                                                       for="d56e39b1-238d-ed11-81ac-002248081ae5"
                                                                                       title="">Industry<span
                              class="lp-required" style="color: rgb(255, 0, 0);">*</span></label><span title=""><input
                              id="d56e39b1-238d-ed11-81ac-002248081ae5" aria-required="true"
                              autocomplete="off" class="lp-form-fieldInput lookup-behavior"
                              data-lookup-index="0" name="d56e39b1-238d-ed11-81ac-002248081ae5" pattern=".*\S+.*"
                              placeholder="" required="required" style="width: 100%; box-sizing: border-box;"
                              type="text">
                            <div
                                id="lookup-popup-div-0" class="ui-selectmenu-menu ui-front">
                            <ul id="lookup-popup-ul-0"
                                class="ui-menu ui-corner-bottom ui-widget ui-widget-content"
                                role="listbox"
                                style="width: 173.312px;"><li
                                class="ui-menu-item"
                                data-value="crane_vertical,djGJ3BpYsLDXlFhcbNFJPnENOgmWRTlJfHWa2kOmjbYjAw=="><div
                                class="ui-menu-item-wrapper" role="option">Aerospace</div></li><li class="ui-menu-item"
                                                                                                   data-value="crane_vertical,djEgL1DK3w5cmHjF9+CDZp5u//vuORQtkkjz+dLtVa6bCQ=="><div
                                class="ui-menu-item-wrapper" role="option">Automotive</div></li><li class="ui-menu-item"
                                                                                                    data-value="crane_vertical,djG1LnK1TkiCtCktMDnzzFCAibYqeaicgLpP9csmiJHzeg=="><div
                                class="ui-menu-item-wrapper" role="option">Cruise, Marine &amp; Hospitality</div></li><li
                                class="ui-menu-item"
                                data-value="crane_vertical,djGaSnhO5GKrV4w0pY9oCwpRSe29P3ufGlLH2En3tnhV+w=="><div
                                class="ui-menu-item-wrapper" role="option">Doc Turnover</div></li><li
                                class="ui-menu-item"
                                data-value="crane_vertical,djEWUMTcDF4hkxfGTHyo6mMk6eeQcbFPR/TykCNUFaQjPw=="><div
                                class="ui-menu-item-wrapper" role="option">Energy</div></li><li class="ui-menu-item"
                                                                                                data-value="crane_vertical,djE05tTXnOHmOql6WPUrW6kiR72nYjB6dggXqsiVXv+VYw=="><div
                                class="ui-menu-item-wrapper" role="option">FMCG</div></li><li class="ui-menu-item"
                                                                                              data-value="crane_vertical,djH8GTtqaVlzOvx/oCV98bnXZ7ZoI42hVNjDQ5ji0XpJOQ=="><div
                                class="ui-menu-item-wrapper" role="option">Government</div></li><li class="ui-menu-item"
                                                                                                    data-value="crane_vertical,djFjKlQ4cyxvgECy0lpLYqZPKtJr62xKP3xeBuc0lEosNA=="><div
                                class="ui-menu-item-wrapper" role="option">Hi-Tech</div></li><li class="ui-menu-item"
                                                                                                 data-value="crane_vertical,djHoI+Ct8+Xne7iineypF/+ZuCrDZSl7KeaygJtWIb9pqQ=="><div
                                class="ui-menu-item-wrapper" role="option">Industrial</div></li><li class="ui-menu-item"
                                                                                                    data-value="crane_vertical,djH9LqusxXvw4+0xFK1w2H4QPO9ZVy9yF3L6nTTkoVUULw=="><div
                                class="ui-menu-item-wrapper" role="option">Life Sciences</div></li></ul></div><datalist
                                id="list-d56e39b1-238d-ed11-81ac-002248081ae5"></datalist></span></div>
                        </div>


                      </div>
                    </div>
                    <div style="clear:both;"></div>
                  </div>
                </div>
                <div class="" data-section="true" style="padding: 10px;">
                  <div class="containerWrapper" style="display: flex;
            width: 100%;
        ;flex-wrap: wrap;">
                    <div style="clear:both;"></div>
                    <div class="columnContainer" data-container="true" data-container-width="100"
                         style="display: block; min-height: 70px; min-width: 20px; width: calc(100% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;   word-wrap: break-word; word-break: break-word;  word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-text" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true">
                            <label class="lp-ellipsis"
                                   for="790a6bdd-7832-4dd6-8f30-ed8d8772966e"
                                   title="">
                              Address
                              <span
                                  class="lp-required" style="color: rgb(255, 0, 0);">*</span>
                            </label>
                            <input
                                id="790a6bdd-7832-4dd6-8f30-ed8d8772966e" aria-required="true"
                                class="lp-form-fieldInput"
                                name="790a6bdd-7832-4dd6-8f30-ed8d8772966e" pattern=".*\S+.*" placeholder=""
                                required="required" style="width: 100%; box-sizing: border-box;" title="" type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="clear:both;"></div>
                  </div>
                </div>
                <div class="" data-section="true" style="padding: 10px;">
                  <div class="containerWrapper" style="display: flex;width: 100%;flex-wrap: wrap;">
                    <div style="clear:both;"></div>
                    <div class="columnContainer" data-container="true" data-container-width="33"
                         style="display: block; min-height: 70px; min-width: 13.3333px; width: calc(33% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;   word-wrap: break-word; word-break: break-word;  word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-lookup" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true">
                            <label class="lp-ellipsis"
                                   for="33d7fcec-338d-ed11-81ac-0022480816f1"
                                   title="">
                              Country
                              <span
                                  class="lp-required" style="color: rgb(255, 0, 0);">*</span>
                            </label>
                            <span title="">
                              <input
                                  id="33d7fcec-338d-ed11-81ac-0022480816f1" aria-required="true"
                                  autocomplete="off" class="lp-form-fieldInput lookup-behavior"
                                  data-lookup-index="1" name="33d7fcec-338d-ed11-81ac-0022480816f1" pattern=".*\S+.*"
                                  placeholder="" required="required" style="width: 100%; box-sizing: border-box;"
                                  type="text">
                              <div
                                  id="lookup-popup-div-1" class="ui-selectmenu-menu ui-front">
                                <ul id="lookup-popup-ul-1"
                                    class="ui-menu ui-corner-bottom ui-widget ui-widget-content"
                                    role="listbox"
                                    style="width: 171.391px;">
                                  <li
                                      class="ui-menu-item"
                                      data-value="crane_country,djH34JrRnoC1oW3UlqjS+j9Jzb3nX23/7D3B4k6BY6Wtfg==">
                                    <div class="ui-menu-item-wrapper" role="option">AFGHANISTAN</div>
                                  </li>
                                  <li
                                      class="ui-menu-item"
                                      data-value="crane_country,djEAodKJb42ivBJ20USJIqMnMDpvh5cZ9E/VFjNz2++LzQ==">
                                    <div class="ui-menu-item-wrapper" role="option">ALAND ISLANDS</div>
                                  </li>
                                  <li
                                      class="ui-menu-item"
                                      data-value="crane_country,djEWM6IK4en5vX5ImoA5fheacIx2w/Az0SCxyuNaI5X1bg==">
                                    <div class="ui-menu-item-wrapper" role="option">ALBANIA</div>
                                  </li>
                                  <li class="ui-menu-item"
                                      data-value="crane_country,djFvB15MIc59uTP9lSbtH+oxcOkC1SWYBKWDApuR7YH8Yg=="><div
                                      class="ui-menu-item-wrapper" role="option">ALGERIA</div>
                                  </li>
                                  <li class="ui-menu-item"
                                      data-value="crane_country,djFI1qZAGL3Oqz/1SdFF2qa0WR8AbM3bZbFe+G7R+PWwUg=="><div
                                      class="ui-menu-item-wrapper" role="option">AMERICAN SAMOA</div>
                                  </li>
                                  <li
                                      class="ui-menu-item"
                                      data-value="crane_country,djGjqMti9HKvQcg8tO1Iyd2j/kdo79jNz7GkOD0RngJtUw=="><div
                                      class="ui-menu-item-wrapper" role="option">ANDORRA</div>
                                  </li>
                                  <li class="ui-menu-item"
                                      data-value="crane_country,djE6jFbUPodD3jrBLupv4dYjo/Kd6HUIv5Wq34nQLcptJw=="><div
                                      class="ui-menu-item-wrapper" role="option">ANGOLA</div>
                                  </li>
                                  <li class="ui-menu-item"
                                      data-value="crane_country,djFOaXhnh2ddRRaxeQfooc++P4AciHizLwG7dZ+1Qw+CVg=="><div
                                      class="ui-menu-item-wrapper" role="option">ANGUILLA</div>
                                  </li>
                                  <li class="ui-menu-item"
                                      data-value="crane_country,djHV3nYm2WTIIqG9BJR/SYHti9aUlchowSaHNqdlWlj63Q=="><div
                                      class="ui-menu-item-wrapper" role="option">ANTARCTICA</div>
                                  </li>
                                  <li class="ui-menu-item"
                                      data-value="crane_country,djFwQdEbjNQ3Z9IT65BY1eHOQiIm2bBjCc+Kd3QfnvgRLQ=="><div
                                      class="ui-menu-item-wrapper" role="option">ANTIGUA AND BARBUDA</div>
                                  </li>
                                </ul>
                              </div>
                              <datalist id="list-33d7fcec-338d-ed11-81ac-0022480816f1"></datalist>
                            </span>
                          </div>
                        </div>


                      </div>
                    </div>
                    <div class="columnContainer" data-container="true" data-container-width="34"
                         style="display: block; min-height: 70px; min-width: 13.3333px; width: calc(34% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;   word-wrap: break-word; word-break: break-word;  word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-text" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true"><label class="lp-ellipsis"
                                                                                       for="fc0308ab-609e-45c8-9f5e-9eca3511dc39"
                                                                                       title="">City<span
                              class="lp-required" style="color: rgb(255, 0, 0);">*</span></label><input
                              id="fc0308ab-609e-45c8-9f5e-9eca3511dc39" aria-required="true" class="lp-form-fieldInput"
                              name="fc0308ab-609e-45c8-9f5e-9eca3511dc39" pattern=".*\S+.*" placeholder=""
                              required="required" style="width: 100%; box-sizing: border-box;" title="" type="text">
                          </div>
                        </div>


                      </div>
                    </div>

                    <div class="columnContainer" data-container="true"
                         data-container-width="33"
                         style="display: block; min-height: 70px; min-width: 13.3333px; width: calc(33% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;   word-wrap: break-word; word-break: break-word;  word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Field-text" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true"><label class="lp-ellipsis"
                                                                                       for="eae4766c-f91a-4648-afb1-259b97e89cab"
                                                                                       title="">Zip Code<span
                              class="lp-required" style="color: rgb(255, 0, 0);">*</span></label><input
                              id="eae4766c-f91a-4648-afb1-259b97e89cab" aria-required="true" class="lp-form-fieldInput"
                              name="eae4766c-f91a-4648-afb1-259b97e89cab" pattern=".*\S+.*" placeholder=""
                              required="required" style="width: 100%; box-sizing: border-box;" title="" type="text">
                          </div>
                        </div>


                      </div>
                    </div>
                    <div style="clear:both;"></div>
                  </div>
                </div>
                <div class="emptyContainer" data-section="true" style="padding: 10px;">
                  <div class="containerWrapper" style="display: flex;
            width: 100%;
        ;flex-wrap: wrap;">
                    <div style="clear:both;"></div>
                    <div class="columnContainer" data-container="true" data-container-width="100"
                         style="display: block; min-height: 70px; min-width: 20px; width: calc(100% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;      word-wrap: break-word; word-break: break-word;     word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Text"><p><strong><span style="font-size:20px;">Please, tell us more about your request</span></strong>
                      </p>


                      </div>
                      <div data-editorblocktype="Field-textarea" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true"><label class="lp-ellipsis"
                                                                                       for="83b8f192-248d-ed11-81ac-002248081ae5"
                                                                                       title="">Details<span
                              class="lp-required" style="color: rgb(255, 0, 0);">*</span></label><textarea
                              id="83b8f192-248d-ed11-81ac-002248081ae5" aria-required="true" class="lp-form-fieldInput"
                              cols="20" name="83b8f192-248d-ed11-81ac-002248081ae5"
                              pattern=".*\S+.*" required="required" rows="5"
                              style="width: 100%; box-sizing: border-box;" title="" value=""></textarea></div>
                        </div>


                      </div>
                    </div>
                    <div style="clear:both;"></div>
                  </div>
                </div>
                <div class="emptyContainer" data-section="true" style="padding: 10px;">
                  <div class="containerWrapper" style="display: flex;
            width: 100%;
        ;flex-wrap: wrap;">
                    <div style="clear:both;"></div>
                    <div class="columnContainer" data-container="true" data-container-width="100"
                         style="display: block; min-height: 70px; min-width: 20px; width: calc(100% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;   word-wrap: break-word; word-break: break-word;  word-wrap: break-word; word-break: break-word;">
                      <div data-editorblocktype="Text"><p><span style="font-size:18px;">Would you like to receive our supply chain insights, logistics news, and market trends in your inbox?</span>
                      </p>
                      </div>
                      <div data-editorblocktype="SubscriptionListBlock">
                        <div class="marketing-subscription-list"><input id="8fd597f4-7a3b-ed11-9db0-000d3a305d6a"
                                                                        checked="checked"
                                                                        class="lp-form-fieldInput"
                                                                        name="8fd597f4-7a3b-ed11-9db0-000d3a305d6a"
                                                                        type="checkbox" value="Yes"><label
                            class="lp-ellipsis" for="8fd597f4-7a3b-ed11-9db0-000d3a305d6a">Subscribe me</label></div>


                      </div>
                    </div>
                    <div style="clear:both;"></div>
                  </div>
                </div>
                <div class="" data-section="true" style="padding: 10px;">
                  <div class="containerWrapper" style="display: flex;
            width: 100%;
        ;flex-wrap: wrap;">
                    <div style="clear:both;"></div>
                    <div class="columnContainer" data-container="true" data-container-width="100"
                         style="display: block; min-height: 70px; min-width: 20px; width: calc(100% - 0px); box-sizing: border-box; flex-direction: column; padding: 10px; float: left;      word-wrap: break-word; word-break: break-word;     word-wrap: break-word; word-break: break-word;">

                      <div data-editorblocktype="SubmitButtonBlock">
                        <p>
                          <button class="button button_color_secondary lp-form-button lp-form-fieldInput"
                                  name="submit24df7dab-92a7-ae82-932c-0b51a8ba59a5" type="submit">Connect with our
                            experts
                          </button>
                        </p>


                      </div>
                      <div data-editorblocktype="Content" data-lookup-id="c20d1811-3025-ec11-b6e5-002248247924"
                           data-lookup-name="Marketing Communications Disclaimer"
                           data-protected="true"
                           style="font-family: Verdana,  Arial,
                             sans-serif; font-size: 14px; color: #000;">
                        <div class="contentBlockWrapper">
                          <!--                          <meta content="text/html; charset=utf-8" http-equiv="Content-Type">-->
                          <!--                          <meta content="width=device-width, initial-scale=1.0" name="viewport">-->
                          <!--                          <meta content="never" name="referrer">-->
                          <!--                          <meta name="type" type="xrm/designer/setting"-->
                          <!--                                value="marketing-designer-content-editor-document">-->
                          <!--                          <meta datatype="font" label="Font Family" name="font-family" type="xrm/designer/setting"-->
                          <!--                                value="Arial">-->
                          <!--                          <meta datatype="text" label="Body Font Size" name="body-text-size" type="xrm/designer/setting"-->
                          <!--                                value="14px">-->
                          <!--                          <meta datatype="color" label="Body Text Color" name="body-text-color" type="xrm/designer/setting"-->
                          <!--                                value="#000">-->
                          <div class="" style="">
                            <div data-block-datatype="Text" data-sub-block="true" style="margin: 0px;"><p
                                style="margin: 0px; padding: 0px; line-height: 20px; mso-line-height-rule: at-least;">
                              <span style="font-size:9px;"><span style="font-family:Arial,Helvetica,sans-serif;"></span></span><span
                                style="font-size:8px;">By entering your details on this page and form, you agree to receive communications about how Crane Worldwide Logistics can help you and your company. You may unsubscribe from emails at any time by clicking the link within the footer of our emails. You can view our <a
                                data-msdyn-tracking="true" data-msdyn-tracking-id="ce9b0c15-129f-4dff-a499-4d92d1e9fb3f"
                                href="https://craneww.com/privacy-policy/">Privacy Policy</a> if you want more information on our privacy practices.</span><br>
                            </p></div>
                          </div>
                        </div>
                      </div>
                      <div class="" data-editorblocktype="Field-text"
                           hidden="hidden" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="marketing-field">
                          <div class="lp-form-field" data-required-field="true"><label class="lp-ellipsis"
                                                                                       for="01e51d74-248d-ed11-81ac-002248081ae5"
                                                                                       title="">Lead Topic<span
                              class="lp-required" style="color: rgb(255, 0, 0);">*</span></label><input
                              id="01e51d74-248d-ed11-81ac-002248081ae5" aria-required="true" class="lp-form-fieldInput"
                              name="01e51d74-248d-ed11-81ac-002248081ae5" pattern=".*\S+.*" placeholder=""
                              required="required" style="width: 100%; box-sizing: border-box;" title="" type="text"
                              value="Website lead"></div>
                        </div>


                      </div>
                    </div>
                    <div style="clear:both;"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!--          <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007"></script>-->

          <div id="dihujgh1mEJ39PvZdx0zT9t8aas8eWUgvjomXYlgou7g">&nbsp;</div>
          <!--          <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007"></script>-->

          <div class="d365-mkt-config" data-hostname="82416f33396b4f7e9803965e8d096e16.svc.dynamics.com"
               data-website-id="ihujgh1mEJ39PvZdx0zT9t8aas8eWUgvjomXYlgou7g" style="display:none">&nbsp;
          </div>
          <!-- <php echo $form; ?> -->

          <!-- <div data-form-block-id="7835987c-b560-eb11-a812-000d3a1cc57f"></div>
          <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.74.2001.0"></script>
          <div id="dXTZKZYvYfmEMHACd056ucwN242WYIbTNym4TyD267_E"></div>
          <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.74.2001.0"></script>
          <div class="d365-mkt-config" style="display:none" data-website-id="XTZKZYvYfmEMHACd056ucwN242WYIbTNym4TyD267_E" data-hostname="1e4260af427340dc89d4009a70fb7fc2.svc.dynamics.com"></div> -->

        </div><!-- .section -->
      </div><!-- .container -->
    </div>

  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {quotationData} from "@/assets/mock/single";

export default {
  name: "quotation",
  components: {CommonBanner},
  data() {
    return {
      quotationData
    }
  }
}
</script>

<style scoped>
form div.lp-form-field {
  word-wrap: break-word;
  word-break: break-word;
}

form div.lp-radioButtonsContainer {
  width: 50%;
}

form span.lp-radioButton input {
  width: 16px;
  height: auto;
  margin-right: 8px;
  border-radius: 50%;
  float: right;
}

form div.marketing-field input[type="checkbox"],
form div.marketing-subscription-list input {
  width: 16px;
  height: auto;
  margin-right: 8px;
  vertical-align: bottom;
}

form span.lp-checkboxListItem,
form span.lp-radioButton {
  /* This rule is needed to style all radio button fields. For product constraints each option is defined as input and label wrapped into a span*/
  display: block;
  margin: 2px;
}

form *[data-layout="true"] {
  margin: 0 auto;
  max-width: /* @layout-max-width */ 600px /* @layout-max-width */;
}

@media only screen and (max-width: 768px) {
  .columnContainer {
    width: 100% !important;
  }
}

div[data-form-block-id] .dialogProviderMessageContainerStyle {
  color: #A80000;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

div[data-form-block-id] #validation-summary p[role=alert] {
  color: red;
}

div[data-form-block-id] .dialogProviderInternalContainerStyle {
  padding: 20px;
  position: absolute;
}

div[data-form-block-id] .dialogProviderIconContainerStyle {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

div[data-form-block-id] .dialogProviderLinkContainerStyle {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

div[data-form-block-id] .dialogProviderContainerStyle {
  background: white;
  position: relative;
}

div[data-form-block-id] .dialogProviderLinkButtonContainerStyle {
  margin-top: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

div[data-form-block-id] .dialogProviderLinkButtonStyle {
  background: none;
  background-color: transparent;
  border: none;
  font-size: 12px;
  color: #757575;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}

div[data-form-block-id] .reloadButtonStyle, .noIconButtonStyle {
  display: flex;
  color: #000000 !important;
  text-decoration: none;
}

div[data-form-block-id] .reloadButtonStyle:before {
  display: flex;
  align-items: center;
  margin-right: 8px;
  content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1328 0.296875C10.9974 0.53125 11.7891 0.898438 12.5078 1.39844C13.2266 1.89323 13.8438 2.48177 14.3594 3.16406C14.8802 3.84115 15.2839 4.59375 15.5703 5.42188C15.8568 6.24479 16 7.10417 16 8C16 8.73438 15.9036 9.44271 15.7109 10.125C15.5234 10.8073 15.2552 11.4453 14.9062 12.0391C14.5625 12.6328 14.1458 13.1745 13.6562 13.6641C13.1719 14.1484 12.6328 14.5651 12.0391 14.9141C11.4453 15.2578 10.8073 15.526 10.125 15.7188C9.44271 15.9062 8.73438 16 8 16C7.26562 16 6.55729 15.9062 5.875 15.7188C5.19271 15.526 4.55469 15.2578 3.96094 14.9141C3.36719 14.5651 2.82552 14.1484 2.33594 13.6641C1.85156 13.1745 1.4349 12.6328 1.08594 12.0391C0.742188 11.4453 0.473958 10.8099 0.28125 10.1328C0.09375 9.45052 0 8.73958 0 8C0 7.27083 0.0963542 6.5625 0.289062 5.875C0.481771 5.1875 0.755208 4.54167 1.10938 3.9375C1.46875 3.32812 1.90365 2.77604 2.41406 2.28125C2.92448 1.78125 3.5 1.35417 4.14062 1H2V0H6V4H5V1.67969C4.39062 1.97135 3.83854 2.33854 3.34375 2.78125C2.85417 3.21875 2.4349 3.71354 2.08594 4.26562C1.73698 4.8125 1.46875 5.40365 1.28125 6.03906C1.09375 6.67448 1 7.32812 1 8C1 8.64062 1.08333 9.26042 1.25 9.85938C1.41667 10.4531 1.65104 11.0104 1.95312 11.5312C2.26042 12.0469 2.6276 12.5182 3.05469 12.9453C3.48177 13.3724 3.95312 13.7396 4.46875 14.0469C4.98958 14.349 5.54688 14.5833 6.14062 14.75C6.73438 14.9167 7.35417 15 8 15C8.64062 15 9.25781 14.9167 9.85156 14.75C10.4505 14.5833 11.0078 14.349 11.5234 14.0469C12.0443 13.7396 12.5182 13.3724 12.9453 12.9453C13.3724 12.5182 13.737 12.0469 14.0391 11.5312C14.3464 11.0104 14.5833 10.4531 14.75 9.85938C14.9167 9.26562 15 8.64583 15 8C15 7.21875 14.8724 6.46615 14.6172 5.74219C14.3672 5.01823 14.0156 4.35938 13.5625 3.76562C13.1094 3.17188 12.5677 2.65885 11.9375 2.22656C11.3125 1.78906 10.6224 1.46615 9.86719 1.25781L10.1328 0.296875Z' fill='%232266E3'/%3E%3C/svg%3E%0A");
}

div[data-form-block-id] .onFormSubmittedFeedback {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  margin: 0 auto;
}

div[data-form-block-id] .onFormSubmittedFeedbackIcon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 64px;
  size: 64px;
}

div[data-form-block-id] .onFormSubmittedFeedback .onFormSubmittedFeedbackMessage {
  padding: 30px 10px 40px 10px;
  color: black;
  font-size: 16px;
  font-family: Segoe UI;
  text-align: center;
}

div[data-form-block-id] .onFormSubmittedFeedback .onFormSubmittedFeedbackButton {
  min-width: 80px;
  min-height: 32px;
  font-size: 14px;
  border-radius: 2px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

div[data-form-block-id] .onFormSubmittedFeedback .onFormSubmittedFeedbackButtonSuccess {
  color: white;
  background-color: #0078D4;
  border: 1px solid #0078D4;
}

div[data-form-block-id] .onFormSubmittedFeedback .onFormSubmittedFeedbackButtonFail {
  color: black;
  background-color: white;
  border: 1px solid #8A8886;
}

div[data-form-block-id] .onFormSubmittedFeedback .onFormSubmittedFeedbackInternalContainerStyle {
  padding: 20px;
}

div[data-form-block-id] .formLoader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0078D4;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

div[data-form-block-id] .mkt-invalid-field, div[data-form-block-id] .mkt-required-field {
  border-color: red;
}

div[data-form-block-id] input.mkt-invalid-field[type="checkbox"], div[data-form-block-id] input.mkt-invalid-field[type="radio"], div[data-form-block-id] input.mkt-required-field[type="checkbox"], div[data-form-block-id] input.mkt-required-field[type="radio"] {
  outline: 1px solid red;
}

div[data-form-block-id] .ui-selectmenu-open {
  display: block !important;
}

div[data-form-block-id] .ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

div[data-form-block-id] .ui-front {
  z-index: 100;
}

div[data-form-block-id] .ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: 0;
}

div[data-form-block-id] .ui-menu .ui-retry-item {
}

div[data-form-block-id] .ui-menu .ui-retry-item-wrapper {
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

div[data-form-block-id] .ui-menu .ui-retry-text {
  text-align: center;
  font-size: 12px;
  color: #808080;
}

div[data-form-block-id] .ui-menu .ui-retry-button-wrapper {
  margin-top: 32px;
  font-size: 12px;
}

div[data-form-block-id] .ui-menu .ui-retry-button {
  display: flex;
  align-items: center;
  background: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

div[data-form-block-id] .ui-menu .ui-retry-button::before {
  display: flex;
  align-items: center;
  margin-right: 8px;
  content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1328 0.296875C10.9974 0.53125 11.7891 0.898438 12.5078 1.39844C13.2266 1.89323 13.8438 2.48177 14.3594 3.16406C14.8802 3.84115 15.2839 4.59375 15.5703 5.42188C15.8568 6.24479 16 7.10417 16 8C16 8.73438 15.9036 9.44271 15.7109 10.125C15.5234 10.8073 15.2552 11.4453 14.9062 12.0391C14.5625 12.6328 14.1458 13.1745 13.6562 13.6641C13.1719 14.1484 12.6328 14.5651 12.0391 14.9141C11.4453 15.2578 10.8073 15.526 10.125 15.7188C9.44271 15.9062 8.73438 16 8 16C7.26562 16 6.55729 15.9062 5.875 15.7188C5.19271 15.526 4.55469 15.2578 3.96094 14.9141C3.36719 14.5651 2.82552 14.1484 2.33594 13.6641C1.85156 13.1745 1.4349 12.6328 1.08594 12.0391C0.742188 11.4453 0.473958 10.8099 0.28125 10.1328C0.09375 9.45052 0 8.73958 0 8C0 7.27083 0.0963542 6.5625 0.289062 5.875C0.481771 5.1875 0.755208 4.54167 1.10938 3.9375C1.46875 3.32812 1.90365 2.77604 2.41406 2.28125C2.92448 1.78125 3.5 1.35417 4.14062 1H2V0H6V4H5V1.67969C4.39062 1.97135 3.83854 2.33854 3.34375 2.78125C2.85417 3.21875 2.4349 3.71354 2.08594 4.26562C1.73698 4.8125 1.46875 5.40365 1.28125 6.03906C1.09375 6.67448 1 7.32812 1 8C1 8.64062 1.08333 9.26042 1.25 9.85938C1.41667 10.4531 1.65104 11.0104 1.95312 11.5312C2.26042 12.0469 2.6276 12.5182 3.05469 12.9453C3.48177 13.3724 3.95312 13.7396 4.46875 14.0469C4.98958 14.349 5.54688 14.5833 6.14062 14.75C6.73438 14.9167 7.35417 15 8 15C8.64062 15 9.25781 14.9167 9.85156 14.75C10.4505 14.5833 11.0078 14.349 11.5234 14.0469C12.0443 13.7396 12.5182 13.3724 12.9453 12.9453C13.3724 12.5182 13.737 12.0469 14.0391 11.5312C14.3464 11.0104 14.5833 10.4531 14.75 9.85938C14.9167 9.26562 15 8.64583 15 8C15 7.21875 14.8724 6.46615 14.6172 5.74219C14.3672 5.01823 14.0156 4.35938 13.5625 3.76562C13.1094 3.17188 12.5677 2.65885 11.9375 2.22656C11.3125 1.78906 10.6224 1.46615 9.86719 1.25781L10.1328 0.296875Z' fill='%232266E3'/%3E%3C/svg%3E%0A");
}

div[data-form-block-id] .ui-menu .ui-loading-item {
  font-size: 12px;
  background-repeat: no-repeat;
  background-position-x: 8px;
  background-position-y: center;
  background-size: 1em 1em;
}
</style>