export const serviceIndexData = {
  bannerData:{
    title: '国际物流服务',
    desc: "作为提供全方位服务的全球货运代理和合同物流提供商，我们可以帮助您管理供应链的仓库和运输需求，以便您专注于自身的业务需求。 点击获取解决所有运输需求的联系方式！",
    image: require("@/assets/images/service/index/banner.jpg")
  },
  serviceList:[
    {name:'空运',desc:'体验全方位的快速整合全球空运服务。 我们在这里支持您的物流挑战！',path:'/service?type=air'},
    {name:'合同物流',desc:'我们独特的多租户仓库设施遍布全球主要的分销市场，能提供可扩展的仓库解决方案来满足您所有的物流需求。',path:'/service?type=contract'},
    {name:'报关行',desc:'在美国和加拿大为所有入境口岸提供全方位服务的海关清关服务。',path:'/service?type=broker'},
    {name:'陆运',desc:'拥有国内和国际卡车运输能力，能提供公路运输服务以满足您的地面货运需求。',path:'/service?type=land'},
    {name:'海运',desc:'全球海运业务使我们能够覆盖FCL和LCL海运的所有主要货运贸易通道。',path:'/service?type=sea'},
    {name:'项目物流',desc:'解决全球超大型货运挑战的技能和解决方案。 您的项目物流问题-解决了！',path:'/service?type=project'},
    {name:'铁路货运',desc:'从中国到欧洲的安全可靠的铁路货运为我们的客户提供了节约时间和金钱的机会。',path:'/service?type=train'},
    {name:'货运保险',desc:'为您的所有运输需求提供货物保险，以保护您的货物。',path:'/service?type=freight'},
    {name:'电子商务航运',desc:'我们的电子商务物流解决方案专为适合您的商业模式而设计。',path:'/service?type=business'},
    {name:'运输管理',desc:'我们的运输管理能力在业界首屈一指，能通过高质量的服务、出色的执行能力、和高端的技术为您提供一流的高频接触解决方案',path:'/service?type=manage'},
    {name:'贸易咨询',desc:'我们的国际贸易咨询项目旨在为您提供最专业的国际贸易和合规信息。',path:'/service?type=trade'},
    {name:'增值服务',desc:'有效而高效的解决方案将为您的全球或本地运营增值。',path:'/service?type=added'},
    {name:'电池物流',desc:'运输锂离子电池需要安全可靠的处理和运输。',path:'/service?type=battery'},
  ]
}


export const airData = {
  bannerData:{
    title: serviceIndexData.serviceList[0].name,
    desc: "作为全球发展最快的供应链组织之一，Crane Worldwide Logistics 与其航空公司合作伙伴建立了有意义的关系，以确保一致的航空货运费率、运输和跟踪您的运营。",
    image: require("@/assets/images/service/banner-01.jpg")
  },
  info:{
    title: '最佳空运服务——为您提供帮助！',
    desc: "如需任何空运、空运货物和包机需求，请联系我们专业的空运专家。我们将以最便捷、最具成本效益的空运解决方案支持您的业务，满足您持续的需求。",
    image: require("@/assets/images/service/intro-01.jpg")
  }
}
export const contractData = {
  bannerData:{
    title: serviceIndexData.serviceList[1].name,
    desc: "我们为客户提供的国际仓储服务是根据他们的具体要求量身定制的、可扩展的仓储解决方案，可满足您的所有物流需求。",
    image: require("@/assets/images/service/banner-02.jpg")
  },
  info:{
    title: '在对您最有利的位置为您提供可扩展的仓库物流解决方案。',
    desc: "Crane Worldwide Logistics 在全球主要分销市场拥有遍布全球的多租户仓库设施。 我们的供应链专业团队可以提供尖端的物流解决方案，并愿意投资于对我们的客户互惠互利的新仓库地点。 多用户或专用仓储，请联系以获取更多信息。",
    image: require("@/assets/images/service/intro-02.jpg")
  }
}
export const brokerData = {
  bannerData:{
    title: '报关员-在这里为您服务！',
    desc: "Crane Worldwide Logistics 在美国各地提供经过认证的报关服务。 需要报关行吗？ 立即与海关代理联系！",
    image: require("@/assets/images/service/banner-03.jpg")
  },
  info:{
    title: '什么是报关行？',
    desc: "作为一家受美国海关和边境保护局 (CBP) 监管和授权的持牌公司，作为报关行，我们协助进出口商满足有关进出口的联邦要求。 我们的报关团队在海关入境程序、准入要求、分类、估价以及进口商品的关税和适用税费方面拥有丰富的经验。 很难找到能够快速响应您需求的优秀海关代理，今天就联系我们吧！",
    image: require("@/assets/images/service/intro-03.jpg")
  }
}
export const landData = {
  bannerData:{
    title: '地面运输',
    desc: "我们提供国内和国际货运服务以满足客户的需求。 立即索取货运报价！",
    image: require("@/assets/images/service/banner-04.jpg")
  },
  info:{
    title: '一流的地面服务',
    desc: "我们充分理解，在当今竞争激烈的市场中，您需要物流合作伙伴提供可访问性、可靠性和解决方案来创造竞争优势，而不仅仅是货运服务。 这就是我们所提供的； 我们通过我们的人员和流程以及在我们所做的一切事情中利用一流的技术来做到这一点。",
    image: require("@/assets/images/service/intro-04.jpg")
  }
}
export const seaData = {
  bannerData:{
    title: '海运',
    desc: "我们丰富的海运承运产品组合可提供灵活的出发日期并缩短运输时间，以满足您的所有海运需求。我们提供全球整箱和拼箱运输服务。",
    image: require("@/assets/images/service/banner-05.jpg")
  },
  info:{
    title: '您需要国际海运服务吗？',
    desc: "增加的购买力允许有竞争力的市场定价和更高的海运服务水平。 我们在 Crane Worldwide Logistics 与铁路服务提供商和入境/卸货港的灵活性使我们能够为我们的客户创建最佳的海运解决方案，运输时间满足您的交付要求。 需要具有成本效益的门到门海运服务？ 立即联系以获取有关如何支持您的货运的更多信息。",
    image: require("@/assets/images/service/intro-05.jpg")
  }
}
export const projectData = {
  bannerData:{
    title: '项目物流',
    desc: "Crane Worldwide Logistics运营专家在评估最具挑战性的超大尺寸和超限货物的包装、吊高、运输和固定要求方面非常熟练。项目物流问题在这里得到了完美解决！",
    image: require("@/assets/images/service/banner-06.jpg")
  },
  info:{
    title: '在您的重型货物上需要帮助吗？',
    desc: "作为澳大利亚经济的主要贡献者之一，采矿业早已成为一个重要行业，不仅提供了出口收入，而且还为澳大利亚主要地区提供了就业机会。 详细了解我们如何帮助我们的客户将超重的电梯运往澳大利亚。 如果您需要项目后勤方面的帮助，请与我们联系！",
    image: require("@/assets/images/service/intro-06.jpg")
  }
}
export const trainData = {
  bannerData:{
    title: '铁路货运',
    desc: "随着时间和成本节省压力的增加，Crane Worldwide Logistics的物流专家制定了中欧铁路货运计划，可以为我们的客户提供传统空运或海运解决方案的替代方案。",
    image: require("@/assets/images/service/banner-07.jpg")
  },
  info:{
    title: '加拿大铁路罢工',
    desc: "请参阅我们关于加拿大铁路货运罢工的常见问题解答。我们在温哥华、卡尔加里和多伦多设有办事处，随时为您的业务提供支持，请随时联系 Crane Worldwide Logistics 加拿大团队的成员。",
    image: require("@/assets/images/service/intro-07.jpg")
  }
}
export const freightData = {
  bannerData:{
    title: '货运保险',
    desc: "什么是货物保险？为什么您需要货物运输的保险？",
    image: require("@/assets/images/service/banner-08.jpg")
  },
  info:{
    title: '什么是货运保险？',
    desc: "法律规定所有承运人提供最低保险金额。 但是，承运人责任提供的货物保险范围非常有限，并且在发生不可预见的事件（例如自然灾害或运输途中的事故）时，托运人可以做好准备并申请全额运费保险，以保护他们的货物免于丢失、被盗或损坏。 对于完全覆盖的损失，我们强烈建议您购买全面的货物保险。",
    image: require("@/assets/images/service/intro-08.jpg")
  }
}
export const businessData = {
  bannerData:{
    title: '电子商务物流与履行',
    desc: "我们的专家不断开发创新的运输解决方案，以解决全球零售商面临的挑战。 我们的电子商务履行解决方案经过定制和定制，以确保您的供应链处于最佳状态。",
    image: require("@/assets/images/service/banner-09.jpg")
  },
  info:{
    title: '可配置且响应迅速的电子商务解决方案',
    desc: "借助我们增强的运输可见性、可扩展的全球基础设施和专门的客户服务，轻松管理您的电子商务运营。 我们的首要任务是找到我们具有成本效益的解决方案适合您的供应链的领域，使其更有利可图。",
    image: require("@/assets/images/service/intro-09.jpg")
  }
}
export const manageData = {
  bannerData:{
    title: '供应链管理4PL物流',
    desc: "灵活、可扩展的解决方案可计划、执行和优化货物运输",
    image: require("@/assets/images/service/banner-10.png")
  },
  info:{
    title: '具有快速集成的量身定制的解决方案',
    desc: "我们为客户创建量身定制的供应链和物流解决方案，我们的目标是帮助您以最高的费率，高效率和端到端可见性运输从原材料和零件到成品的所有产品。",
    image: require("@/assets/images/service/intro-10.jpg")
  }
}
export const tradeData = {
  bannerData:{
    title: '供应链管理4PL物流',
    desc: "我们的国际贸易咨询服务计划旨在在您最需要的时候提供专业的国际贸易和合规信息。",
    image: require("@/assets/images/service/banner-11.jpg")
  },
  info:{
    title: '2023年及以后的国际贸易...',
    desc: "在过去的几年中，贸易中断给世界各地的供应链带来了许多挑战。 贸易战，USMCA，英国退欧都是引发国际运输和货物进出口的诱因。 我们的贸易咨询团队可以在这种动荡的环境中为您提供支持，以确保您和您的全球团队为全球货物运输的新规则和贸易法规做好准备。",
    image: require("@/assets/images/service/intro-11.jpg")
  }
}
export const addedData = {
  bannerData:{
    title: '增值解决方案',
    desc: "提供咨询服务，我们的目标是提供有效和高效的物流解决方案，以增加本地和全球运营的价值。",
    image: require("@/assets/images/service/banner-12.jpg")
  },
  info:{
    title: '咨询服务',
    desc: "利用我们与现有客户一起开发的经验和网络以及我们在世界各地采用的专业知识，我们将与客户合作制定解决方案，以提供根据他们的需求量身定制的独特解决方案。 您在寻找增值仓储和配送吗？ 伸手。",
    image: require("@/assets/images/service/intro-12.png")
  }
}
export const batteryData = {
  bannerData:{
    title: '电池物流：运输、储存和运输',
    desc: "运输锂离子电池需要安全可靠的处理和运输。",
    image: require("@/assets/images/service/banner-13.jpg")
  },
  info:{
    title: '运输锂离子电池 - 物流和供应链',
    desc: "锂离子电池是全球增长最快的市场之一。 可持续发展法规和对电动汽车 (EV) 的需求必将推动市场走得比许多人想象的更远。 但是供应链流程是否已准备好满足这种需求？ 要回答这个问题，必须考虑市场背后的实际增长。",
    image: require("@/assets/images/service/intro-13.png")
  }
}