<template>
  <div id="header" class="section section_size_xl section_header">
    <div class="container">
      <div class="grid justify_between align-items_center">
        <div class="grid__item size_auto_lg"><a class="logo" href="/"> <img
            alt="起重机全球" role="img" src="@/assets/images/logo.svg"> </a></div>
        <div class="grid__item size_auto d_block d_none_hd">
          <div class="level"><a class="button button_color_secondary d_none d_flex_xs"
                                href="/quotation">要求报价</a> <a
              class="button button_outline_secondary" href="/%E6%90%9C%E7%B4%A2%E3%80%81/">
            <svg class="icon" role="img">
              <use xlink:href="#icon-search"></use>
            </svg>
          </a>
            <button class="modal__trigger button button_icon button_outline_secondary d_none_md"
                    data-modal="modal-menu">
              <svg class="icon" role="img"><title>移动菜单切换</title>
                <use xlink:href="#icon-menu"></use>
              </svg>
            </button>
          </div>
        </div>
        <div class="grid__item size_12 size_auto_hd d_none d_block_md">
          <ul class="menu menu_theme_delimiter justify_center">
            <li v-for="(item,index) in navList" :key="index" class="menu__item dropdown on-hover">
              <a :href="item.path" class="menu__link ">{{ item.name }}</a>
              <ul v-if="item.children && item.children.length" class="dropdown__menu dropdown__menu_pos_down">
                <li v-for="(item1,index1) in item.children" :key="index1" class="dropdown__item"><a
                    :href="item1.path"
                    class="dropdown__link ">{{ item1.name }}</a>
                  <ul v-if="item1.children && item1.children.length" class="dropdown__menu dropdown__menu_pos_switch">
                    <li v-for="(item2,index2) in item2.chlidren" :key="index2" class="dropdown__item">
                      <a
                          :href="item.path"
                          class="dropdown__link ">{{ item.name }}</a>
                    </li>

                  </ul>
                </li>
              </ul>
            </li>

          </ul>
        </div>
        <div class="grid__item size_auto d_none d_none_md d_block_hd"><a class="button button_color_secondary"
                                                                         href="/quotation">要求报价</a>
          <!--          <a class="button button_outline_secondary" href="/%E6%90%9C%E7%B4%A2%E3%80%81/">-->
          <!--            <svg class="icon" role="img">-->
          <!--              <use xlink:href="#icon-search"></use>-->
          <!--            </svg>-->
          <!--          </a>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "customNav",
  data() {
    return {
      navList: [
        {
          name: '服务', path: '/service?type=index', children: [
            {name: '空运', path: '/service?type=air',},
            {name: '合同物流', path: '/service?type=contract',},
            {name: '报关行', path: '/service?type=broker',},
            {name: '陆运', path: '/service?type=land',},
            {name: '海运', path: '/service?type=sea',},
            {name: '项目物流', path: '/service?type=project',},
            {name: '铁路货运', path: '/service?type=train',},
            {name: '货运保险', path: '/service?type=freight',},
            {name: '电子商务航运', path: '/service?type=business',},
            {name: '运输管理', path: '/service?type=manage',},
            {name: '贸易咨询', path: '/service?type=trade',},
            {name: '增值服务', path: '/service?type=added',},
            {name: '电池物流', path: '/service?type=battery',},
          ]
        },
        {
          name: '行业应用', path: '/industry?type=index', children: [
            {name: '航空航天', path: '/industry?type=aerospace',},
            {name: '汽车行业', path: '/industry?type=car',},
            {name: '消费品', path: '/industry?type=goods',},
            {name: '邮轮，航海和款待', path: '/industry?type=cruise',},
            {name: '能源', path: '/industry?type=energy',},
            {name: '政府', path: '/industry?type=government',},
            {name: '高科技行业', path: '/industry?type=tech',},
            {name: '工业', path: '/industry?type=industrial',},
            {name: '生命科学', path: '/industry?type=life',},
            {name: '采矿', path: '/industry?type=mining',},
          ]
        },
        {
          name: '专业技术', path: '/technology?type=index', children: [
            {name: 'Shipment Tracking', path: '/technology?type=shipment',},
            {name: '采购订单管理系统', path: '/technology?type=purchase',},
            {name: '仓库管理系统(WMS)', path: '/technology?type=wms',},
            {name: '通用集成标注', path: '/technology?type=standard',},
          ]
        },
        {
          name: '关于我们', path: '/about?type=index', children: [
            {name: '我们的价值', path: '/about?type=ourValue',},
            {name: '领导团队', path: '/about?type=leader',},
            {name: '职业机会', path: '/about?type=careers',},
            {name: '永续发展', path: '/about?type=sustain',},
            {name: '社区支持', path: '/about?type=community',},
            {name: '安全与防范', path: '/about?type=security',},
            {name: '合规性', path: '/about?type=compliance',},
            {name: 'QHSE', path: '/about?type=qhse',},
          ]
        },
        {name: '资源库', path: '/news?type=index', children: [
            {name: '市场更新', path: '/news?type=market',},
            {name: '行业洞察新闻', path: '/news?type=industry',},
            {name: '贸易咨询公告', path: '/news?type=trade',},
            {name: '客户案例', path: '/news?type=case',},
            // {name: '播客', path: '/news?type=podcast',},
            {name: 'Incoterms® - 运输条款指南', path: '/news?type=guide',},
            {name: '新闻中心', path: '/news?type=press',},
            {name: '英国脱欧和欧盟', path: '/news?type=exit',},
          ]},
        // {name: '地址', path: '/', children: []},
        {name: '职业机会', path: '/about?type=careers', children: []},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-nav {
  padding: 2rem;
  background-color: #ffffff;

  .container {
    width: 140rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    padding: 1.5rem;
  }

  .nav-list {
    display: flex;

    .menu-item {
      border-color: #132d76;

      a {
        color: #132d76;
        font-size: 1.6rem;
      }
    }

  }
}
</style>