<template>
  <div>
    <common-banner :bannerData="carData.bannerData"></common-banner>

    <!--    <service-intro :infoData="aerospaceData.info"></service-intro>-->
    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>需要全球汽车物流服务？</h1>
            <p class="gt-block">如果您需要汽车物流公司，我们的全球汽车物流服务可以为您提供帮助。 我们的汽车物流服务包括多式联运国际运输：
              <strong><a href="/service?type=land">公路货运</a></strong>, <strong><a
                  href="/service?type=air">空运</a>,</strong>&nbsp;和&nbsp;<strong><a
                  href="/service?type=sea">海运</a></strong> 此外&nbsp;<strong><a
                  href="/service?type=contract">合同物流</a></strong>
              的解决方案。</p>
            <p>根据我们客户的要求（OEM 或售后市场服务提供商），我们的服务包括供应商管理、全球整合网络以及限时空运服务。
              我们将建立一个定制的供应链计划来解决您的汽车物流挑战。</p>
            <p>作为全球汽车物流解决方案和汽车供应链管理提供商，我们努力提供从制造商到配送中心的下游运输服务。
              我们能够分配资源来处理物流行业内虚拟货运需求，使我们的汽车客户能够实现所有需求的全面收益。&nbsp;</p>
            <p class="gt-block">我们行业领先 <strong><a href="/technology?type=index">货运可见性</a>&nbsp;</strong>技术是为我们的汽车客户提供全面了解其汽车供应链所需的物流数据的关键。
              国际跟踪对于维持广泛纳入全球汽车供应链的准时运营至关重要。 凭借我们强大的技术，我们能够提高备件供应链中库存管理的透明度，从而改善您的客户服务体验。&nbsp;
            </p>
            <p>此外，我们的汽车物流专家精通处理汽车售后市场复杂的供应链管理计划。
              如果您从事车辆零部件、设备和配件的制造、再制造、分销、零售和/或安装业务，我们的全球汽车服务和汽车供应链解决方案将是您的首选解决方案。
              此外，我们作为领先的 LSP（物流服务提供商）建立的合作伙伴关系意味着您能够实现额外的成本避免和节省成本的机会。&nbsp;</p>
            <h3>我们了解您的汽车物流和供应链挑战</h3>
            <p>汽车服务团队将对您的供应链进行全面分析，无论是零部件还是整车物流需求。 我们的重点是整个业务端到端（E2E）。
              从原材料供应商一直到成品交付的这一周期的仔细管理对于成功的车辆物流解决方案至关重要。&nbsp;</p>
            <p>最先进的分析、商业智能技术和极其广泛且屡获殊荣的服务组合相结合，为我们提供支持，确保您实现目标。&nbsp;</p>
            <p>联系我们讨论即将到来的大趋势，例如 4IR 或自动驾驶。 随着未来无人驾驶交通法规的不断变化，我们的团队已准备好回答您的问题。&nbsp;</p>
            <p>我们将共同努力帮助您：</p>
            <ul>
              <li><strong>进入新市场。</strong></li>
              <li><strong>提高组织的运营效率。</strong></li>
              <li><strong>利用有机增长战略来扩大规模。&nbsp;</strong></li>
            </ul>
            <blockquote><p class="gt-block">你准备好迎接当前 <strong>大趋势</strong>&nbsp;汽车行业的应用，例如：端到端供应链、自动驾驶、电气化和电池物流，或作为汽车物流跟踪一部分的区块链可追溯性？
            </p>
              <p class="gt-block"><strong>联系我们的专家，讨论当今最先进的解决方案。&nbsp;</strong></p></blockquote>
            <p style="margin-bottom:11px">我们涵盖的领域包括：</p>
            <ul>
              <li><strong>入库物流</strong> （生产线侧进料、VMI、库存计划）</li>
              <li><strong>生产物流</strong> （配套和包装、组装、包装、交叉转运）</li>
              <li><strong>配送物流</strong> （最后一英里交付、整车物流和特殊处理服务，包括&nbsp;<strong><a
                  href="https://zh-cn.craneww.com/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E7%99%BD%E6%89%8B%E5%A5%97%E4%BA%A4%E4%BB%98%E9%87%87%E8%B4%AD%E6%9C%80%E4%BD%B3%E5%AE%9E%E8%B7%B5/">白手套服务</a></strong>)
              </li>
              <li><strong>售后服务</strong> （服务或备件物流和逆向物流）</li>
              <li><strong>附加增值服务 </strong>和认可，包括 HAZ-MAT，用于处理或回收报废产品和材料（电池、液体和半导体）</li>
            </ul>
            <p class="gt-block"><img alt="汽车制造汽车厂" class="align_right"
                                     src="https://www.craneww.com/site/assets/files/1050/industries_automotive.320x0-is.jpg"
                                     width="320">在全球物流业务的支持下 <strong>世界各地</strong>，无论您身在何处，都可以使用我们的汽车物流专业知识。</p>
            <p class="gt-block">此外，我们的国际 <strong><a
                href="/service?type=trade">贸易咨询</a></strong>&nbsp;和
              <a href="/service?type=broker"><strong>报关行</strong> </a>我们提供的服务可支持您的战略性汽车物流和供应链决策，提供宝贵的见识和国际贸易专业知识，以增强您在美国，加拿大和全球的竞争优势！
            </p>
            <p class="gt-block">随着电动汽车使用量的增加，Crane Worldwide Logistics 可以提供广泛的服务来支持行业向前发展，从电动汽车充电站的存储和交付到
              <strong><a href="/service?type=battery">电池物流</a></strong>，请随时联系我们，对您的供应链需求进行个人审查。
              我们的目标是与客户合作，在供应链中发展竞争优势。&nbsp;</p>
            <p class="gt-block">我们的汽车物流专家随时为您提供敏捷、灵活的解决方案帮助。 <strong><a
                href="https://craneww.com/request-a-quote/">请求报价</a></strong> 今天为您提供 Crane Worldwide 的汽车物流服务。&nbsp;<br>&nbsp;
            </p></div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">了解最新消息</h3>
         <press-list type="market"></press-list>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {carData} from "@/assets/mock/industry";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, CommonBanner},
  data() {
    return {
      carData
    }
  }
}
</script>

<style scoped>

</style>