<template>
  <div>


    <template v-if="currentNews">
      <div v-if="$route.query.type === 'market' || $route.query.type === 'industry'"
           class="section section_size_xl bg_primary-dark">
        <div class="section__container container">
          <div class="section__intro type type_inverted">
            <h1 class="c_white">{{ currentNews.title }}</h1>
          </div>
        </div>
        <img :src="currentNews.image"
             alt="" class="section__background" height="800" width="1200">
        <div class="section__screen bg_primary-dark"></div>
      </div>

      <div v-if="$route.query.type === 'trade' || $route.query.type === 'press' || $route.query.type === 'exit'"
           class="section section_size_s bg_primary-dark">
        <div class="section__container container">
          <div class="section__intro type type_inverted">
            <p>{{ currentNews.time }}</p>
            <h1 class="c_white">{{ currentNews.title }}</h1>
          </div>
        </div>
        <div class="section__screen bg_primary-dark"></div>
      </div>


      <div class="section">
        <div class="container container_size_xs">
          <div class="type" v-html="currentNews.detail"></div>
        </div>
      </div>

    </template>

    <quotation></quotation>
  </div>

</template>

<script>
import {exitData, industryData, marketData, pressData, tradeData} from "@/assets/mock/news";
import Quotation from "@/components/quotation.vue";

export default {
  name: "detail",
  components: {Quotation},
  computed: {
    currentNews() {
      let arr = [];
      if (this.$route.query.type === 'market') {
        arr = marketData.list;
      } else if (this.$route.query.type === 'industry') {
        arr = industryData.list
      } else if (this.$route.query.type === 'trade') {
        arr = tradeData.list
      } else if (this.$route.query.type === 'press') {
        arr = pressData.list
      } else if (this.$route.query.type === 'exit') {
        arr = exitData.list
      }
      console.log(arr)
      return arr.find(item => item.id === this.$route.query.id);

    }
  }
}
</script>

<style scoped>

</style>