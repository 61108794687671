export const technologyData = {
  bannerData: {
    title: '技术',
    desc: "我们独有的一流技术工具集与物流流程无缝集成，使供应链更加高效。 我们致力于提供定制的物流解决方案，以满足您的业务需求。",
    image: require("@/assets/images/technology/banner-01.jpg")
  },
  info: {
    title: '解决您的物流挑战的基础设施和创新',
    desc: "我们不断改进我们的系统，开发更好的流程，并投资物流工具以满足您的供应链需求。 作为您的数字货运代理，我们旨在为您的库存、发货和其他业务领域提供适应性、易于集成、先进的货物可见性和全面控制。",
    image: require("@/assets/images/technology/intro-01.png"),
  }
}

export const shipmentData = {
  bannerData: {
    title: 'C-视图 | 货件追踪器',
    desc: "C-View 是我们的虚拟视点，客户可以通过 Crane Worldwide Logistics 自由访问和跟踪他们的货物。",
    image: require("@/assets/images/technology/banner-02.jpg")
  },
  info: {
    title: '首选的预测物流指挥中心',
    desc: "从取货到交货，以及其间的所有里程碑，我们的物流门户 C-View 提供端到端的供应链可见性。 通过货件跟踪器轻松跟踪和追踪，主动管理供应链中的异常情况，监控准时绩效和随时间推移的支出。 借助轻松的跟踪系统，即使在移动设备上，完全可见性和透明度触手可及。",
    image: require("@/assets/images/technology/intro-02.jpg"),
  }
}
export const purchaseData = {
  bannerData: {
    title: '查明',
    desc: "我们的采购订单 (PO) 管理系统将 PO 订单项目的详细信息集成到从开始到交付的物流流程中。",
    image: require("@/assets/images/technology/banner-03.jpg")
  },
  info: {
    title: '整个供应链的强大可见性',
    desc: "PinPOint 是我们基于云的集成运输可见性应用程序，将客户与供应商的运营联系起来，提供主动控制和监控从原产地到目的地的产品移动。",
    image: require("@/assets/images/technology/intro-03.jpg"),
  }
}
export const wmsData = {
  bannerData: {
    title: '脚印',
    desc: "我们的平台提供完整的库存管理，跟踪您仓库中的所有物品及其所在位置。",
    image: require("@/assets/images/technology/banner-04.jpg")
  },
  info: {
    title: '跟踪仓库中的所有物品',
    desc: "FOOTPRINT 是一种物流工具，可扩展、灵活和量身定制，以提高仓库运营的生产力和效率。 从收货到发货，全面控制您的库存。",
    image: require("@/assets/images/technology/intro-04.jpg"),
  }
}
export const standardData = {
  bannerData: {
    title: 'EDI 和 API 集成',
    desc: "使用我们的 API 和 EDI 集成，通过更快、更明智的战略决策，与 Crane Worldwide Logistics 联系以实现可持续供应链运营。",
    image: require("@/assets/images/technology/banner-05.png")
  },
  info: {
    title: '让我们帮你连接！',
    desc: "Crane Worldwide 提供灵活的集成选项，允许您实时交换运输和仓储数据。 除了满足您的内部需求外，我们还提供与您批准的所有各方无缝协作的选项，为您提供完全数字化的供应链。",
    image: require("@/assets/images/technology/intro-05.png"),
  }
}