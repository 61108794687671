  <template>
  <div class="section section_toolbar d_none d_block_md">
    <div class="container">
      <div class="grid grid_size_sm grid_flatten align-items_center justify_between text_size_sm">
        <div class="grid__item size_auto level">
          <ul class="gt-block menu menu_theme_delimiter menu_inverted" data-gt-block="">
            <li class="menu__item"><a class="menu__link" href="tel:+1-888-870-2726">+1 888-870-2726</a></li>
            <li class="menu__item"><a class="menu__link" href="/quotation">要求报价</a></li>
            <li class="menu__item"><a class="menu__link" href="/about?type=careers">职业机会</a></li>
          </ul>
        </div>
        <div class="grid__item size_auto">
          <ul class="gt-block menu menu_theme_delimiter menu_inverted" data-gt-block="">
            <li class="menu__item"><a class="menu__link" href="https://portal.craneww.com/login">连接到C-view</a></li>
            <li class="menu__item"><a class="menu__link" href="https://webtracker.craneww.com/">货物查询</a></li>
            <li class="menu__item"><a class="menu__link" href="https://developers.craneww.com/">综合门户网站</a></li>
<!--            <li class="menu__item dropdown on-hover"><a class="menu__link" href="/">语言</a>-->
<!--              <ul class="dropdown__menu dropdown__menu_pos_down">-->
<!--                <li class="dropdown__item"><a class="dropdown__link " href="https://bit.ly/cww_home">英语</a></li>-->
<!--                <li class="dropdown__item"><a class="dropdown__link " href="https://zh-TW.craneww.com">中国（繁体）</a>-->
<!--                </li>-->
<!--                <li class="dropdown__item"><a class="dropdown__link " href="https://zh-CN.craneww.com/">简体中文</a>-->
<!--                </li>-->
<!--                <li class="dropdown__item"><a class="dropdown__link " href="https://de.craneww.com/">德语</a></li>-->
<!--                <li class="dropdown__item"><a class="dropdown__link " href="https://it.craneww.com/">意大利语</a></li>-->
<!--                <li class="dropdown__item"><a class="dropdown__link " href="https://ko.craneww.com">韩语</a></li>-->
<!--                <li class="dropdown__item"><a class="dropdown__link " href="https://pt.craneww.com/">葡萄牙语</a></li>-->
<!--                <li class="dropdown__item"><a class="dropdown__link " href="https://es.craneww.com/">西班牙文</a></li>-->
<!--              </ul>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "customHeader"
}
</script>

<style lang="scss" scoped>

</style>