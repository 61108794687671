<template>
  <div>
    <common-banner :bannerData="tradeData.bannerData"></common-banner>

    <service-intro :infoData="tradeData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>国际贸易格局的变化比以往任何时候都快。</h1>
            <p>为了为我们的全球物流客户提供支持，Crane Worldwide Logistics制定了一项贸易咨询计划，以在您最需要时提供专业的国际贸易和合规信息。
              这种对国际贸易和合规行业的宝贵资源，确保我们的物流客户充分了解如何继续在全球范围内进行进出口，而对他们的供应链造成的干扰却最小。&nbsp;</p>
            <p>
              贸易咨询团队由在进出口和国际贸易合规方面均具有丰富经验的顾问，经理和分析人员组成，包括持照海关经纪人（LCB），经认证的海关专家（CCS），经认证的出口专家（CES）和经认证的美国出口合规官（CUSECO）。</p>
            <h3>国际贸易咨询服务包括：</h3>
            <p class="gt-block">进口审计评估，出口贸易合规培训，分类项目，其他关税清单提交，最小化关税和追回税，受限制方筛选，供应链安全，GTM
              SaaS解决方案，&nbsp;<a
                  href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E8%81%8C%E5%8A%A1%E9%80%80%E7%A8%8E/">责任退税</a>&nbsp;咨询、外贸区评估、自贸协定分析、产品研究与评估、培训与研讨会
            </p>
            <p class="gt-block">除了我们的网络研讨会系列 <strong><a
                href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E7%BD%91%E7%BB%9C%E7%A0%94%E8%AE%A8%E4%BC%9A/">“ Industry
              Insight Live”</a></strong>，Crane Worldwide Logistics贸易咨询顾问可以根据您的要求提供许多不同的研讨会，包括：&nbsp;
            </p>
            <p class="gt-block">进口101 /出口101，统一关税表原则，自由贸易协定，&nbsp; <a
                href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E5%9B%BD%E9%99%85%E8%B4%B8%E6%98%93%E6%9C%AF%E8%AF%AD%E8%A7%A3%E9%87%8A%E9%80%9A%E5%88%992020/">Incoterms
              2020</a>,&nbsp;<a
                href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E5%85%B3%E4%BA%8E%E5%A4%96%E8%B4%B8%E5%8C%BA/">对外贸易区</a>，贸易壁垒，美国分类和原产地原则，进口和过程控制，国际武器贸易条例（军事进出口），出口管理条例（民用和两用出口），美国评估和过程控制原则。
              可以根据客户的要求提供定制的培训和研讨会。 阅读所有最新的贸易咨询通知 <strong><a
                  href="/news?type=trade">这里。</a></strong>
            </p>
            <p style="text-align: center;">&nbsp;</p></div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">了解更多......</h3>
        <press-list type="case"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {tradeData} from "@/assets/mock/service";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "air",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      tradeData
    }
  }
}
</script>

<style scoped>

</style>