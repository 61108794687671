<template>
  <div class="section by">
    <div class="container container_size_sm spacing_xl">
      <div class="grid grid_md">
        <div class="grid__item size_8 spacing_xl"><h1 class="h1 text_align_center">英国退欧更新和最新的英国退欧新闻</h1>
          <div class="spacing_xl">
            <div v-for="(item,index) in exitData.list" :key="index" class="media align-items_center">
              <div class="media__body">
                <div><p>{{ item.time }}</p>
                  <h2 class="h3">
                    <a :href="'/newsDetail?type=exit&id='+item.id">{{ item.title }}</a>
                  </h2>
                </div>
                <p>{{ item.desc }}</p>
                <a
                    :href="'/newsDetail?type=exit&id='+item.id"
                    class="button button_outline_secondary">
                  <span>查看更多</span>
                  <svg class="icon" role="img">
                    <use xlink:href="#icon-arrow-right"></use>
                  </svg>
                </a>
              </div>
            </div>
          </div>

        </div>
        <div class="grid__item size_4">
          <div class="card card_size_sm bg_primary-dark">
            <div class="card__body c_white">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item c_white type"><h4 class="h5 c_white">英国和欧盟</h4>
                  <p class="text_size_sm">如果您需要物流和海关支持，请联系我们。</p>
                  <div><a class="button button_size_sm button_color_secondary"
                          href="https://craneww.com/request-a-quote/">我们可以帮忙！</a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="resources-list card card_size_sm bg_primary-white">
            <div class="card__body">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item type text_size_sm"><p><img alt=""
                                                                  src="https://www.craneww.com/site/assets/files/2815/brexit_image.320x0-is.jpg"
                                                                  width="320"></p>
                  <p class="gt-block"><strong>英国脱欧正在发生。</strong> 您知道我们有英国海关专家随时准备回答您的问题吗？
                  </p>
                  <p class="gt-block">英国脱欧过渡期于 31 年 2020 月 1 日结束，新的海关规则将于 2022 年 XNUMX 月 XNUMX
                    日实施。联系物流和海关支持&nbsp;<strong><a
                        href="/quotation">这里。</a></strong></p></div>
              </div>
            </div>
          </div>
          <div class="card card_size_sm bg_primary-dark">
            <div class="card__body c_white">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item c_white type"><h4 class="h5 c_white">在英国需要脱欧支持吗？</h4>
                  <p class="text_size_sm">英国海关服务，提供英国退欧建议</p>
                  <div><a class="button button_size_sm button_color_secondary"
                          href="https://craneww.com/knowledge-center/brexit-update/uk-customs-and-trade-advisory/">来帮忙！</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {exitData} from "@/assets/mock/news";

export default {
  data() {
    return {
      exitData
    }
  }
}
</script>

<style scoped>

</style>