<template>
  <div>
    <div class="section section_size_lg bg_primary-dark">
      <div class="section__container container">
        <div class="grid grid_md grid_flatten align-items_center">
          <div class="grid__item size_3">
            <a class="button button_color_secondary text_wrap" href="/news?type=case">
              <svg class="icon" role="img">
                <use xlink:href="#icon-chevron-left"></use>
              </svg>
              <span>所有案例研究</span>
            </a>
          </div>
          <div class="grid__item section__intro type type_inverted">
            <h1 class="c_white">{{ currentCase.title }}</h1>
            <p class="text_lead">{{currentCase.bannerDesc}}</p>
          </div>
          <div class="grid__item size_3"></div>
        </div>
      </div>
      <img :src="currentCase.bannerImage" alt="" class="section__background" height="800" width="1200">
      <div class="section__screen bg_primary-dark"></div>
    </div>


    <div class="section bg_shade">
      <div class="container">
        <div class="grid grid_size_md grid_md align-items_center">
          <div class="grid__item type" v-html="currentCase.descHtml"></div>
        </div>
      </div>
    </div>


    <div class="section">
      <div class="container">
        <div class="grid grid_md">
          <div class="grid__item size_6">
            <div class="spacing">
              <img :src="currentCase.image1" alt="" class="radius" height="500" width="800">
              <div class="type" v-html="currentCase.itemHtml1"></div>
            </div>
          </div>
          <div class="grid__item size_6">
            <div class="spacing">
              <img :src="currentCase.image2" alt="" class="radius" height="500" width="800">
              <div class="type" v-html="currentCase.itemHtml2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentCase.itemHtml4" v-html="currentCase.itemHtml4"></div>

    <div class="section">
      <div class="container">
        <div class="grid grid_size_md grid_md align-items_center">
          <div class="grid__item">
            <img :src="currentCase.image3" alt="" class="radius" height="500" width="800">
          </div>
          <div class="grid__item type" v-html="currentCase.itemHtml3">

          </div>
        </div>
      </div>
    </div>

    <quotation></quotation>
  </div>
</template>

<script>
import {caseData} from "@/assets/mock/news";
import Quotation from "@/components/quotation.vue";

export default {
  name: "caseDetail",
  components: {Quotation},
  data() {
    return {
      caseData,
      currentCase: null,
    }
  },
  mounted() {
    this.currentCase = caseData.list.find(item => item.id === this.$route.query.id)
  }
}
</script>

<style lang="scss" scoped>
</style>