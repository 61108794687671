<template>
  <div>
    <common-banner :bannerData="cruiseData.bannerData"></common-banner>

    <service-intro :infoData="cruiseData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>邮轮，海上和酒店业供应链解决方案。</h1>
            <p>我们专业的物流团队充分了解邮轮，海运和酒店业充满挑战的供应链环境。
              我们的团队将协调必要的物资运送到下一个停靠港或为维修损坏的发动机而运送关键零件，因此我们将以紧迫感做出响应，使我们的客户能够为他们的消费者提供终极的游轮，航海或款待体验。</p>
            <h2>邮轮物流</h2>
            <p>在 Crane Worldwide
              Logistics，我们提供定制的游轮供应链解决方案，满足您游轮船队的需求。我们的邮轮物流专家团队将为供应链的所有领域（从供应商和供应商到特许经营商）提供协助和服务。通过提供一流的咨询、运输、仓储和技术，我们将确保增加从源头到发货的价值和可见性。我们在世界各地的专业物流专业人员将努力以及时且经济高效的方式为港口（包括干船坞和新船）的游轮提供服务，以保持游轮航线按计划运行。&nbsp;</p>
            <hr>
            <h2>海运物流</h2>
            <p>Crane Worldwide Logistics 开发了一种独特的结构，使整个海运业受益。
              我们经过验证的全球基础设施提供了广泛的解决方案，可满足造船厂、船舶管理公司、船东等的任何运输需求。
              海运物流团队通过结合国际货运、租船、专业处理和仓储解决方案，在正确的地点和时间交付海运备件，始终满足时间紧迫的最后期限。&nbsp;</p>
            <h2>酒店物流</h2>
            <p>Crane Worldwide Logistics团队热衷于提供自适应的供应链解决方案；
              我们与许多酒店行业领先的酒店，饭店和度假品牌合作，通过独特的物流运营支持来监督翻新，安装，清算等工作。
              我们的酒店专家将了解您的业务需求，以提供高度协调的运输，仓储和项目管理解决方案。
              通过使用我们的全球办公室网络和创新技术，我们可以执行多位置刷新，其精度与简单的单属性安装相同。</p></div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">不断更新</h3>
          <press-list></press-list>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {cruiseData} from "@/assets/mock/industry";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      cruiseData
    }
  }
}
</script>

<style scoped>

</style>