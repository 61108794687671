<template>
  <div>
    <template v-if="$route.query.type === 'index'">
      <div class="section section_size_xl bg_primary-dark">
        <div class="section__container container">
          <div class="section__intro type type_inverted"><h1 class="c_white">{{
              industryIndexData.bannerData.title
            }}</h1>
            <p class="text_lead">{{ industryIndexData.bannerData.desc }}</p></div>
        </div>
        <img :src="industryIndexData.bannerData.image" alt=""
             class="section__background" height="800" width="1200">
        <div class="section__screen bg_primary-dark"></div>
      </div>
      <div class="section section_size_lg">
        <div class="container">
          <div class="grid grid_size_lg grid_center">
            <div v-for="(item,index) in industryIndexData.industryList" :key="index"
                 class="grid__item size_6 size_4_sm size_3_md">
              <a
                  :href="item.path"
                  class="button_wrap d_block spacing text_align_center">
                <h3 class="button button_theme_brackets button_size_lg mx_auto">{{ item.name }}</h3>
                <p>{{ item.desc }}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>

    <aerospace v-if="$route.query.type === 'aerospace'"></aerospace>
    <car v-if="$route.query.type === 'car'"></car>
    <goods v-if="$route.query.type === 'goods'"></goods>
    <cruise v-if="$route.query.type === 'cruise'"></cruise>
    <energy v-if="$route.query.type === 'energy'"></energy>
    <government v-if="$route.query.type === 'government'"></government>
    <tech v-if="$route.query.type === 'tech'"></tech>
    <industrial v-if="$route.query.type === 'industrial'"></industrial>
    <life v-if="$route.query.type === 'life'"></life>
    <mining v-if="$route.query.type === 'mining'"></mining>


    <quotation></quotation>
  </div>
</template>

<script>
import {industryIndexData} from "@/assets/mock/industry";
import Quotation from "@/components/quotation.vue";
import Aerospace from "@/views/industry/components/aerospace.vue";
import Car from "@/views/industry/components/car.vue";
import Goods from "@/views/industry/components/goods.vue";
import Cruise from "@/views/industry/components/cruise.vue";
import Energy from "@/views/industry/components/energy.vue";
import Government from "@/views/industry/components/government.vue";
import Tech from "@/views/industry/components/tech.vue";
import Industrial from "@/views/industry/components/industrial.vue";
import Life from "@/views/industry/components/life.vue";
import Mining from "@/views/industry/components/mining.vue";

export default {
  name: "index",
  components: {Mining, Life, Industrial, Tech, Government, Quotation, Aerospace, Car, Goods, Cruise, Energy},
  data() {
    return {
      industryIndexData,
    }
  }
}
</script>

<style scoped>

</style>