<template>
  <div>
    <technology v-if="$route.query.type === 'index'"></technology>
    <shipment v-if="$route.query.type === 'shipment'"></shipment>
    <purchase v-if="$route.query.type === 'purchase'"></purchase>
    <wms v-if="$route.query.type === 'wms'"></wms>
    <standard v-if="$route.query.type === 'standard'"></standard>

    <quotation></quotation>
  </div>
</template>

<script>
import Quotation from "@/components/quotation.vue";
import Technology from "@/views/technology/components/technology.vue";
import Shipment from "@/views/technology/components/shipment.vue";
import Purchase from "@/views/technology/components/purchase.vue";
import Wms from "@/views/technology/components/wms.vue";
import Standard from "@/views/technology/components/standard.vue";

export default {
  name: "index",
  components: {Standard, Wms, Purchase, Shipment, Technology, Quotation},
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>