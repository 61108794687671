<template>
  <div>
    <common-banner :bannerData="airData.bannerData"></common-banner>

    <service-intro :infoData="airData.info"></service-intro>
    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>国际货运代理-空运服务</h1>
            <p><img alt="空运重型起重货物" class="align_right hidpi"
                    src="https://www.craneww.com/site/assets/files/1044/charter_ams.320x0-is-hidpi.jpg" width="320"></p>
            <p>您的货物是否需要； 无论是优先级、速度还是特殊处理，我们都有适合您要求的运输选项。
              我们的专家将与您和您的物流团队合作，了解您的空运挑战并提供具有成本效益的解决方案。</p>
            <p class="gt-block">我们将评估您的全球空运运输时间要求，并为您的空运运输需求提供最佳解决方案。
              我们可以提供快递、标准和延期空运服务。 从机场到机场或门到门， <a
                  href="/quotation">联系报价</a>.&nbsp;</p>
            <p>在 Crane Worldwide Logistics，我们获得了 TSA 间接航空承运人 (IAC)
              的批准，这使我们能够提供一整套合规的航空货运和航空货运代理解决方案，并采用创新技术来支持您的航空货运跟踪。
              我们提供完整的空运服务：</p>
            <ul>
              <li><strong>包机航班： </strong>无论您的空运货物的大小或重量如何，我们都可以将您的货物无忧地运送到目的地。
                Crane Worldwide 从中国到美国的包机服务定期且可靠。 联系我们以获取更多信息。
              </li>
              <li><strong>起重机紧急空气：&nbsp;</strong>我们时间紧迫的空运团队将确保您的货物以紧迫感安全到达您想要的地点。
                当需要紧急情况时，我们的时间紧迫的物流团队可以为您提供支持。
              </li>
              <li><strong>手提物流</strong>: 我们可以为紧急的时间敏感的空运货物安排国内和国际手提货运服务。</li>
              <li><strong>温度控制</strong>：对于需要通过进行温度控制的航空货运集装箱、货舱放置和温度监控来控制气候的产品，我们可以确保航空货运过程中的温度稳定性。
              </li>
            </ul>
            <p class="gt-block">我们独特的 <strong><a
                href="/technology?type=shipment">技术平台C-View</a></strong>,
              允许您通过简化的仪表板查看任何空运货物，以使用简化的仪表板在全球范围内跟踪和追溯您的货物。
              空运和货物跟踪以及使用实时数据管理您的物流和空运业务将为评估现在和未来的供应链漏洞提供洞察力。 <strong>在您的航空货物跟踪方面需要帮助？
                获取演示！</strong></p>
            <h3>重型和特大型货物</h3>
            <p>在 Crane Worldwide Logistics，我们的强项是熟练地运输重型和超大货物。
              我们致力于卓越和创新，成功交付了多种资产，例如石油工业设备、发电厂组件、卫星通信设备和各种车辆。
              我们团队、客户和社区的信任和承诺对我们至关重要，我们努力超越期望，同时优先考虑可持续和个性化的物流服务。
              选择克瑞全球物流作为致力于分享您成功之旅的合作伙伴。</p>
            <h3>危险品运输</h3>
            <p>将您的危险品运输委托给 Crane Worldwide Logistics 可确保安全、无缝且符合法规的流程。
              我们的专家团队拥有丰富的知识，可以处理从许可证到地面飞行代表的一切事务。 我们包租专为各种危险材料设计的货机。
              选择克瑞全球物流，获得超越物流的专业知识和承诺，为全球社区塑造更光明的未来。</p>
            <h3>人道主义和救济支持</h3>
            <p>克瑞全球物流是在紧急情况下采取迅速、果断行动的代名词，确保及时向有需要的人提供援助。
              我们经验丰富的物流专家与主要援助机构和政府合作，包括美国、英国、加拿大、德国和西班牙。
              通过选择我们，您将与一个具有全球视野、专注于卓越、创新和服务全球集体的组织合作。</p>
            <h2><strong>时间紧迫的空运：Crane Urgent Air</strong></h2>
            <p>Crane Urgent Air 是一项独特的服务，提供时间敏感的下一班航班运输解决方案，并提供 24/7 全天候客户支持和服务。
              我们专门的客户支持团队监控全球航班时刻表，因此我们可以快速回答您的要求并帮助您预订下一架可用飞机的运输。
              我们在机场提供直接协助来处理您的空运货物——在最重要的时候为您节省时间和金钱。</p>
            <h3>在您最需要的时候准时交货</h3>
            <p class="gt-block">运输紧急空运是一种快速有效的运输必须按时交付的物品的方法。 您的货物将通过这种方式运送，确保它们尽快到达目的地。
              <strong>我们的产品非常适合必须快速将产品交付给客户或需要运输紧急物品的公司</strong>.</p>
            <p class="gt-block">为您的时效性货运选择我们的优先空运服务，以避免延误和错过最后期限。
              <strong>我们时间紧迫的空运服务</strong> 由于其快速和可靠，是最后一刻交货的最佳选择。
              您也可以放心，您的物品将得到妥善处理并安全运送到目的地。</p>
            <h3>增强空运货物跟踪可见性</h3>
            <p>我们的紧急空运是可定制的。
              我们的空运专家可以审查您的运营并制定解决方案，包括取件和送货时间以满足您的需求并确保您的货物到达目的地。</p>
            <ul>
              <li><strong>时间敏感的空运货物</strong>
                <ul>
                  <li>下一班航班 (NFO)</li>
                  <li>手提</li>
                  <li>完整章程 - 部分章程</li>
                  <li>保证航空服务</li>
                </ul>
              </li>
              <li><strong>加急陆运</strong>
                <ul>
                  <li>下一个开车出去</li>
                  <li>热门人物</li>
                  <li>最后一英里</li>
                  <li>车队车手</li>
                </ul>
              </li>
            </ul>
            <p><a href="/site/assets/files/1044/crane_urgent_air_service.png">
              <img alt="起重机全球物流时间关键服务"
                   class="align_center hidpi"
                   src="https://www.craneww.com/site/assets/files/1044/crane_worldwide_logistics_time_critical_service.png"
                   width="1600"></a></p>
            <h3>满足您所有航空货运需求的合作伙伴。</h3>
            <p class="gt-block">我们的目标是了解您现在和未来的航空货运代理和货运挑战。
              了解、规划和预测您未来的货运和物流需求将使团队能够提供无缝的门到门运输，以满足您的国际和国内货运需求。
              我们的技术为我们的客户提供了空运货物的可见性，并增强了客运和货运航空公司的空运跟踪。 我们的 包机业务
              可以在您最需要的时候提供空运运力。 我们还提供 <a
                  href="/service?type=broker">报关服务</a>. <strong>立即联系我们的客户服务团队获取空运报价！</strong>
            </p>
            <blockquote>
              <center><p class="gt-block"><strong>最新的空运信息，查看我们的空运&nbsp;</strong><a
                  href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E5%B8%82%E5%9C%BA%E6%9B%B4%E6%96%B0/">市场动态</a><strong>.</strong>
              </p></center>
            </blockquote>
            <div class="card card_size_lg bg_primary-dark">
              <div class="card__body c_white">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item spacing"><h2 class="h3 c_white">接收最新空运资讯</h2>
                    <p class="text_size_lg">所有最新最快的市场信息可以直接发送到您的收件箱</p></div>
                  <div class="grid__item size_auto spacing text_align_center">
                    <div><a class="button button_size_lg button_color_secondary"
                            href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/">成为会员</a>&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">阅读我们的最新文章...</h3>
        <press-list type="industry"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {airData} from "@/assets/mock/service";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "air",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      airData
    }
  }
}
</script>

<style scoped>

</style>