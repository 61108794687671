<template>
  <div>
    <common-banner :banner-data="{title:'隐私政策'}"></common-banner>
    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h3>Crane Worldwide Logistics 网站隐私政策</h3>
          <p>生效日期：1 年 2020 月 XNUMX 日</p>
          <p class="gt-block"><strong>目的</strong></p>
          <p>Crane Worldwide Logistics 及其附属公司和子公司（统称为“CWW”）尊重您的隐私。 本 CWW 网站隐私政策（“政策”）旨在概述如何通过使用我们位于
            http://www.craneww.com（“网站”）的网站来收集和处理个人信息。&nbsp;</p>
          <p>本政策仅适用于本网站。 如果您链接到其他第三方网站、平台和应用程序（“第三方网站”），这些第三方网站可能有自己的隐私政策和条款和条件，CWW
            鼓励所有各方在与任何第三方合作之前进行审查派对网站。</p>
          <p class="gt-block"><strong>CWW 收集的个人数据</strong></p>
          <p>“个人数据”（也称为“个人信息”）是指与已识别或可识别的自然人（“数据主体”）有关的任何信息。
            可识别的自然人是可以直接或间接识别的自然人，特别是通过参考诸如姓名、识别号、位置数据、在线标识符或特定于身体、生理、该自然人的遗传、精神、经济、文化或社会身份。</p>
          <p>CWW 只会在公平合法的情况下处理您的个人数据。 任何此类使用均应遵守本政策。</p>
          <p>个人数据的一些示例包括姓名、地址、屏幕名称、个人资料图片、电子邮件地址、电话号码、IP
            地址、生日以及生活方式选择或偏好，例如爱好和兴趣。</p>
          <p class="gt-block">CWW 可能会从不同来源接收个人数据，下面将对此进行详细讨论。<br>直接提供给 CWW 的信息<br>&nbsp;&nbsp;
            &nbsp;<br>CWW 收集访问者自愿提交到我们网站的个人信息。 收集的信息类型包括：</p>
          <ul>
            <li>传记信息，如姓名、年龄、性别和出生日期；</li>
            <li>联系信息，例如用户名、电子邮件地址、邮寄地址和电话号码；</li>
            <li>支付信息;</li>
            <li>专业信息，例如商业技能、职位和/或在企业中的地位；</li>
            <li>用户生成的内容，例如提交给网站的帖子和其他内容； 和</li>
            <li>自愿提供给 CWW 的任何其他个人信息。</li>
          </ul>
          <p>使用CWW网站时自动收集的信息</p>
          <p>CWW（和代表 CWW 的第三方服务提供商）使用 cookie 和其他工具（例如网络分析工具和像素标签）自动收集有关网站使用情况的信息，但须遵守本政策的条款和适用数据保护法律法规。
            自动收集的信息类型可能包括：</p>
          <ul>
            <li>浏览器使用情况；</li>
            <li>访问过的网页；</li>
            <li>IP地址;</li>
            <li>使用的超链接；</li>
            <li>用户名;</li>
            <li>个人资料图片；</li>
            <li>性别;</li>
            <li>使用第三方网站时共享的网络和任何其他信息（例如 Facebook 上的“喜欢”功能或 Google+ 上的“+1”功能）； 和</li>
            <li>在使用 CWW 网站之前访问过的网站。</li>
          </ul>
          <p>从其他来源收集的信息</p>
          <p>CWW 可能会从合法的商业来源接收个人信息，例如公共数据库、数据聚合器和各种信誉良好的第三方。
            可能从此类来源收集的个人信息类型包括：</p>
          <ul>
            <li>传记信息，如姓名和年龄；</li>
            <li>联系信息，例如街道地址；</li>
            <li>有关生活方式选择的偏好和信息，例如爱好和兴趣；</li>
            <li>法律允许的公开信息，例如用户生成的内容、博客和帖子； 和</li>
            <li>购买信息。</li>
          </ul>
          <p>根据位置，可能有解决方案来帮助控制在线行为广告偏好（例如某些第三方是否可能出于有针对性的广告目的收集和使用网站使用信息）。
            例如，在欧洲，网站 www.youronlinechoices.com 允许选择哪些公司可以提供定制广告，而 Network Advertising
            Initiative 的 Opt-Out Tool 和 Digital Advertising Alliance 的 Opt-Out Tool 在美国提供相同的服务</p>
          <p class="gt-block"><strong>加工基础</strong></p>
          <p>在本政策的范围内，我们可能会依据以下一项或多项法律依据来处理您的个人数据：</p>
          <ul>
            <li>需要履行我们在合同项下的义务，或履行相关的合同前义务；</li>
            <li>需要追求我们的合法利益，例如我们在营销和宣传我们的产品和服务方面的合法利益；</li>
            <li>您的同意； 和</li>
            <li>法律要求或允许的任何其他理由。</li>
          </ul>
          <p class="gt-block"><strong>控制权</strong></p>
          <p>在本政策的上下文中，CWW 充当我们处理的个人数据的数据控制者。</p>
          <p class="gt-block"><strong>CWW 如何使用个人信息&nbsp;</strong></p>
          <p>CWW 可能会使用和共享本政策中规定的、您首次提交信息时披露的或适用法律允许的个人信息。 CWW
            可能会将个人信息用于：</p>
          <ul>
            <li>改进 CWW 产品和您在 CWW 网站上的体验；</li>
            <li>就可能感兴趣的产品和服务联系潜在客户；</li>
            <li>提供 CWW 要求的产品或服务；</li>
            <li>促进合同谈判；</li>
            <li>交流业务或工作机会； 和</li>
            <li>确定合同责任。</li>
          </ul>
          <p>手机短信服务</p>
          <p>CWW 可能会提供一项服务，通过该服务可以在无线或移动设备上接收文本或其他类型的消息，例如
            SMS（短消息服务）、MMS（多媒体消息服务）或 EMS（增强型消息服务）（“移动消息服务”）服务”）。 订阅 CWW
            移动消息服务后，用户同意通过为此目的提供的地址或手机号码接收来自 CWW 的此类消息。 应该注意的是，无线运营商标准费率适用于这些消息。
            如果用户选择不接收此类消息，则这不适用。</p>
          <p>任何阅读本政策的人均表示他或她是用于注册移动消息服务的无线设备的所有者或授权用户，并且他或她被授权批准适用的费用。
            除了满足某些年龄限制以及与每个移动消息服务相关的任何其他条款和条件外，用户可能还需要注册个人信息，例如您的姓名、短信、无线地址或移动电话号码。
            CWW还可以在使用移动消息服务的过程中获取消息的日期、时间和内容。 CWW 将使用根据本政策获得的任何信息。
            请注意，无线运营商和其他服务提供商也可能会收集有关无线设备使用情况的数据，并且他们的做法受他们自己的政策约束。</p>
          <p>通过阅读本政策，即承认并同意移动消息服务是通过使用无线电（和其他方式）在复杂网络上传输通信的无线系统提供的。
            CWW 不保证移动消息服务的使用将是私密的或安全的，并且 CWW 对所经历的任何隐私或安全性缺乏不承担任何责任。
            用户有责任采取预防措施并提供最适合其情况和移动消息服务预期用途的安全措施。 CWW
            还可以访问运营商的无线和/或移动电话帐户的内容，以识别和解决技术问题和/或与服务相关的投诉。</p>
          <p>CWW 与谁共享您的个人信息&nbsp;</p>
          <p>作为一个全球商业实体，CWW 可能会在位于世界不同国家的其他 CWW
            办事处、附属机构和受信任的第三方之间共享个人信息。</p>
          <p>此类第三方包括：</p>
          <ul>
            <li>IT系统和基础设施服务提供商；</li>
            <li>CWW 销售代表和电话接线员；</li>
            <li>网站服务提供商； 和&nbsp;</li>
            <li>互联网广告服务提供商。</li>
          </ul>
          <p>我们的服务提供商可能位于美国境外；
            但是，我们将获得您的明确同意将您的个人数据传输给此类第三方，或者我们将要求这些第三方至少保持与我们为此类个人数据维护的机密级别相同的机密性，并确保实施适当的保护措施确保保护您的个人数据。&nbsp;</p>
          <p class="gt-block"><strong>您个人信息的其他披露</strong></p>
          <p>我们可能会在 (i) 法律要求的范围内披露您的个人数据，或者如果我们真诚地相信此类披露是必要的，以遵守政府和/或执法官员发起的官方调查或法律程序，或私人方，包括但不限于：响应传票、搜查令或法院命令，或
            (ii) 如果我们出售或转让我们公司的全部或部分商业利益、资产或两者，或与公司合并、合并、重组或其他公司变更，或 (iii)
            仅在上述部分所述的业务和运营目的有必要时才向我们的子公司或附属公司转让。</p>
          <p>
            我们保留出于任何合法商业目的使用、转让、出售和共享不包括任何个人数据的汇总匿名数据的权利，这些数据将我们的网站用户作为一个群体用于任何合法商业目的，例如分析使用趋势和寻找兼容的广告商、赞助商、客户和客户。</p>
          <p>如果我们必须披露您的个人数据以遵守政府和/或执法官员发起的官方调查或法律程序，我们可能无法确保您的个人数据的此类接收者将维护您个人的隐私或安全数据。&nbsp;</p>
          <p class="gt-block"><strong>保护个人信息&nbsp;</strong></p>
          <p>CWW 采取一切合理的预防措施来确保个人信息的安全。&nbsp;</p>
          <p>CWW
            已实施并将保持技术、管理和物理措施的合理设计，以帮助保护个人数据免遭未经授权的处理，例如未经授权的访问、披露、更改或破坏。</p>
          <p>CWW 要求代表 CWW 处理或处理个人信息的任何第三方遵守 CWW 在保护个人信息方面所坚持的相同标准。 仅允许 CWW
            员工和代理在需要了解的情况下访问信息。</p>
          <p class="gt-block"><strong>个人信息的保留和销毁&nbsp;</strong></p>
          <p>CWW 将根据适用法律以及 CWW 记录保留政策中的适用保留期限保留个人信息。
            除非适用法律要求在适用的保留期限届满后销毁个人信息，否则将应要求销毁个人信息。 考虑到 CWW
            的敏感性、价值和关键程度，销毁方式应适合于保护和确保个人信息的机密性。</p>
          <p class="gt-block"><strong>儿童</strong></p>
          <p>本网站面向成年人，因此很少被任何被视为合法未成年人的人使用。 CWW在收集任何未成年人的个人信息之前，会征得其父母或监护人的同意。
            如果CWW未经父母或监护人同意而收集未成年人的个人信息（本应获得此类同意），CWW将尽快删除该个人信息。 CWW
            收集、使用或披露其个人信息的任何未成年人应查看本政策的条款，应与该未成年人的父母或监护人一起查看。</p>
          <p class="gt-block"><strong>欧洲联盟 (EU) 代表&nbsp;</strong></p>
          <p>根据欧盟通用数据保护条例 (GDPR) 第 27 条，Harry Gerritsen 已被任命为 CWW 在欧盟的数据保护事务代表。 除了 CWW
            之外，我们只能就与个人数据处理相关的事宜联系 Harry Gerritsen。 要进行此类查询，请通过
            Harry.Gerritsen@craneww.com 联系 Harry Gerritsen&nbsp;</p>
          <p class="gt-block"><strong>加州居民</strong></p>
          <p>以下信息适用于属于以下任何类别的加利福尼亚州居民：</p>
          <ul>
            <li>访问我们位于 www.craneww.com 的公共网站（“网站”）的人；</li>
            <li>参加我们网络研讨会的人；&nbsp;</li>
            <li>通过社交媒体平台访问、关注或与我们互动的人，包括 Facebook、Twitter 和 LinkedIn（“社交媒体帐户”）；</li>
            <li>我们通过我们的销售和营销系统收集其数据的潜在 Crane 客户，包括但不限于电子邮件、我们的客户资源管理 (CRM)
              系统、直接营销系统和电话会议系统（统称为网站和社交媒体，“销售和营销系统”）； 和
            </li>
            <li>与我们向客户提供的运输服务相关的人员（例如，我们客户发送的货物的收件人）。</li>
          </ul>
          <p class="gt-block"><strong>我们收集的个人信息</strong></p>
          <p>我们可能会从您那里收集以下类别的个人信息：</p>
          <ul>
            <li>标识符，例如姓名、邮政地址、电子邮件地址、电话号码、传真号码、用户名、唯一个人标识符、在线标识符、帐户名称、社交媒体资料信息、出生日期、税号、IP
              地址或其他类似标识符;
            </li>
            <li>商业信息，例如支付信息、支付卡信息、银行信息或购买信息；</li>
            <li>互联网或其他电子网络活动信息，例如我们在您与我们的网站互动时收到的数据或 Crane
              网站或门户的密码，以及您的社交媒体资料或您在我们的社交媒体帐户上留下的评论或互动中包含的信息；
            </li>
            <li>专业或与就业相关的信息，例如您的雇主、职位、任期、商业技能或其他可能在在线个人资料中提供的信息； 和</li>
            <li>教育信息，例如您就读的大学和学位。</li>
          </ul>
          <p class="gt-block"><strong>我们收集个人信息的来源</strong></p>
          <p>我们从各种来源收集个人信息，包括：</p>
          <ul>
            <li>直接来自您（例如，如果您与我们的销售和营销系统互动、使用我们的服务或访问我们的网站）；</li>
            <li>来自我们的客户（包括他们的员工、承包商和公司的其他代表）；</li>
            <li>来自我们集团内的其他公司；</li>
            <li>来自我们的服务提供商；&nbsp;</li>
            <li>来自社交媒体平台（例如，LinkedIn）；</li>
            <li>来自向我们提供可能有兴趣成为我们客户的个人名单的第三方； 和</li>
            <li>来自通过向我们提供您的信息而将您推荐给我们的服务的第三方。</li>
          </ul>
          <p class="gt-block"><strong>我们如何使用个人信息</strong></p>
          <p>我们可能将您的个人信息用于以下目的：</p>
          <ul>
            <li>营销或销售我们的服务，包括识别潜在客户以及跟踪和分析销售和营销活动；</li>
            <li>提供或启用服务的使用；</li>
            <li>运营我们的网站；</li>
            <li>回应您的要求或问题； 和</li>
            <li>向您发送电子邮件营销通讯和我们认为您可能感兴趣的有关我们业务的其他信息；</li>
            <li>符合法律要求。</li>
          </ul>
          <p class="gt-block"><strong>您在加州的权利</strong></p>
          <p>加利福尼亚州法律授予该州居民在收集、使用和共享其个人信息方面的某些权利。
            在某些限制下，加州居民享有以下权利：</p>
          <ul>
            <li><strong>知情权</strong>. 您有权了解和索取有关我们在过去 12
              个月内收集的有关您的个人信息的类别和特定片段的信息，以及收集此类信息的来源类别、收集此类信息的目的、以及我们与之共享此类信息的第三方类别。
              您也有权知道我们是否出于商业目的出售或披露了您的个人信息。
            </li>
            <li><strong>删除权</strong>. 您有权要求删除我们从您那里收集的个人信息，但某些例外情况除外。</li>
            <li><strong>选择退出的权利</strong>. 出于有价值的考虑，您有权选择不披露您的某些个人信息。 您可以通过“请勿出售我的个人信息”链接
              [插入链接] 行使此权利。&nbsp;
            </li>
            <li><strong>不受歧视的权利</strong>. 您有权因行使上述任何权利而不受歧视。
              但是，如果差异与您的个人信息的价值合理相关，我们可能会提供不同级别的服务或收取不同的费用。&nbsp;
            </li>
          </ul>
          <p class="gt-block">如果您是加利福尼亚州居民并希望行使上述任何权利，请提交您的请求至 <a
              href="mailto:DataPrivacy@craneww.com" title="邮件至 DataPrivacy@craneww.com">数据隐私@craneww.com</a> 或致电
            +1 888-870-2726.&nbsp;</p>
          <p>披露或删除您的信息的请求必须提供足够的信息，使我们能够合理地验证您是我们收集个人信息的对象，加利福尼亚州居民，并且必须以足够的细节描述您的请求，使我们能够正确理解、评估，并作出回应。
            我们只会使用可验证的消费者请求中提供的个人信息来验证请求者的身份或提出请求的权限。&nbsp;</p>
          <p>在某些情况下，我们可能无法满足您的要求。 例如，如果您要求删除，我们可能需要保留某些个人信息以遵守我们的法律义务或其他允许的目的。
            在这种情况下，我们会告知您我们无法满足您的要求的原因。</p>
          <p>如果您通过授权代理人提交请求，我们可能会要求您 (1) 向授权代理人提供书面许可，以及 (2) 直接验证您自己的身份和居住地。
            我们可能会拒绝代理人的请求，如果代理人未提交证明他们已获得消费者授权代表他们行事的证据。</p>
          <p class="gt-block"><strong>更改本隐私政策</strong></p>
          <p>如果我们对此政策进行任何重大更改，我们会将修订后的政策发布到此网页并更新上面的“生效日期”以反映新政策生效的日期。&nbsp;</p>
          <p>我们鼓励您在访问本网站时查看本政策，以了解您的个人信息将被如何使用。</p>
          <p class="gt-block"><strong>您的权利和联系方式</strong></p>
          <p>如果您是我们存储个人数据的数据主体，您可能有权：</p>
          <p class="gt-block">• 限制所有未来的接触；<br>• 获得一份由 CWW 维护的您的个人信息副本；&nbsp;<br>• 更正、更新或删除您在
            CWW 记录中的个人信息； 和<br>• 选择不使用或收集个人信息<br>发送电子邮件至 gdprteam@craneww.com。<br>此外，您可能有权向一个或多个欧盟成员国的数据保护监管机构提出投诉。<br>如果您对本政策或上述任何内容有任何问题、意见或疑虑，请联系：
          </p>
          <p class="gt-block">特蕾西·L·艾布拉姆·安东尼<br>副总裁兼首席合规官<br>兰金路1500号<br>美国德克萨斯州休斯顿
            77073<br>gdprteam@craneww.com</p>
          <p>请给我们最多四个星期的时间来回复。</p></div>
      </div>
    </div>

    <quotation></quotation>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import Quotation from "@/components/quotation.vue";

export default {
  name: "policy",
  components: {Quotation, CommonBanner}
}
</script>

<style scoped>

</style>