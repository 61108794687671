<template>
  <div>
    <div class="section by">
      <div class="container container_size_sm spacing_xl">
        <div class="grid grid_md">
          <div class="grid__item size_8 spacing_xl"><h1 class="h1 text_align_center">Incoterms® - 运输条款指南</h1>
            <div class="spacing_xl">
              <div v-for="(item,index) in guideData.list" :key="index" class="media align-items_center">
                <div class="media__body">
                  <div>
                    <p></p>
                    <h2 class="h3">
                      <a :href="'/guideDetail?id='+item.id">{{ item.title }}</a>
                    </h2>
                  </div>
                  <p>{{ item.desc }}</p>
                  <a :href="'/guideDetail?id='+item.id" class="button button_outline_secondary">
                    <span>查看更多</span>
                    <svg class="icon" role="img">
                      <use xlink:href="#icon-arrow-right"></use>
                    </svg>
                  </a></div>
              </div>

            </div>
          </div>
          <div class="grid__item size_4">
            <div class="card card_size_sm bg_primary-dark">
              <div class="card__body c_white">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item c_white type"><h4 class="h5 c_white">立即下载 PDF 格式的免费 Incoterms®
                    图表！</h4>
                    <p class="text_size_sm">了解您的运输条款，Incoterms 图表提供了所有 11 个 Incoterms 的指南。</p>
                    <div><a class="button button_size_sm button_color_secondary"
                            href="https://cranewwmktg.powerappsportals.com/cwwincotermsmatrix/">现在下载</a></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="resources-list card card_size_sm bg_primary-white">
              <div class="card__body">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item type text_size_sm"><h1>国际贸易术语<strong>®</strong></h1>
                    <p class="gt-block"><strong>国际商业条款</strong></p>
                    <p class="gt-block"><strong><a href="https://craneww.com/knowledge-center/incoterms/exw-exworks/">EXW</a>&nbsp;</strong>（出厂）&nbsp;
                    </p>
                    <p class="gt-block"><strong><a
                        href="https://craneww.com/knowledge-center/incoterms/fca-free-carrier/">FCA</a></strong> (免费承运人)&nbsp;
                    </p>
                    <p class="gt-block"><strong><a
                        href="/news?type=guide">CPT</a></strong>
                      （运费付至）&nbsp;</p>
                    <p class="gt-block"><strong><a
                        href="/news?type=guide">CIP</a></strong>
                      （运费和保险付给）&nbsp;</p>
                    <p class="gt-block"><strong><a
                        href="/news?type=guide">DAP</a></strong>
                      （当场交货）&nbsp;</p>
                    <p class="gt-block"><strong><a
                        href="/news?type=guide">DPU</a></strong>
                      （在卸货地点交付）&nbsp;</p>
                    <p class="gt-block"><strong><a
                        href="/news?type=guide">DDP</a></strong>
                      (完税交货)</p>
                    <p class="gt-block"><strong><a
                        href="/news?type=guide">FAS</a></strong>
                      （船边免费）</p>
                    <p class="gt-block"><strong><a
                        href="/news?type=guide">FOB</a></strong>
                      （免费董事会）</p>
                    <p class="gt-block"><strong><a
                        href="/news?type=guide">CFR</a></strong>
                      （成本加运费）</p>
                    <p class="gt-block"><strong><a
                        href="/news?type=guide">CIF</a></strong>
                      （成本，保险费加运费）</p>
                    <p>了解买卖双方的规则！</p></div>
                </div>
              </div>
            </div>
            <!--            <div class="card card_size_sm bg_primary-dark">-->
            <!--              <div class="card__body c_white">-->
            <!--                <div class="grid grid_md m_0 align-items_center">-->
            <!--                  <div class="grid__item c_white type"><h4 class="h5 c_white">完整的 Incoterms 2020 概述</h4>-->
            <!--                    <p class="text_size_sm">阅读有关 Incoterms 最新更新的所有信息。</p>-->
            <!--                    <div><a class="button button_size_sm button_color_secondary"-->
            <!--                            href="https://craneww.com/knowledge-center/latest-news-and-info/incoterms-2020/">了解更多</a></div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="resources-list card card_size_sm bg_primary-white">-->
            <!--              <div class="card__body">-->
            <!--                <div class="grid grid_md m_0 align-items_center">-->
            <!--                  <div class="grid__item type text_size_sm"><h2>其他资源：</h2>-->
            <!--                    <ul>-->
            <!--                      <li>提供海运集装箱指南<strong> <a-->
            <!--                          href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E9%9B%86%E8%A3%85%E7%AE%B1%E5%B0%BA%E5%AF%B8/">这里。</a></strong>-->
            <!--                      </li>-->
            <!--                      <li>提供单位负载尺寸概览 <strong><a-->
            <!--                          href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E5%8D%95%E4%BD%8D%E8%B4%9F%E8%BD%BD%E8%AE%BE%E5%A4%87-ul/">这里。</a></strong>-->
            <!--                      </li>-->
            <!--                    </ul>-->
            <!--                    <h3>寻求支持 <strong><a href="/%E5%92%A8%E8%AF%A2%E6%8A%A5%E4%BB%B7/">这里。</a></strong></h3></div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {guideData} from "@/assets/mock/news";

export default {
  data() {
    return {
      guideData
    }
  }
}
</script>

<style scoped>

</style>