<template>
  <div>

    <div class="section by">
      <div class="container spacing_xl"><h1 class="h1 text_align_center">最新的全球物流运营和承运人</h1>
        <div class="grid grid_md">
          <div class="grid__item">
            <div class="spacing">
              <a :href="'/newsDetail?type=market&id='+marketData.list[0].id">
                <img
                    :src="marketData.list[0].image"
                    alt="阅读文章：所有最新货运新闻 - 空运、海运和陆运更新" class="radius"
                    height="600"
                    width="600">
              </a>
              <div><p>{{ marketData.list[0].time }}</p>
                <h2 class="h2">
                  <a :href="'/newsDetail?type=market&id='+marketData.list[0].id">
                    {{ marketData.list[0].title }}
                  </a>
                </h2>
              </div>
              <p></p><a
                :href="'/newsDetail?type=market&id='+marketData.list[0].id"
                class="button button_outline_secondary">
              <span>查看更多</span>
              <svg class="icon" role="img">
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </a></div>
            <div class="newsletter">
              <div class="card card_size_lg bg_primary-dark">
                <div class="card__body c_white">
                  <div class="grid grid_md m_0 align-items_center">
                    <div class="grid__item spacing"><h2 class="h3 c_white">见解通讯</h2>
                      <p class="text_size_md">将最新的《行业洞察》新闻稿直接发送到您的收件箱。</p>
                      <div><a class="button button_size_lg button_color_secondary"
                              href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/">成为会员</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid__item">
            <div class="grid grid_flatten">
              <template v-for="(item,index) in marketData.list">
                <div v-if="index !== 0" :key="index" class="grid__item size_12 size_6_sm">
                  <div class="spacing">
                    <a :href="'/newsDetail?type=market&id='+item.id">
                      <img
                          :src="item.image"
                          alt="阅读文章：国际货运市场新闻，下载最新的 PDF 更新" class="radius"
                          height="250"
                          width="250"></a>
                    <div>
                      <p>{{ item.time }}</p>
                      <h3 class="h4">
                        <a :href="'/newsDetail?type=market&id='+item.id">{{ item.title }}</a>
                      </h3>
                    </div>
                    <p>{{ item.desc }}</p>
                    <a
                        :href="'/newsDetail?type=market&id='+item.id"
                        class="button button_outline_secondary">
                      <span>查看更多</span>
                      <svg class="icon" role="img">
                        <use xlink:href="#icon-arrow-right"></use>
                      </svg>
                    </a></div>
                </div>
              </template>


            </div>
          </div>
        </div>
        <!--        <div class="text_align_center bt py_2"><a-->
        <!--            class="button button_size_lg button_color_secondary"-->
        <!--            href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E5%B8%82%E5%9C%BA%E6%9B%B4%E6%96%B0/%E6%A1%A3%E6%A1%88/">较旧的文章</a>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {marketData} from "@/assets/mock/news";

export default {
  components: {},
  data() {
    return {
      marketData
    }
  }
}
</script>

<style scoped>

</style>