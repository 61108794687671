<template>
  <div class="section section_size_lg bg_primary-dark">
    <div class="section__container container">
      <div class="section__intro type type_inverted">
        <h1 class="c_white" :class="{h1: !bannerData.desc}">{{ bannerData.title }}</h1>
        <p class="text_lead">{{ bannerData.desc }}</p></div>
    </div>
    <img :src="bannerData.image"
         alt="" class="section__background" height="800" width="1200">
    <div class="section__screen bg_primary-dark"></div>
  </div>
</template>

<script>
export default {
  name: "commonBanner",
  props: ['bannerData']
}
</script>

<style scoped>

</style>