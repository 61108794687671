<template>
  <div>
    <common-banner :bannerData="governmentData.bannerData"></common-banner>

    <service-intro :infoData="governmentData.info"></service-intro>


    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>政府和军队后勤</h1>
            <p>在 Crane Worldwide Logistics，我们提供各种以政府和国防为重点的物流解决方案，以支持全球的美国政府和国防承包商。
              我们的目标是确保我们的所有客户在您与我们位于德克萨斯州休斯顿的政府控制塔互动时都能获得最高水平的支持。</p>
            <h2>Crane Worldwide Logistics 与美国政府签订的有效合同</h2>
            <p>全球重量级服务，多式联运 III，全球缔约。</p>
            <p><img alt="军事和政府后勤" class="align_center hidpi"
                    src="https://www.craneww.com/site/assets/files/1052/military_and_government_logistics.png"
                    width="895"></p>
            <h2>政府物流服务产品</h2>
            <p>我们的物流团队了解政府货运和航运的复杂性和时间敏感性。 我们设计了经过验证的解决方案，以满足大型项目和难以到达的区域。
              在提取资源、调动人员或复杂合同方面，我们是您的最佳合作伙伴。</p>
            <ul>
              <li>飞机包机</li>
              <li>NFO / AOG 服务</li>
              <li>全球供应/补给</li>
              <li>采购订单管理与执行</li>
              <li>严格的位置支持</li>
              <li>超限货物</li>
              <li>钻井平台和海事物流支持</li>
              <li>OCTG解决方案</li>
              <li>全球畜牧业</li>
              <li>代理服务</li>
              <li>立管清洁服务</li>
            </ul>
            <h2>批准的商品</h2>
            <ul>
              <li>军用车辆备件</li>
              <li>飞机零件</li>
              <li>高价值材料</li>
              <li>超大尺寸（机翼、襟翼、发动机、车辆等）</li>
              <li>武器系统</li>
              <li>武器弹药</li>
              <li>ITAR 受控材料</li>
              <li>有害物质</li>
              <li>普通货物</li>
            </ul>
            <h2>认证和许可证</h2>
            <p>作为全球行业的货运代理，我们努力为客户提供贸易合规方面的专业知识。 我们从本质上理解有关处理美国国务院许可证的独特要求。
              Crane Worldwide Logistics 已获得多项认证，可在政府部门有效运作。</p>
            <p>认证：IATA、C-TPAT、间接航空承运人 (IAC)、货运代理执照 (FF)、安全商务商业联盟 (BASC)、国家和地方报关行许可证、TAPA-A
              级以及多个地点的 AEO 认证.</p>
            <p>书面政策和培训确保我们的团队在各个层面都合规。
              全球政策和程序包括全球贸易、GCPA/英国贿赂、反垄断和全球贸易出口合规、反抵制政策和报告程序、商业行为准则、反垄断和竞争政策。
              审核包括 GTC-RCA 计划、软距离审核和每周抽查。</p></div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">最新文章</h3>
          <press-list></press-list>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {governmentData} from "@/assets/mock/industry";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      governmentData
    }
  }
}
</script>

<style scoped>

</style>