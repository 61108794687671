<template>
  <div>
    <common-banner :bannerData="technologyData.bannerData"></common-banner>

    <service-intro :infoData="technologyData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h2>全集成物流技术</h2>
            <p>我们独特的人员、服务和技术融合使我们的技术产品能够发展成为值得信赖的物流平台，为我们的客户提供对整个供应链和需求链的准确可见性。
              我们的解决方案以更快、更高效的操作取代了痛苦且耗时的手动流程。</p>
            <p>我们所有的技术产品都采用以用户为中心的设计，其简单目标是让我们的客户能够实时处理可操作的运输、货物和库存信息。
              我们确保我们的系统允许我们的客户做出明智的供应链决策，以支持和提高他们组织的绩效。</p>
            <h3>支持您的供应链的系统</h3>
            <ul>
              <li><img alt="货运和货运技术" class="align_right hidpi"
                       src="https://www.craneww.com/site/assets/files/3066/freight_and_cargo_technology.450x0-is-hidpi.jpg"
                       width="450">运输管理系统 (TMS)
              </li>
              <li>采购订单管理系统</li>
              <li>仓库管理系统 (WMS)</li>
              <li>企业资源规划（ERP）</li>
              <li>生产检验工具</li>
              <li>外部整合</li>
              <li>供应链门户</li>
              <li>需求计划</li>
              <li>订单状态</li>
              <li>邮寄状态</li>
            </ul>
            <hr>
            <blockquote>
              <center><p class="gt-block"><strong>我们的技术产品</strong> - Crane Worldwide Logistics
                提供一套集成解决方案，使您的企业能够实时交换运输、仓储和采购订单级别的数据。</p></center>
            </blockquote>
            <hr>
            <h3><strong>C-VIEW（货件追踪器）</strong></h3>
            <p>C-View 是我们的虚拟视点，客户可以通过 Crane Worldwide Logistics 自由访问和跟踪他们的货物。</p>
            <p class="gt-block" style="text-align: left;"><a class="button button_size_lg button_color_secondary"
                                                             href="https://craneww.com/logistics-technology/shipment-tracking/"
                                                             left="">更多关于 CVIEW</a></p>
            <h3><a href="https://craneww.com/logistics-technology/shipment-tracking/"><img
                alt="集装箱港口、集装箱船和空运" class="align_center"
                src="https://www.craneww.com/site/assets/files/3066/container_port-_container_ship_and_air_freight.png"
                width="1193"></a></h3>
            <h3><strong>PINPOINT（采购订单管理）</strong></h3>
            <p>Pinpoint 是 Crane Worldwide 采购订单 (PO) 管理解决方案的名称，可在客户的供应链中近乎实时地提供端到端的项目可见性，直至
              (PO) 订单和项目详细信息。</p>
            <p class="gt-block" style="text-align: left;"><a class="button button_size_lg button_color_secondary"
                                                             href="https://craneww.com/logistics-technology/purchase-order-management/"
                                                             left="">更多关于 PINPOINT</a></p>
            <h3><a href="https://craneww.com/logistics-technology/purchase-order-management/"><img
                alt="使用采购订单管理系统的专业人员" class="align_center"
                src="https://www.craneww.com/site/assets/files/3066/purchase_order_management_system_stages.png"
                width="1193"></a></h3>
            <h3><strong>CRANE TMS（运输管理系统）</strong></h3>
            <p>Crane TMS 系统提供了一个单一、直观的运输软件平台，以确定成本最低的交付选项，包括辅助成本、客户的交付偏好等。
              比较包裹、零担和卡车运输的承运人和费用。</p>
            <p class="gt-block" style="text-align: left;"><a class="button button_size_lg button_color_secondary"
                                                             href="/service?type=index"
                                                             left="">更多关于 TMS</a></p>
            <p><a
                href="/service?type=index"><img
                alt="跟踪卡车的高速公路，几个专业人员进行盘点，工作人员进行周期盘点" class="align_center"
                src="https://www.craneww.com/site/assets/files/3066/transportation_management_advantages.png"
                width="1193"></a></p>
            <h3><strong>FOOTPRINT（仓库管理系统）</strong></h3>
            <p>Footprint 是我们基于工作流的仓库管理系统 (WMS)，用于管理仓库配送服务。 该工具旨在审查现有库存和交易。</p>
            <p class="gt-block" style="text-align: left;"><a class="button button_size_lg button_color_secondary"
                                                             href="/technology?type=wms">更多关于足迹</a>
            </p>
            <p><a
                href="/technology?type=wms"><img
                alt="叉车在仓库内移动托盘，员工移动货物，几个同行验证仓库内的托盘" class="align_center"
                src="https://www.craneww.com/site/assets/files/3066/warehouse_management_system.png" width="1193"></a>
            </p></div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">相关案例研究</h3>
        <div class="grid grid_l">
          <div class="grid__item size_12 size_6_sm">
            <div class="spacing">
              <div class="media"><a
                  class="media__image size_3"
                  href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%A1%88%E4%BE%8B%E7%A0%94%E7%A9%B6/%E9%87%87%E8%B4%AD%E8%AE%A2%E5%8D%95%E7%AE%A1%E7%90%86/"><img
                  class="radius" height="150" src="https://www.craneww.com/site/assets/files/2844/pinpoint_3.150x150.jpg"
                  width="150"></a>
                <div class="media__body"><a
                    href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%A1%88%E4%BE%8B%E7%A0%94%E7%A9%B6/%E9%87%87%E8%B4%AD%E8%AE%A2%E5%8D%95%E7%AE%A1%E7%90%86/">
                  <h2 class="h4">采购订单管理 - 控制上游采购订单 - 可见性和供应商管理</h2></a>
                  <p>采购订单管理……全球供应链中断往往是公司检查其供应链的催化剂。 当我们的其中一位...</p><a
                      class="button button_outline_secondary"
                      href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%A1%88%E4%BE%8B%E7%A0%94%E7%A9%B6/%E9%87%87%E8%B4%AD%E8%AE%A2%E5%8D%95%E7%AE%A1%E7%90%86/"> <span>了解更多</span>
                    <svg class="icon" role="img">
                      <use xlink:href="#icon-arrow-right"></use>
                    </svg>
                  </a></div>
              </div>
            </div>
          </div>
          <div class="grid__item size_12 size_6_sm">
            <div class="spacing">
              <div class="media"><a
                  class="media__image size_3"
                  href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E5%BE%AE%E8%BD%AF%E7%9A%84-Satya-Nadella-%E9%87%8D%E7%82%B9%E4%BB%8B%E7%BB%8D%E4%BA%86%E5%85%A8%E7%90%83%E8%B5%B7%E9%87%8D%E6%9C%BA/"><img
                  class="radius" height="150"
                  src="https://www.craneww.com/site/assets/files/2449/satya_nadella.150x150.jpg" width="150"></a>
                <div class="media__body"><a
                    href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E5%BE%AE%E8%BD%AF%E7%9A%84-Satya-Nadella-%E9%87%8D%E7%82%B9%E4%BB%8B%E7%BB%8D%E4%BA%86%E5%85%A8%E7%90%83%E8%B5%B7%E9%87%8D%E6%9C%BA/">
                  <h2 class="h4">微软的萨蒂亚·纳德拉（Satya Nadella）着重介绍Crane Worldwide</h2></a>
                  <p>微软首席执行官萨蒂亚·纳德拉（Satya Nadella）着重指出Crane Worldwide Logistics是AI首创组织</p><a
                      class="button button_outline_secondary"
                      href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E5%BE%AE%E8%BD%AF%E7%9A%84-Satya-Nadella-%E9%87%8D%E7%82%B9%E4%BB%8B%E7%BB%8D%E4%BA%86%E5%85%A8%E7%90%83%E8%B5%B7%E9%87%8D%E6%9C%BA/"> <span>了解更多</span>
                    <svg class="icon" role="img">
                      <use xlink:href="#icon-arrow-right"></use>
                    </svg>
                  </a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {technologyData} from "@/assets/mock/technology";
import ServiceIntro from "@/components/serviceIntro.vue";

export default {
  components: {ServiceIntro, CommonBanner},
  data() {
    return {
      technologyData
    }
  }
}
</script>

<style scoped>

</style>