<template>
  <div>
    <common-banner :bannerData="wmsData.bannerData"></common-banner>

    <service-intro :infoData="wmsData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h2><strong>什么是仓库管理系统（WMS）？</strong></h2>
            <p>仓库管理系统 (WMS) 提高了对企业整个库存的可见性，并管理从配送中心到商店货架的供应链履行操作。</p>
            <p class="gt-block"><strong>脚印</strong> 是一种仓库管理系统 (WMS)，旨在帮助运营提高跟踪库存和供应的效率。
              我们领先的平台为您的企业提供了一套独特的解决方案，以跟踪您仓库中的所有物品及其所在位置。</p>
            <h3><strong>为什么您的企业应该使用仓库管理系统 (WMS)？</strong></h3>
            <ul>
              <li>减少电子表格和手动数据输入。</li>
              <li>建立详细的报告以跟踪和控制成本。</li>
              <li>自动化运输和贴标流程以节省人工时间并减少错误。</li>
              <li>通过选择列表和简化的工作流程提高工作人员的效率。</li>
              <li>全面了解库存水平和区域，以改进材料处理。</li>
            </ul>
            <h3><strong>仓库管理系统 (WMS) 的好处</strong></h3>
            <p class="gt-block"><strong>脚印</strong> 提供完整的入站操作管理，包括 ASN 订单。</p>
            <ul>
              <li>批次控制允许跟踪每种材料类型的唯一批次和供应商批次。
                库存存储为不同类型的材料，使您将存储的每件商品都可以完全定制。
              </li>
              <li>串行控制允许指定独特的特征和质量，并跟踪仓库中的单个项目。</li>
              <li>材料按可移动的车牌分组。</li>
              <li>必要时可以快速调整物料总量。</li>
              <li>可以设置任意数量的材料位置，并以您选择的任何方式进行组织。</li>
              <li>移动使跟踪您的库存变得轻而易举。</li>
            </ul>
            <h3><strong>将库存带入您的仓库</strong></h3>
            <ul>
              <li>跟踪订单的每一个细节都很容易； 提单号、相关电子邮件地址，甚至海外运输的港口信息 - 一切都在
                <strong>脚印</strong>.
              </li>
              <li>接收快速而直接，可选择条形码接收。</li>
              <li>系统引导的 PutAway 使工作更轻松、更高效。</li>
            </ul>
            <h3><strong>将库存发送出您的仓库</strong></h3>
            <ul>
              <li>基于您的偏好的系统引导库存分配简化了流程。</li>
              <li>挑选出境货物很容易，如果有任何变化，只需点击几下即可重新分配。</li>
              <li>强大的包装系统允许快速创建和组织运输容器和车牌。</li>
            </ul>
            <p class="gt-block">克瑞环球物流提供全套 <a
                href="/service?type=contract">仓储服务</a>
              并在我们的设施中提供 <a href="/%E5%9C%B0%E7%82%B9/">全球地点</a>.&nbsp;</p>
            <blockquote><p class="gt-block"><strong>作为您首选的仓库管理系统 (WMS)，FOOTPRINT
              提供的工具可确保您的仓库运营以最佳方式运行。</strong></p>
              <p class="gt-block">您可以跟踪的主要 KPI <strong>脚印</strong> 衡量您的仓库管理流程是否成功：</p>
              <ul>
                <li>接收效率</li>
                <li>回报率</li>
                <li>拣选精度</li>
                <li>订单提前期</li>
              </ul>
              <p class="gt-block"><a href="/quotation">想让您的仓库像钟表一样运转吗？
                <strong>立即联系我们的团队</strong></a></p></blockquote>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">相关案例研究</h3>
        <press-list></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {wmsData} from "@/assets/mock/technology";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      wmsData
    }
  }
}
</script>

<style scoped>

</style>