<template>
  <div>
    <about v-if="$route.query.type === 'index'"></about>
    <our-value v-if="$route.query.type === 'ourValue'"></our-value>
    <leader v-if="$route.query.type === 'leader'"></leader>
    <careers v-if="$route.query.type === 'careers'"></careers>
    <sustain v-if="$route.query.type === 'sustain'"></sustain>
    <community v-if="$route.query.type === 'community'"></community>
    <security v-if="$route.query.type === 'security'"></security>
    <compliance v-if="$route.query.type === 'compliance'"></compliance>
    <qhse v-if="$route.query.type === 'qhse'"></qhse>

  </div>
</template>

<script>
import About from "@/views/about/components/about.vue";
import OurValue from "@/views/about/components/ourValue.vue";
import Leader from "@/views/about/components/leader.vue";
import Careers from "@/views/about/components/careers.vue";
import Sustain from "@/views/about/components/sustain.vue";
import Community from "@/views/about/components/community.vue";
import Security from "@/views/about/components/security.vue";
import Compliance from "@/views/about/components/compliance.vue";
import Qhse from "@/views/about/components/qhse.vue";

export default {
  name: "index",
  components: {Qhse, Compliance, Security, Community, Sustain, Careers, Leader, OurValue, About},
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>