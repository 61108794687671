<template>
  <div>
    <common-banner :banner-data="sustainData.bannerData"></common-banner>

    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h1>供应链的可持续性</h1>
          <p>
            秉承我们在全球供应链中的角色，我们致力于与客户和承运商合作，致力于运输脱碳工作。我们将利用数据分析来推动明智的决策，平衡成本和效率，同时利用可用的创新解决方案和技术。</p>
          <h2>我们的可持续发展使命</h2>
          <blockquote><p>
            在克瑞全球物流，我们的可持续承诺将环境管理、社区影响和客户价值融为一体。我们努力创造更绿色的未来，并通过创新、负责任的实践丰富我们的合作。这是我们的共同目标，每天都做出改变。</p>
          </blockquote>
          <h2>可持续物流时间表 - 我们的可持续发展之旅</h2>
          <p>
            设定未来目标使我们公司能够在整个业务流程中推动举措。凭借长远的眼光和可行的短期目标，我们努力在我们全球运营的每个领域开发更可持续的商业实践。</p>
          <p><img alt="可持续发展时间表" class="align_center"
                  src="https://www.craneww.com/site/assets/files/3811/sustainability_timeline-1.png" width="1512"></p>
          <p>作为领先的物流提供商和客户合作伙伴，我们致力于可持续发展的改进，并共同努力实现最可持续的运输实践。&nbsp;</p>
          <h2>我们的承诺：到 2040 年实现净零碳排放</h2>
          <ul>
            <li>我们的环境与可持续发展委员会定期召开会议，审查目标进展情况，提供问责制和资源承诺</li>
            <li>我们将在 1 年底前建立全球范围 2 和范围 2023 排放基准（2024 年）</li>
            <li>我们制定了到 50 年将范围 1 和范围 2 排放量减少 2030% 的近期目标</li>
            <li>拥抱循环经济，到50年我们将实现废物减少2035%</li>
            <li>我们为客户提供全球物流排放委员会 (GLEC) 认证的通过我们的全球 TMS（范围 2）管理的货运的 CO3e 排放量</li>
            <li>利用数据和分析，我们将与客户合作，减少其供应链中目标运输通道的排放和强度</li>
          </ul>
          <hr>
          <h2>气候承诺</h2>
          <p class="gt-block"><img alt="气候承诺徽标 - Crane Worldwide 承诺" class="align_right"
                                   src="https://www.craneww.com/site/assets/files/3811/climate_pledge_tcp_logo_rgb_darkgreen.320x0-is.png"
                                   width="320"><a
              href="https://www.theclimatepledge.com/us/en/the-pledge/About">气候承诺</a> 是到 2040
            年实现净零碳排放的承诺。它将世界顶尖公司聚集在一起，加速联合行动、跨部门合作和负责任的变革。</p>
          <p>Crane Worldwide Logistics 很荣幸签署了《气候承诺》，因为我们承诺到 2040
            年在我们的运营中实现净零碳排放。在这一过程中，我们致力于定期报告、实施脱碳战略和可信的抵消。</p>
          <p>气候承诺签署方同意：</p>
          <ul>
            <li>定期测量和报告温室气体排放量。</li>
            <li>
              通过实际的业务变革和创新，实施符合《巴黎协定》的脱碳战略，包括提高效率、可再生能源、减少材料和其他碳排放消除战略。
            </li>
            <li>通过额外的、可量化的、真实的、永久的和对社会有益的抵消措施来中和任何剩余排放，到 2040 年实现年度净零碳排放——比《巴黎协定》的
              2050 年目标提前十年。
            </li>
          </ul>
          <h2>未来</h2>
          <p>
            我们致力于在全球范围内做出可持续的努力，并正在与客户一起踏上持续改进的重要旅程。整个供应链流程中的每个合作伙伴都必须在更加可持续的未来中发挥自己的作用，这一点至关重要。我们与合作伙伴、客户和员工合作，将可持续发展置于我们行动的首要位置，以改善供应链运营。</p>
          <p><img alt="可持续性数据跟踪即将推出" class="align_center"
                  src="https://www.craneww.com/site/assets/files/3811/sustainability_coming_soon_banner_cww.1347x0-is.jpg"
                  width="1347"></p>
          <p>&nbsp;</p>
          <p><br>&nbsp;</p></div>
      </div>
    </div>

    <quotation></quotation>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {sustainData} from "@/assets/mock/about";
import Quotation from "@/components/quotation.vue";

export default {
  components: {Quotation, CommonBanner},
  data() {
    return {
      sustainData
    }
  }
}
</script>

<style scoped>

</style>