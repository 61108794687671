var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section section_size_s bg_primary-dark"},[_c('div',{staticClass:"section__container container"},[_c('div',{staticClass:"section__intro type type_inverted"},[_c('p'),_c('h1',{staticClass:"c_white"},[_vm._v(_vm._s(_vm.currentGuide.title))])])]),_c('div',{staticClass:"section__screen bg_primary-dark"})]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container container_size_xs"},[_c('div',{staticClass:"type"},[_c('div',{staticClass:"type",domProps:{"innerHTML":_vm._s(_vm.currentGuide.detail)}}),_c('hr'),_c('h2',[_vm._v("有关 Incoterms 的更多详细信息： ")]),_c('p',[_vm._v("单击下面以获取有关运输条款的更多信息：")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=5"}},[_vm._v("FCA")])]),_vm._v("(免费承运人) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=6"}},[_vm._v("CPT")])]),_vm._v(" （运费付至） ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=7"}},[_vm._v("CIP")])]),_vm._v("（运费和保险付给） ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=8"}},[_vm._v("DAP")])]),_vm._v(" （当场交货） ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=9"}},[_vm._v("DPU")])]),_vm._v(" （在卸货地点交付） ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=10"}},[_vm._v("DDP")])]),_vm._v(" (完税交货)")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=11"}},[_vm._v("FAS")])]),_vm._v(" （船边免费）")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=1"}},[_vm._v("FOB")])]),_vm._v(" （免费董事会）")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=2"}},[_vm._v("CFR")])]),_vm._v(" （成本加运费）")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block"},[_c('strong',[_c('a',{attrs:{"href":"/guideDetail?id=3"}},[_vm._v("CIF")])]),_vm._v(" （成本，保险费加运费）")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"gt-block",staticStyle:{"text-align":"center"}},[_c('a',{staticClass:"button button_size_lg button_color_secondary",attrs:{"center":"","href":"/news?type=guide"}},[_vm._v("回到国际贸易术语")])])
}]

export { render, staticRenderFns }