import Vue from 'vue'
import VueRouter from 'vue-router'
import homeView from '@/views/home/index.vue'
import serviceIndex from '../views/service/index.vue'
import industry from "@/views/industry/index.vue";
import technology from "@/views/technology/index.vue";
import about from "@/views/about/index.vue";
import leader from "@/views/about/leader.vue";
import quotation from "@/views/single/quotation.vue";
import news from  "@/views/news/index.vue"
import newsDetail from "@/views/news/detail.vue"
import caseDetail from "@/views/news/caseDetail.vue"
import guideDetail from "@/views/news/guideDetail.vue";
import policy from "@/views/single/policy.vue";
import termsOfService from  "@/views/single/service.vue";
import statement from  "@/views/single/statement.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: homeView
  },
  {
    path: '/service',
    component: serviceIndex
  },
  {
    path: '/industry',
    component: industry
  },
  {
    path: '/technology',
    component: technology
  },
  {
    path: '/about',
    component: about
  },
  {
    path: '/leader',
    component: leader
  },
  {
    path: '/news',
    component: news
  },
  {
    path: '/newsDetail',
    component: newsDetail
  },
  {
    path: '/caseDetail',
    component: caseDetail
  },
  {
    path: '/guideDetail',
    component: guideDetail
  },
  {
    path: '/quotation',
    component: quotation
  },
  {
    path: '/policy',
    component: policy
  },
  {
    path: '/termsOfService',
    component: termsOfService
  },
  {
    path: '/statement',
    component: statement
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
