<template>
  <div class="section by">
    <div class="container container_size_sm spacing_xl">
      <div class="grid grid_md">
        <div class="grid__item size_8 spacing_xl"><h1 class="h1 text_align_center">新闻中心</h1>
          <div class="spacing_xl">
            <div v-for="(item,index) in pressData.list" :key="index" class="media align-items_center">
              <div class="media__body">
                <div>
                  <p>{{ item.time }}</p>
                  <h2 class="h3">
                    <a :href="'/newsDetail?type=press&id='+item.id">{{ item.title }}</a>
                  </h2>
                </div>
                <p>{{ item.desc }}</p>
                <a :href="'/newsDetail?type=press&id='+item.id" class="button button_outline_secondary">
                  <span>查看更多</span>
                  <svg class="icon" role="img">
                    <use xlink:href="#icon-arrow-right"></use>
                  </svg>
                </a>
              </div>
            </div>

          </div>
        </div>
        <div class="grid__item size_4">
          <!--          <div class="card card_size_sm bg_primary-dark">-->
          <!--            <div class="card__body c_white">-->
          <!--              <div class="grid grid_md m_0 align-items_center">-->
          <!--                <div class="grid__item c_white type"><h4 class="h5 c_white">注册以接收定期物流行业更新！</h4>-->
          <!--                  <p class="text_size_sm">随时了解并注册我们的时事通讯，其中包含所有最新的行业信息。</p>-->
          <!--                  <div><a class="button button_size_sm button_color_secondary"-->
          <!--                          href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/">报名参与</a></div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="resources-list card card_size_sm bg_primary-white">
            <div class="card__body">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item type text_size_sm"><h3>对于所有媒体查询，请联系：</h3>
                  <p>需要了解当前 3PL 环境吗？&nbsp;</p>
                  <p>请不要犹豫，联系：&nbsp;</p>
                  <p>Kate Yates，数字营销、公关和传播总监</p>
                  <p class="gt-block"><strong><a
                      href="mailto:kate.yates@craneww.com">凯特·耶茨@Craneww.com</a>&nbsp;</strong></p></div>
              </div>
            </div>
          </div>
          <div class="card card_size_sm bg_primary-dark">
            <div class="card__body c_white">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item c_white type"><h4 class="h5 c_white">行业洞察</h4>
                  <p class="text_size_sm">Crane Worldwide Logistics 发表的最新文章</p>
                  <div><a class="button button_size_sm button_color_secondary"
                          href="/news?type=industry">阅读文章</a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="resources-list card card_size_sm bg_primary-white">
            <div class="card__body">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item type text_size_sm"><h2>鹤咖啡</h2>
                  <p class="gt-block">您可以在这里或您播客的任何地方收听我们所有的剧集（<a
                      href="https://open.spotify.com/show/3PV6j3xuM59hR3060mdO9Z">Spotify</a>, <a
                      href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8xNDg0ZDMwYy9wb2RjYXN0L3Jzcw==">Google播客</a>或
                    <a href="https://podcasts.apple.com/us/podcast/coffee-with-crane/id1508247853">Apple播客</a>).</p>
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="card card_size_sm bg_primary-dark">-->
          <!--            <div class="card__body c_white">-->
          <!--              <div class="grid grid_md m_0 align-items_center">-->
          <!--                <div class="grid__item c_white type"><h4 class="h5 c_white">行业网络研讨会</h4>-->
          <!--                  <p class="text_size_sm">我们所有最新的行业洞察网络研讨会都可供您使用！</p>-->
          <!--                  <div><a class="button button_size_sm button_color_secondary"-->
          <!--                          href="https://craneww.com/knowledge-center/webinars/">收听</a></div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="resources-list card card_size_sm bg_primary-white">
            <div class="card__body">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item type text_size_sm"><h2>市场动态</h2>
                  <p class="gt-block">Crane Worldwide Logistics
                    定期提供空运、海运和陆运市场更新，可供下载<strong> </strong><strong><a
                        href="/news?type=market">这里。</a></strong>
                  </p></div>
              </div>
            </div>
          </div>
          <div class="card card_size_sm bg_primary-dark">
            <div class="card__body c_white">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item c_white type"><h4 class="h5 c_white">贸易咨询</h4>
                  <p class="text_size_sm">更多关于我们专家的国际贸易通知。</p>
                  <div><a class="button button_size_sm button_color_secondary"
                          href="/service?type=trade">了解更多</a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="resources-list card card_size_sm bg_primary-white">
            <div class="card__body">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item type text_size_sm"><h1>社交媒体&nbsp;</h1>
                  <p>查看最新的</p>
                  <ul>
                    <li><a href="https://www.linkedin.com/company/crane-worldwide-logistics_2">LinkedIn</a>,</li>
                    <li><a href="https://www.facebook.com/CraneWorldwide">Facebook</a>,</li>
                    <li><a href="https://www.instagram.com/crane_worldwide_logistics/?hl=en">Instagram</a>,</li>
                    <li><a href="https://twitter.com/CWWLogistics">Twitter</a>&nbsp;</li>
                    <li><a href="https://www.youtube.com/channel/UCWmBEeR7nQr6vM07d32Ytxg">YouTube</a></li>
                  </ul>
                  <p class="gt-block"><strong>关注我们以保持最新！</strong></p>
                  <p>&nbsp;</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {pressData} from "@/assets/mock/news";

export default {
  name: "press",
  data() {
    return {
      pressData
    }
  }
}
</script>

<style scoped>

</style>