<template>
  <div>
    <common-banner :banner-data="qhseData.bannerData"></common-banner>

    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h2>Crane Worldwide
          Logistics致力于维护我们员工，客户，承包商和我们社区的健康与安全。</h2>
          <p>当务之急是为我们的员工，客户，承包商和周围社区提供安全健康的工作场所。 此承诺与我们以道德，对社会负责和对环境负责的方式开展业务的公司目标一致。
            在计划和执行我们设施中的所有工作活动时，环境，健康和安全是首要考虑因素。 作为积极的健康，安全和环境计划的一部分，我们制定了以下目标：&nbsp;</p>
          <p>•通过提供培训来减少事故和伤害的可能性，提供一个安全健康的工作场所。&nbsp;</p>
          <p>•立即对可能危害任何人，周围社区和/或环境的健康或安全的事件做出响应。&nbsp;</p>
          <p>•达到或超过所有适用的环境和安全法规要求。&nbsp;</p>
          <p>•及时响应员工，承包商，客户和社区对环境，健康和安全问题的关注。&nbsp;</p>
          <p>•通过进行自我评估，定期检查并利用安全和环境专家的外部审核来进行持续改进。</p>
          <p>•我们的组织具有信任的基础，并授权所有员工在认为情况不安全的情况下有权停止工作。 每位员工都有责任确保始终存在安全的工作环境。&nbsp;</p>
          <p>•继续改进我们的废物最小化计划，以无害环境的方式集中于源头和废物减少，回收和废物处置。
            回馈社区并倡导健康的生活方式是我们Crane关怀的一部分。&nbsp;</p>
          <p>•每个工厂都有一个安全协调员和安全委员会，负责拥有Crane Worldwide安全计划和计划。 &nbsp;</p>
          <h3>品质保证</h3>
          <p>Crane Worldwide Logistics致力于为所有有关方面提供优质，安全，可靠和环保的供应链解决方案和服务。</p>
          <p>我们的目标是超越客户的期望，满足所有适用的要求，并致力于持续改进质量管理体系。</p>
          <p>我们坚信以学习为目的倾听，并努力调整我们的业务和解决方案以适应客户的需求。&nbsp;</p>
          <p>我们的行动，方法和成果是一致的，可衡量的，并且反映了我们组织的信念。&nbsp;</p></div>
      </div>
    </div>

    <quotation></quotation>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {qhseData} from "@/assets/mock/about";
import Quotation from "@/components/quotation.vue";

export default {
  components: {Quotation, CommonBanner},
  data() {
    return {
      qhseData
    }
  }
}
</script>

<style scoped>

</style>