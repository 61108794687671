<template>
  <div>
    <common-banner :bannerData="freightData.bannerData"></common-banner>

    <service-intro :infoData="freightData.info"></service-intro>
    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>货物保险，涵盖您的货运</h1>
            <p>近年来，全球货物运输中出现了许多不可预见的情况。&nbsp;</p>
            <p>货物保险专为满足您的需求、保护您的货物价值和承担法律责任而设计。 货物保险索赔由专门的专业团队处理，确保您在国内和国际运输货物时高枕无忧。&nbsp;</p>
            <p class="gt-block">作为您的供应链合作伙伴，提供全套物流解决方案，包括<strong> <a
                href="/service?type=air">空运</a>, <a
                href="/service?type=sea">海运</a></strong> 和 <strong><a
                href="/service?type=contract">仓库解决方案</a></strong>，我们提供所有风险货物保险，如果您的货物运输过程中出现任何问题，我们将确保您的货物得到充分保障。
              如果发生丢失或损坏（被视为不可抗力或其他原因），我们的综合货物保险将涵盖您贵重货物的全部费用。&nbsp;</p>
            <h2>不可预见的事件可能会影响您的底线</h2>
            <p class="gt-block">要详细了解与您的货运保险风险范围相关的不同类型的责任，请联系您的 <strong><a
                href="/quotation">销售代表</a></strong>
              今天了解更多关于我们如何帮助您获得完整的货物保险的信息，以及需要时的条款和条件列表。 为您的货物购买适合您运输方式的保险。&nbsp;
            </p>
            <p>您选择的保险类型可能会在现在和将来的运输过程中发挥关键作用，并确保您对货物的任何物理损失感到安心。
              承运人责任为您的货物提供有限的保险。 自然灾害，事故等可能会损坏您的货物。
              确保您的货物在运输过程中不致丢失，被盗或损坏，将确保您的货物得到货物保护。
              今天就向我们索取保险报价。&nbsp;</p>
            <h2>货物保险：一般海损&nbsp;</h2>
            <p class="gt-block">拥有货物保险保护您的货物的一大优势是货物保险还涵盖 <strong>共同海损</strong>.
              如果您的货件包含在共同海损申报中，则保险单将涵盖共同海损保证金，以便让您的货物放行。 这使您的公司不必进行任何额外的现金存款。
            </p>
            <h2>什么是一般平均？</h2>
            <p class="gt-block"><a
                href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E5%85%B1%E5%90%8C%E6%B5%B7%E6%8D%9F%E8%88%AA%E8%BF%90%E4%BF%9D%E9%99%A9/">综合平均法</a>
              是一个基于海事传统的概念，并受 1990
              年修订的一套称为《约克/安特卫普规则》的规则管辖。参与须遵守海洋运输合同的条款和条件。共同海损由船东申报。选择共同海损理算员来管理该流程。他们的首要工作是确保所有各方参与并收到所有货主的押金。押金是粗略估计的发票价值的比例值，用于支付货主在共同海损中的部分。由于整个过程可能需要很长时间才能解决，因此必须从一开始就确保参与和资金。
            </p>
            <p>例如，如果一位客户的货物装在拼箱集装箱中，但没有提交共同海损表格，则整个集装箱将被扣留。
              如果客户未能参加共同海损，他们的货物可被视为放弃，将导致客户没有财务追索权。</p>
            <p class="gt-block">向承运人发出带有说明和表格的共同海损通知，承运人将其传递给货主或通过其货运代理。
              拥有货物保险来保护您的货物的一大特色是货物保险还涵盖共同海损。 如果您的货件包含在共同海损申报中，则保险单
              <strong>将覆盖</strong> 共同海损保证金，以便让您的货物放行。 这使您的公司不必进行任何额外的现金存款。</p>
            <div class="card card_size_lg bg_primary-dark">
              <div class="card__body c_white">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item spacing"><h2 class="h3 c_white">接收最新的物流新闻</h2>
                    <p class="text_size_lg">所有最新最快的市场信息可以直接发送到您的收件箱</p></div>
                  <div class="grid__item size_auto spacing text_align_center">
                    <div><a class="button button_size_lg button_color_secondary"
                            href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/">成为会员</a>&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">...了解更多</h3>
        <press-list type="market"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {freightData} from "@/assets/mock/service";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "air",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      freightData
    }
  }
}
</script>

<style scoped>

</style>