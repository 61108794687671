<template>
  <div>
    <news v-if="$route.query.type === 'index' || !$route.query.type"></news>
    <market v-if="$route.query.type === 'market'"></market>
    <industry v-if="$route.query.type === 'industry'"></industry>
    <trade v-if="$route.query.type === 'trade'"></trade>
    <case v-if="$route.query.type === 'case'"></case>
    <podcast v-if="$route.query.type === 'podcast'"></podcast>
    <guide v-if="$route.query.type === 'guide'"></guide>
    <press v-if="$route.query.type === 'press'"></press>
    <exit v-if="$route.query.type === 'exit'"></exit>
  </div>
</template>

<script>
import News from "@/views/news/components/news.vue";
import Market from "@/views/news/components/market.vue";
import Industry from "@/views/news/components/industry.vue";
import Trade from "@/views/news/components/trade.vue";
import Case from "@/views/news/components/case.vue";
import Podcast from "@/views/news/components/podcast.vue";
import Guide from "@/views/news/components/guide.vue";
import Press from "@/views/news/components/press.vue";
import Exit from "@/views/news/components/exit.vue";

export default {
  name: "index",
  components: {Exit, Press, Guide, Podcast, Case, Trade, Industry, Market, News}
}
</script>

<style scoped>

</style>