<template>
  <div>
    <common-banner :banner-data="communityData.bannerData"></common-banner>


    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h1><strong>企业公民 - 回馈我们的社区</strong></h1>
          <p>我们认为，提供社区援助和成为负责任的企业公民非常重要。 我们在我们经营的地方负有社会责任。&nbsp;</p>
          <p>我们的员工在世界各地参加当地活动，以支持为有需要的人提供服务的慈善机构。</p>
          <p>Crane Cares 由我们当地的员工推动； 捐赠、参与和积极参与社区项目和慈善机构使我们的员工能够在他们生活的地区有所作为。
            作为一个团队走到一起可以在我们的社区中产生巨大的变化，通过支持和贡献的方式回馈是我们企业文化的重要组成部分。&nbsp;</p>
          <h2>2024 年起重机关怀计划</h2>
          <p>2024 年，我们的全球团队将继续关注粮食不安全问题。自 2019 年以来，营养不良人数增加了 10%，全球有超过 800
            亿人处于饥饿状态。造成这一现象的因素包括不平等、贫困、冲突、气候变化、灾害，以及我们最近都熟悉的流行病。
            14万儿童营养不良，45%的儿童死亡因饥饿；每天有 700 名儿童因脏水和不卫生的生活条件而死亡。只要齐心协力，我们可以有所作为。&nbsp;</p>

          <p>&nbsp;</p>
          <hr>
          <h2><strong>Crane关怀</strong></h2>
          <p>Crane Cares 为我们自己的员工提供了一个框架，可以与合作伙伴和慈善机构一起在当地推动倡议。 Crane Cares 包括三个部分：&nbsp;</p>
          <p class="gt-block"><strong>放眼全球</strong>：我们对社区的承诺。 我们在全球的员工参与的活动反映了我们对社区的承诺，并支持贫困，患病和受灾难影响的人们。
            最近的例子包括重大的回收计划，向孤儿院志愿服务，向医院捐款，收集学校用品，为无家可归的儿童提供饭菜以及捐赠时间和金钱来援助洪水和飓风灾民。
          </p>
          <p class="gt-block"><strong>全球绿色</strong>：我们对环境的承诺。
            通过“绿色世界”，我们参与了全球范围内的回收计划，清理了南美的海滩，维护了印度的花园并在英国种植了树木，并帮助清理了北美的社区。
          </p>
          <p class="gt-block"><strong>世界各地的生活</strong>：我们对员工健康和安全的承诺。
            我们通过专注于体育活动，压力管理和心理健康，健康饮食和人身安全的健康计划，向全球员工推广健康的生活方式。
            我们提倡员工高度敬业和舒适的工作关系。&nbsp;&nbsp;</p>
          <hr>
          <h2><strong>乌克兰支持</strong></h2>
          <p><img alt="双手环绕的地球仪" class="align_right"
                  src="https://www.craneww.com/site/assets/files/1026/crane_cares_globe_image.320x0-is.jpg" width="320">Crane
            Cares 以紧迫感响应乌克兰边境的人道主义援助需求。</p>
          <p class="gt-block">与...合作 <a href="https://pmm.org.pl/en">波兰医学协会</a>, Crane Cares
            设立了筹款活动，以支持慈善机构的医疗救助需求。</p>
          <p>捐赠的所有资金均由慈善机构直接接收，用于购买紧急医疗用品并为乌克兰难民提供支持。</p>
          <p>此外，我们的一些站点已经收集了物资，并与合作组织直接运送到波兰的慈善机构和乌克兰。</p>
          <p>非常感谢为我们的努力做出贡献的员工、客户和合作伙伴。</p>
          <p>&nbsp;</p>

          <hr>
          <h2 align="center"><strong>当地社区活动 - Crane Cares</strong></h2>
          <ul>
            <li><p class="gt-block"><img alt="为无家可归者做饭" class="align_right"
                                         src="https://www.craneww.com/site/assets/files/1026/img_3339.320x0-is.jpg"
                                         width="320">Crane Worldwide Logistics 在 <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E8%8D%B7%E5%85%B0%E4%BA%BA/%E9%98%BF%E5%A7%86%E6%96%AF%E7%89%B9%E4%B8%B9/">阿姆斯特丹</a>
            </strong>最近与救世军合作，在当地日间中心烹饪和分发食物。 对于所有参与者来说，这都是一次令人谦卑的经历。 “通过参加这个
              Crane Cares 活动，我们都意识到我们盘子里有足够的食物是多么幸运”。</p></li>
            <li><p class="gt-block">起重机全球 <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E8%81%94%E5%90%88%E7%8E%8B%E5%9B%BD/%E4%BC%A6%E6%95%A6%E5%B8%8C%E6%80%9D%E7%BD%97/">伦敦希思罗机场</a></strong>&nbsp;最近发起了一项活动，以筹集资金来赞助一间家庭房
              <a href="https://rmhc.org.uk/">麦当劳叔叔之家英国</a>. 抽奖、无声拍卖和有趣的游戏筹集了超过 10,000
              英镑，用于赞助我们的同事在当地医院接受治疗时所住的房间。 该团队的一名成员还举办了一场半程马拉松比赛，以补充筹款活动。
            </p></li>
            <li><p class="gt-block">起重机全球 <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/">澳大利亚</a></strong>&nbsp;已加入赞助珀斯当地的
              AFL 足球俱乐部，以开发土著跳投。 Ocean Ridge 业余足球俱乐部“老鹰队”将在所有本土比赛和客场比赛中穿着这件套头衫。
              该设计来自一群土著玩家和当地长者，基于当地的历史。</p></li>
            <li><p class="gt-block">我们的Crane Cares团队成员 <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E7%BE%8E%E5%9B%BD/%E4%BA%9A%E7%89%B9%E5%85%B0%E5%A4%A7/">亚特兰大</a></strong>
              定期在当地食品银行当志愿者。 活动包括分类不易腐烂的物品和准备分发给有需要家庭的包裹。&nbsp;</p></li>
            <li>Crane Worldwide团队 <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E5%A2%A8%E8%A5%BF%E5%93%A5/">Mexico</a></strong>
              最近，他参观了当地的养老院，在玩游戏，进行瑜伽，跳舞和向老人分发礼物方面发挥了领导作用。&nbsp;
            </li>
            <li><img alt="有支票的一群人" class="align_right"
                     src="https://www.craneww.com/site/assets/files/1026/microsoftteams-image_27_1.280x0-is.png"
                     width="280">In <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E6%96%B0%E5%8A%A0%E5%9D%A1/">Singapore</a></strong>，我们的团队筹集资金支持巴淡岛当地一所小学的儿童，以支持他们的教育并支付父母因大流行而生病和失业而无法应付的学费。
            </li>
            <li>In<strong> <a
                href="/%E5%9C%B0%E7%82%B9/%E8%81%94%E5%90%88%E7%8E%8B%E5%9B%BD/%E4%BC%A6%E6%95%A6%E5%B8%8C%E6%80%9D%E7%BD%97/">伦敦</a></strong>，我们筹集了超过
              6,000 英镑，我们的员工在 Lord's Cricket Ground 过夜，为 <a href="https://ceosleepoutuk.com/">CEO 熬夜</a>
              为支持英国无家可归者的慈善机构筹集资金和提高认识。
            </li>
            <li>为了纪念全国心脏月，Crane Cares团队在 <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E7%BE%8E%E5%9B%BD/%E6%97%A7%E9%87%91%E5%B1%B1/">旧金山</a></strong>
              有一个“竹enny径”为当地的慈善机构筹集资金。
            </li>
          </ul>
          <ul>
            <li>在情人节那天，我们的团队 <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E7%BE%8E%E5%9B%BD/%E8%8A%9D%E5%8A%A0%E5%93%A5/">芝加哥</a></strong>
              参观了当地的养老院，向当地居民分发了手工制作的情人卡。&nbsp;
            </li>
            <li>In <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E8%81%94%E5%90%88%E7%8E%8B%E5%9B%BD/%E9%A6%99%E6%B8%AF%E4%BB%94-1/">香港仔</a></strong>，苏格兰，我们的物流专家通过组织烘焙义卖和抽奖活动为他们选择的当地慈善机构筹集了大量资金，奖品从小礼物到曼联门票不等！
              所有人都玩得很开心，我们很自豪能够以急需的资金支持我们当地的医院，以继续他们在当地社区的重要工作。
            </li>
            <li>In <strong><a
                href="/%E5%9C%B0%E7%82%B9/%E7%BE%8E%E5%9B%BD/%E4%BC%91%E6%96%AF%E6%95%A6/">休斯顿</a></strong>，在最近的全球销售会议上，该团队支持了一个名为
              <a href="https://lucilles1913.org/">露西尔的 1913 年</a>&nbsp;建设蔬菜园，种植新鲜农产品和打包餐食，以便在当地运送。
              Lucille 的使命是建立一个垂直整合的生态系统，以应对粮食不安全和浪费；
              在传统上资源匮乏的社区创造培训和就业机会，并使社区能够通过食物发现自给自足的生计。&nbsp;
            </li>
          </ul>
          <p><img alt="Crane Worldwide Logistics 的慈善工作"
                  src="https://www.craneww.com/site/assets/files/1026/microsoftteams-image_29.1600x0-is.png"
                  width="1600">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p></div>
      </div>
    </div>
    <quotation></quotation>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {communityData} from "@/assets/mock/about";
import Quotation from "@/components/quotation.vue";

export default {
  components: {Quotation, CommonBanner},
  data() {
    return {
      communityData
    }
  }
}
</script>

<style scoped>

</style>