<template>
  <div>
    <template v-if="$route.query.type === 'index'">
      <div class="section section_size_xl bg_primary-dark">
        <div class="section__container container">
          <div class="section__intro type type_inverted"><h1 class="c_white">{{
              serviceIndexData.bannerData.title
            }}</h1>
            <p class="text_lead">{{ serviceIndexData.bannerData.desc }}</p></div>
        </div>
        <img :src="serviceIndexData.bannerData.image" alt=""
             class="section__background" height="800" width="1200">
        <div class="section__screen bg_primary-dark"></div>
      </div>
      <div class="section section_size_lg">
        <div class="container">
          <div class="grid grid_size_lg grid_center">
            <div v-for="(item,index) in serviceIndexData.serviceList" :key="index"
                 class="grid__item size_6 size_4_sm size_3_md">
              <a
                  :href="item.path"
                  class="button_wrap d_block spacing text_align_center">
                <h3 class="button button_theme_brackets button_size_lg mx_auto">{{ item.name }}</h3>
                <p>{{ item.desc }}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>

    <air v-if="$route.query.type === 'air'"></air>
    <contract v-if="$route.query.type === 'contract'"></contract>
    <broker v-if="$route.query.type === 'broker'"></broker>
    <land v-if="$route.query.type === 'land'"></land>
    <sea v-if="$route.query.type === 'sea'"></sea>
    <project v-if="$route.query.type === 'project'"></project>
    <train v-if="$route.query.type === 'train'"></train>
    <freight v-if="$route.query.type === 'freight'"></freight>
    <business v-if="$route.query.type === 'business'"></business>
    <manage v-if="$route.query.type === 'manage'"></manage>
    <trade v-if="$route.query.type === 'trade'"></trade>
    <added v-if="$route.query.type === 'added'"></added>
    <battery v-if="$route.query.type === 'battery'"></battery>



    <quotation></quotation>
  </div>
</template>

<script>
import {serviceIndexData} from "@/assets/mock/service";
import Quotation from "@/components/quotation.vue";
import Air from "@/views/service/components/air.vue";
import Contract from "@/views/service/components/contract.vue";
import Broker from "@/views/service/components/broker.vue";
import Land from "@/views/service/components/land.vue";
import Sea from "@/views/service/components/sea.vue";
import Project from "@/views/service/components/project.vue";
import Train from "@/views/service/components/train.vue";
import Freight from "@/views/service/components/freight.vue";
import Business from "@/views/service/components/business.vue";
import Manage from "@/views/service/components/manage.vue";
import Trade from "@/views/service/components/trade.vue";
import Added from "@/views/service/components/added.vue";
import Battery from "@/views/service/components/battery.vue";

export default {
  name: "index",
  components: {Land, Broker, Contract, Air, Quotation, Sea, Project, Train, Freight, Business, Manage,Trade,Added,Battery},
  data() {
    return {
      serviceIndexData,
    }
  }
}
</script>

<style scoped>

</style>