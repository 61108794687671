<template>
  <div class="section bg_shade">
    <div class="container">
      <div class="grid grid_size_xl grid_md align-items_center">
        <div class="grid__item"><img
            :src="infoData.image" alt=""
            class="radius" height="500" width="800"></div>
        <div class="grid__item type"><h2>{{ infoData.title }}</h2>
          <p class="text_lead">
            {{ infoData.desc }}</p>
          <div><a class="button button_size_lg button_color_secondary" href="/quotation">联系我们</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "serviceIntro",
  props: ['infoData']
}
</script>

<style scoped>

</style>