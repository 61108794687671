<template>
  <div>

    <div class="section by">
      <div class="container container_size_sm spacing_xl">
        <div class="grid grid_md">
          <div class="grid__item size_8 spacing_xl">
            <h1 class="h1 text_align_center">贸易咨询通告</h1>
            <div class="spacing_xl">
              <div v-for="(item,index) in tradeData.list" :key="index" class="media align-items_center">
                <div class="media__body">
                  <div>
                    <p>{{ item.time }}</p>
                    <h2 class="h3">
                      <a :href="'/newsDetail?type=trade&id='+item.id">{{ item.title }}</a>
                    </h2>
                  </div>
                  <p>{{ item.desc }}</p>
                  <a :href="'/newsDetail?type=trade&id='+item.id" class="button button_outline_secondary">
                    <span>查看更多</span>
                    <svg class="icon" role="img">
                      <use xlink:href="#icon-arrow-right"></use>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="grid__item size_4">
            <div class="card card_size_sm bg_primary-dark">
              <div class="card__body c_white">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item c_white type"><h4 class="h5 c_white">注册我们的时事通讯</h4>
                    <p class="text_size_sm">随时了解所有最新的行业洞察新闻。</p>
                    <div><a class="button button_size_sm button_color_secondary"
                            href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/">立即注册！</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="resources-list card card_size_sm bg_primary-white">-->
            <!--              <div class="card__body">-->
            <!--                <div class="grid grid_md m_0 align-items_center">-->
            <!--                  <div class="grid__item type text_size_sm"><h3><strong>资源</strong></h3>-->
            <!--                    <p><img alt="" src="https://www.craneww.com/site/assets/files/2712/international_trade.320x0-is.jpg"-->
            <!--                            width="320"></p>-->
            <!--                    <p class="gt-block"><strong><a-->
            <!--                        href="/%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88/%E8%B4%B8%E6%98%93%E5%92%A8%E8%AF%A2/">贸易咨询服务</a></strong>-->
            <!--                    </p>-->
            <!--                    <p class="gt-block"><strong><a-->
            <!--                        href="https://craneww.com/knowledge-center/trade-advisory-notices/section-301/">第 301 条排除条款-->
            <!--                      PDF</a>&nbsp;</strong></p>-->
            <!--                    <p class="gt-block"><strong><a-->
            <!--                        href="https://craneww.com/knowledge-center/trade-advisory-notices/usmca-certificate-of-origin/">原产地证书模板</a></strong>-->
            <!--                    </p>-->
            <!--                    <p class="gt-block"><strong><a-->
            <!--                        href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E8%8B%B1%E5%9B%BD%E8%84%B1%E6%AC%A7%E6%9B%B4%E6%96%B0/">英国脱欧门户网站</a>&nbsp;</strong>-->
            <!--                    </p>-->
            <!--                    <p class="gt-block"><a-->
            <!--                        href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E5%9B%BD%E9%99%85%E8%B4%B8%E6%98%93%E6%9C%AF%E8%AF%AD/"><strong>国际贸易术语概览</strong></a>-->
            <!--                    </p>-->
            <!--                    <p class="gt-block"><a-->
            <!--                        href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/"><strong>行业新闻</strong></a>-->
            <!--                    </p>-->
            <!--                    <p class="gt-block"><a-->
            <!--                        href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E9%9B%86%E8%A3%85%E7%AE%B1%E5%B0%BA%E5%AF%B8/"><strong>集装箱尺寸</strong></a>-->
            <!--                    </p>-->
            <!--                    <p class="gt-block"><a-->
            <!--                        href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E5%8D%95%E4%BD%8D%E8%B4%9F%E8%BD%BD%E8%AE%BE%E5%A4%87-ul/"><strong>单位负载设备尺寸</strong></a>-->
            <!--                    </p>-->
            <!--                    <p class="gt-block"><a href="/%E5%9C%B0%E7%82%B9/"><strong>起重机全球位置</strong></a></p>-->
            <!--                    <p>&nbsp;</p></div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="card card_size_sm bg_primary-dark">-->
            <!--              <div class="card__body c_white">-->
            <!--                <div class="grid grid_md m_0 align-items_center">-->
            <!--                  <div class="grid__item c_white type"><h4 class="h5 c_white">收听我们最新的网络研讨会</h4>-->
            <!--                    <p class="text_size_sm">我们所有最新的行业洞察网络研讨会都可供您使用！</p>-->
            <!--                    <div><a class="button button_size_sm button_color_secondary"-->
            <!--                            href="https://craneww.com/knowledge-center/webinars/">收听</a></div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="resources-list card card_size_sm bg_primary-white">
              <div class="card__body">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item type text_size_sm"><h2>您知道吗？</h2>
                    <p>我们的播客系列“鹤咖啡”可供您使用。</p>
                    <p class="gt-block">您可以在线收听 <a
                        href="/news?type=podcast">这里。</a></p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {tradeData} from "@/assets/mock/news";

export default {
  components: {},
  data() {
    return {
      tradeData
    }
  }
}
</script>

<style scoped>

</style>