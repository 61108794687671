<template>
  <div>
    <common-banner :banner-data="{title:'服务条款'}"></common-banner>
    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><p class="gt-block"><strong>1.定义</strong></p>
          <p>&nbsp;</p>
          <p>“运输”是指承运人就货物承担的此处所述的全部或任何部分操作和服务。</p>
          <p>“承运人”是指 Crane Worldwide Logistics LLC 或其附属公司，代表其签发了与货物运输有关的提单。
            如果货物在运输的海上部分丢失、损坏或延误，并且船东或光船承租人寻求根据 46 US Code §§ 181 et seq 限制其责任。
            或根据另一个国家的类似全球时效制度，只有船东或光船承租人是“承运人”。</p>
          <p>“集装箱”包括用于货物运输的任何集装箱、拖车、可运输罐、平板或托盘或任何类似物品。</p>
          <p>&nbsp;</p>
          <p>“危险品”是指任何可能或被合理地认为对任何运输方式或处理或储存场所构成危险的任何货物，无论该货物被任何当局确定为危险或未被如此确定。
            危险货物包括但不限于在任何法规、法规或国际海事组织的国际海运危险货物规则中列为危险货物的货物。</p>
          <p>“货物”是指适用的提单正面描述的货物，如果货物装在由货方或代表货方提供或提供的集装箱中，则还包括集装箱。</p>
          <p>&nbsp;</p>
          <p>
            “商户”包括托运人、收货人、发货人、收货人、与货物运输有关的提单持有人以及对货物拥有当前或未来利益的任何人或代表任何人行事的任何人上述人员中。</p>
          <p>
            “包裹”是由托运人组成或为托运人交付并委托给承运人的部分或完全覆盖或包含的货物的最大单个单元，包括托盘单元和由托运人或其代表填充和密封的每个集装箱，尽管托运人可能已在适用的提单上提供了对此类密封集装箱内装物的描述。</p>
          <p>“特殊运输”是指通风、加热或冷藏运输或任何其他需要特别注意的运输。</p>
          <p>“分包商”应包括直接和间接代理人、分包商及其各自的受雇人和代理人。</p>
          <p>“船舶”包括用于执行运输或出于任何目的装载货物的任何船舶、船舶、船只、驳船、车辆和其他运输工具。</p>
          <p class="gt-block"><strong>2. 多式联运或港到港提单</strong></p>
          <p>为货物签发的提单不得转让，除非是“定单”，在这种情况下，提单可转让并构成货物的所有权，持有人有权接收或转让货物.
            如果此类提单不可转让，则构成多式联运或港到港不可转让的海运/空运提单，承运人没有义务在交付货物前要求交出此类提单。
            如果此类提单是可转让的，则必须在交付货物时交回所有正确背书的正本提单。
            如果接收货物的人希望交出的提单数量少于所有签发的正本提单，并且承运人同意交付少于所有正本的提单，则接收货物的人同意赔偿承运人免受承运人可能遭受的所有损失有责任因交付货物而无需交出所有正本提单而付款。
            如交出一份正本提单，其他正本提单将作废。 在任何情况下，此类可转让提单将在签发六个月后失效。</p>
          <p class="gt-block"><strong>3.效果</strong></p>
          <p>如果提单中、提单上或提供的与所涉货物运输有关的条款和条件因任何原因不适用，或未提供此类条款和条件，或双方之间的合同条款与货物运输有关的各方不以任何理由适用，这些条款和条件将受控制。
            在接受与货物有关的提单时，托运人为自己并代表每个商户行事。
            货物的运输受制于或由联邦海事委员会或其他监管机构存档或公布或要求存档或公布的承运人关税的所有条款和规定（视情况而定）部分运输（“运价”）。
            关税的条款，包括但不限于与运费和其他应付商户补偿相关的关税的适用条款，均包含在本条款中。
            可根据要求向承运人或其代表索取适用运价表的相关规定。
            如果本文件与适用的运价表不一致，除法律另有规定外，以本文件为准。</p>
          <p class="gt-block"><strong>4. 所有权或所有权的保证</strong></p>
          <p>商户保证，在同意本协议条款时，它是拥有或有权拥有货物的人或有权拥有货物的人。</p>
          <p class="gt-block"><strong>5. 分包、合并和可能提出索赔的当事人</strong></p>
          <p>6.1 双方同意部分运输或全部运输或相关服务可由分包商执行。
            承运人可以根据该承运人标准格式提单的条款和条件聘请任何承运人，该提单对货方具有约束力。</p>
          <p>5.2
            承运人有权将货物与其他货物拼箱，并通过与任何人就拼箱货物的移动（包括全部或任何部分）的任何条款与任何人签订合同，促使履行全部或任何部分运输。商品。</p>
          <p>&nbsp;</p>
          <p>5.3 如果货物在船舶上丢失、损坏或延误，并且船舶所有人或光船承租人启动了本文件第 1
            条中承运人定义中提到的限制程序，索赔或诉讼只能针对该船东或光船承租人。 在所有其他情况下，只能向 Carrier
            提出索赔或诉讼。
            如果对除承运人以外的任何参与承运的任何人提出索赔或诉讼，该方有权获得本文件授予或提供的所有例外、豁免、抗辩、豁免、责任限制、特权和条件，任何适用的关税，以及管辖它或通过引用并入其中的任何法律，就好像受保护方是本文件的一方一样。
            这些受保护方包括但不限于分包商、装卸工人、码头、监视服务、参与的陆、空或海运承运人及其直接或间接分包商。
            这些方中的每一方都是本文件的第三方受益人。</p>
          <p class="gt-block"><strong>6. 承运人的首要条款和责任</strong></p>
          <p>6.1 条款至高无上。 本文件所证明的运输合同在任何海上运输过程中均受美国海上货物运输法案 46 USC App 的法律效力管辖。
            §§ 1300 及以下。 (COGSA)，其应被视为并入本协议，且本协议所包含的任何内容均不得视为承运人放弃其在 COGSA
            下的任何权利或豁免权或增加其在 COGSA 下的任何责任。 除了航空运输和本协议规定的情况外，COGSA
            也通过引用纳入运输合同的条款，无论货物是在甲板上还是在甲板下运输，无论运输是否在美国对外贸易中，在美国港口之间，或在非美国港口之间，在货物装上货物之前和/或货物从船上卸下之后，以及在货物装船之前的整个过程中</p>
          <p>货物由承运人保管或负责履行本协议项下的运输，无论是作为承运人、受托人、装卸工还是码头运营商。 COGSA
            不得通过引用纳入运输合同，该合同受 12 年 1929 月 XNUMX
            日在华沙签署的《统一国际航空运输某些规则的统一公约》（“华沙公约”）的法律效力，以及任何对其具有法律效力的修正案。
            与华沙公约有关的规则应适用于受其管辖的国际货物运输。
            本文件中规定的所有权利、特权、抗辩、责任豁免和限制均适用于针对承运人因货物损坏或其他与货物有关的损失而提起的任何诉讼，无论该诉讼是否以合同为依据，侵权，或其他。</p>
          <p>&nbsp;</p>
          <p>6.2 责任限制。 以托运人如下规定的更高价值声明为前提，承运人对货物灭失或损坏的责任应限制如下：对于在受该法约束的承运人的任何空运部分期间发生的灭失或损坏华沙公约，承运人的责任应限于每公斤
            20.00 美元，或在受《华沙公约》（经蒙特利尔议定书第 4 号修正）的法律约束的运输期间，每公斤为 17 国际货币基金组织的特别提款权
            (SDR) ; 对于在受 COGSA 管辖的运输的任何部分期间发生的损失或损坏，无论是根据法律效力还是根据本协议规定的合并，承运人的责任应限于每件包裹
            500 美元，或者对于未以包裹运输的货物，按惯例运费单元; 对于在此类限制条款不适用的运输任何部分发生的损失或损坏，承运人的责任应限于每磅
            0.50 美元，或任何适用法律可能规定的较低金额。 就本协议而言，货物在装载至承运人的集装箱时应被视为已装船，无论该集装箱是否已装载至船舶。
            承运人还应有权享受任何国家的法律和法规以及任何分包商的合同中可能适用于货物装船前或卸船后的条款的全部利益，包括其中规定的所有抗辩和免责条款，以及任何低于此处规定的限制。
            托运人或商户可以在允许的范围内避免这些限制或适用法律施加的任何其他限制，通过声明更高的价值每公斤、包裹、惯常货运单位或整批货物（视情况而定），插入此类更高的价值与此类运输相关的提单上的价值，并支付更高的运费。
            在任何情况下，承运人均不对货物的特殊、附带或后果性损害、利润或收入损失或适销性损失负责，无论承运人是否知道可能发生此类损害或此类损害是否可合理预见。
            货方应就任何第三方索赔向承运人作出赔偿，该索赔要求或试图向承运人强加除本协议规定以外的与货物有关的任何责任，无论是否因承运人的疏忽而引起。</p>
          <p>6.3 延迟。 除非双方另有书面约定，承运人不对延误承担责任。</p>
          <p>&nbsp;</p>
          <p>6.4 例外。
            承运人不对在任何时间发生的任何损失、损坏、延迟或未能履行本协议承担责任，包括在装船之前或卸货之后或在任何运输过程中，由发生和/或威胁和/或以下一项或多项的影响：天灾、战争行为、恐怖行为或威胁、不可抗力、检疫限制、禁运、公敌行为、小偷、海盗、袭击小偷、逮捕或限制王子、统治者或人员、根据法律程序被扣押、商人、其代理人或代表的作为或不作为、罢工或停工、或因任何原因（局部或全面）、骚乱或民事骚乱、船长的作为、疏忽或过失而停工或限制劳工，海员、引航员或承运人在船舶的航行或管理中的仆人、船坞、结冰、爆炸、碰撞、搁浅、危险、海上或其他通航水域的危险或事故、体积或重量的浪费，或任何由于货物的固有缺陷、质量或缺陷、包装不充分、标记不充分或不充分、锅炉爆裂、轴断裂或船体、设备、机械、锚链或线路的任何潜在缺陷、不适航而引起的其他损失或损坏除非由于承运人未尽职尽责使船舶适航或适当配备人员、装备和供应，并使货舱、冷藏室和冷却室以及船舶的所有其他部分适合和安全接收，货物的运输和保存，挽救或试图挽救海上的生命或财产，或提供此类服务的任何偏差，船舶的损失或物质损坏，或承运人无法控制的任何类似或不同的原因。</p>
          <p>&nbsp;</p>
          <p>6.5 转让和代位求偿。 商家同意，作为承运人就任何丢失、损坏或延误的货物向商家付款的对价，商家将被视为已将其全部索赔和诉讼理由转让给承运人，并且承运人将被转让并代位为承运人的权利。
            商户同意执行承运人要求的文件，以作为受让人和/或代位权人起诉第三方，并在承运人针对其他方提起的任何诉讼中全力配合。</p>
          <p>6.6 从价。 如果商户申报的价值高于本协议规定的限额，货物的任何部分灭失或损坏应按申报价值按比例调整。
            该值不得超过实际值。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>7. 交货状况良好的证据</strong></p>
          <p>
            有权在没有投诉或丢失或损坏通知的情况下以适用的方式和时间段内收到或交付货物的人，应作为货物已完好交付的初步证据条件并按照本文件。</p>
          <p class="gt-block"><strong>8. 损失或损坏的投诉和通知，以及时效</strong></p>
          <p>8.1 航空运输。 对于航空运输，任何关于损失或损坏的投诉必须由有权获得货物的人在发现损坏或延误后立即以书面形式向承运人提出，或至少在以下时间段内提出：如果发生损坏，迟于收到货物后十四
            (14) 天； 在延误的情况下，不迟于应交付货物之日起二十一 (21)
            天，前提是：如果《华沙公约》或其任何修正案规定了提交投诉的任何其他时间期限，具有法律效力，则应适用此类时间段。
            除非发生欺诈，否则不得针对货物的损失或损坏或延误向承运人提起诉讼，除非已按照上述时间段提出此类投诉。
            在任何情况下，如果在货物交付后两 (2)
            年内未提起诉讼，或者在延迟索赔的情况下，在交付本应发生的日期内提起诉讼，则对承运人的任何损害赔偿权利都将失效。</p>
          <p>&nbsp;</p>
          <p>8.2 所有其他运输。 对于所有其他运输，在 COGSA 不具有法律效力的情况下，任何运输期间发生的对货物丢失或损坏或延误的任何索赔必须在货物交付之日起九
            (9) 个月内送达承运人交付或应该交付。 如果未能在九 (9) 个月的期限内提出索赔，商家将无法在以后提起诉讼或其他诉讼程序以弥补损失、损害或延误。
            如果损失或损坏是在美国部分搬迁期间造成的，并且如果索赔是在九 (9) 个月内提出的，则必须在承运人拒绝索赔后的两
            (2) 年内提出诉讼，无论是全部还是部分，否则索赔将被超时。 商家将赔偿承运人因商家未能及时发出通知或未能及时向负责的第三方提起诉讼而遭受的任何损害。
            在 COGSA 依法适用的情况下，在任何运输过程中发生的任何货物损失或损坏或延误索赔，应通过向承运人或其代理人发出书面损失或损坏通知，或在货物收据上背书的方式提出。
            所述通知必须包括损失或损坏的一般性质，并可在收货人提供的货物收据上背书。
            如果损失或损坏很明显，则必须在将货物移至根据本文件有权交付货物的人保管之前或之时发出上述通知；
            如果丢失或损坏不明显，则必须在交货后连续三 (3) 天内发出上述通知。 如果 COGSA
            在损失、损坏或延迟发生时受法律效力管辖，则无需在货物交付之日或应交付之日起九 (9) 个月内提出索赔。
            但是，诉讼必须在货物交付或应该交付之日起一 (1) 年内开始。 未能在一年内提起诉讼将消除不合时宜的诉讼原因。</p>
          <p>&nbsp;</p>
          <p>8.3 如果在承运人收到要装运的货物之日起 180 天内未向承运人提出，则所有多收费索赔将被视为放弃。
            尽管有上述规定，如果账户逾期超过 60 天，承运人可能会针对最早的逾期金额应用多付款项或欠商家的其他贷项。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>9.火</strong></p>
          <p>
            承运人不对因任何时间或任何地点发生的火灾引起或导致的货物损失或损坏承担责任，除非是由承运人或其任何受雇人、代理人或分包商的实际过失或隐私造成的。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>10. 未按承运人包装的集装箱</strong></p>
          <p>如果集装箱尚未包装或填充，或者货物（无论是否装在集装箱中）尚未准备或包装用于由承运人或代表承运人运输，则本条款的规定应适用。
            承运人不对内容的损失或损害承担责任，如果该等损失、损害、责任或费用是由以下原因造成的，则商家应赔偿承运人免于承运人遭受的任何损失、损害、责任或费用：(a)容器已被包装或装满；
            (b) 货物不适合用集装箱运输或进口或在目的地交付； (c) 由承运人或代表承运人提供的任何集装箱的不适合或有缺陷的状况，(i)
            承运人在没有尽职调查以使集装箱合理地适合所需用途的情况下发生， (ii) 在集装箱装箱或装货之时或之前，经货方的合理检查后，显而易见；
            (d) 并非由承运人或代表承运人提供的任何集装箱不适合或存在缺陷； (e)
            货物缺乏适当的描述或准备或包装以便运输。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>11. 可选配载</strong></p>
          <p>货物可能由承运人包装在集装箱或用于合并货物的类似运输物品中。
            封闭式集装箱中的货物，包括但不限于带有防水油布顶部的集装箱，无论是由托运人还是承运人包装，都可以在甲板上运输。
            集装箱和货物在船上的积载位置由承运人或商户以外的人决定。 COGSA 将管理集装箱甲板货物，就好像货物是在甲板下运输一样。
            未装在封闭式集装箱中的货物可积载在通常用于货物运输的任何有盖但不一定是封闭的空间中，并且如此运输的此类货物就所有目的而言均应视为积载在甲板下。
            如果货物的这种积载是习惯性的或由任何当局强制执行，则未装在集装箱中的货物可以积载在甲板上。
            如果货物不习惯甲板积载，经商户同意，可将货物积载在甲板上。 在这种情况下，适用的提单的正面将被注释以表明甲板上的积载。
            COGSA 的所有抗辩和限制，包括但不限于每件包裹的责任限制，均通过引用并入本文中关于甲板货物的内容。 归于 COGSA
            的举证责任规则不适用于此类甲板运输。
            就货物的损失、损坏或延误提出索赔的人必须证明造成损失、损坏或延误的具体违反合同的行为。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>12. 特殊容器或运输</strong></p>
          <p>商户保证，除非要求特殊运输并支付费用，否则货物适合在不通风、不加热、不冷藏的集装箱或其他装载空间中运输。
            除非书面同意并考虑到更高的运费，承运人不得承诺在冷藏、加热、隔热、通风或任何其他特殊集装箱或其他积载空间中运输货物，或运输特殊货物。由商户或代表商户包装的容器。
            承运人将仅将此类货物或集装箱分别视为普通货物或干货集装箱，除非适用的提单上注明特殊安排且所有特殊运费均已支付。
            承运人不对因专用集装箱或其设备的潜在缺陷造成的任何货物损失或损坏负责，也不保证其适用性或性能。
            承运人不负责控制和照管此类集装箱的操作设备（当承运人未实际拥有时）。
            如果货方要求的特定温度范围在适用的提单上注明，承运人将根据要求的温度范围设置恒温控制。
            双方同意冷藏集装箱或其他冷藏空间在解冻时以及从各种运输工具或储存地点移出和移到各种运输工具或储存地点时，温度会发生变化，并且加热的集装箱在移出和移到各种运输工具或储存地点时的温度可能会发生变化。存储位置。
            如果内容物已由商户或代表商户包装，商户应预冷或预热货物并正确存放货物并正确设置恒温控制。
            承运人不对因货方未能遵守此类义务而导致的货物灭失或损坏负责。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>13. 货物检验</strong></p>
          <p>承运人有权但无义务随时打开任何包裹或容器并检查其内容物。
            如果内容物或其任何部分似乎无法安全或适当地运输或进一步运输，无论是完全或不产生任何额外费用，或对此类包裹或容器或其内容物或其任何部分采取任何措施，承运人可以放弃运输货物和/或采取任何措施和/或产生任何合理的额外费用来配合货物、携带或继续运输或将货物存放在岸上或漂浮的有盖或露天的任何地方，该存储应被视为构成本文件项下的到期交付。
            货方应赔偿承运方因此产生的任何合理的额外费用。
            承运人在行使本条款所包含的自由时不承担采取任何特定措施的任何义务，并且承运人不对因本条款项下的任何行动或不采取行动而导致的任何损失、损害或延误承担任何责任。
            承运人有权检查货物和/或承运人对货物的任何检查不会减少本协议第 15、20、21 和 23 条规定的商户保证。
            承运人完全依赖商户不运送任何危险品。
            除非明确同意，承运人没有义务验证或向商户报告有关货物的任何信息，包括重量、数量、状况、质量或是否符合任何合同或法规要求。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>14. 货物描述</strong></p>
          <p>与相关货物运输相关的适用提单仅构成对交付给承运人的包裹或其他单元的外部条件以及承运人可见的包裹或其他单元的数量的收据。
            它不能作为承运人在交付给承运人时不易且合理地看到的包裹或物品数量的收据。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>15. 商家的保证和责任</strong></p>
          <p>商户（而非承运人）在与相关货物运输相关的适用提单上提供了货物描述以及托运人/出口商和收货人的名称和地址。
            商户保证货物或其包装的描述和标记、数量、数量、重量以及发货人/出口商和收货人的名称和地址准确无误，并符合有关当局的所有规定，包括但不仅限于美国海关与边境保护局
            (“CBP”) 等各机构要求的危险或危险货物描述和提前舱单。商户保证将在至少七十二 (72)
            小时前向承运人提供此信息船舶装载，并承认承运人可以拒绝装载任何货物的信息：(i) 不符合有关当局的所有此类规定； 或
            (ii) 未在船舶装载前至少七十二 (72) 小时提供给承运人。
            货方同意就承运人因以下原因未装载的任何货物而产生的任何及所有费用（包括但不限于检验、储存和/或交付成本）向承运方作出赔偿：(i)
            货方的未能提供符合有关当局所有此类规定的信息； (ii) 货方未能在船舶装载前至少七十二 (72) 小时向承运人提供信息；
            (iii) CBP 或其他相关当局的指示（无论信息是否符合适用法规或在船舶装载前七十二 (72) 小时提供）。
            商户还同意就因违反本保证和责任而全部或部分造成的任何其他损害（包括 CBP
            或其他相关当局施加的任何罚款、违约金或其他制裁）完全赔偿承运人。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>16. 运费和费用</strong></p>
          <p>16.1 运费应由承运人选择支付，基于以下任何一项：总进货重量或测量； 总排放重量或测量值； 从价； 按包裹或一次性付款；
            或承运人适用关税中规定的任何其他适用费率。 运费可根据货方提供的货物描述计算，但承运人可随时对货物进行称重、测量和估价，并在发现货方描述有误并增加运费时打开包装检查内容。应付。
            商户和货物应负责对货物进行检查、称重、测量、熏蒸和估价所产生的任何额外运费和费用。
            商户、收货人、持有人、货物所有人及其委托人应对承运人承担包括预付款在内的所有运费和费用的连带责任，并应在任何转介收取或提起诉讼时因承运人在承运人追回后，支付催收和诉讼费用，包括合理的律师费。
            无论与所涉货物运输有关的适用提单的正面是否标记为“预付”或“运费预付”，只要运费和费用仍未支付，本规定均适用。
            如果承运人同意“运费到付”条款，并在提单上注明，承运人作为托运人的代理人收取运费和费用，托收费用由商户承担，承运人不承担托收风险和上述如果承运人无法应要求向任何一方收取运费，则双方仍有义务支付所有运费和费用，而无需诉诸法律程序。</p>
          <p>&nbsp;</p>
          <p>16.2
            到与所涉货物运输相关的适用提单上指定的交货地点的全额运费以及针对货物的所有预付费用应视为承运人或其分包商在收到货物后完全赚取，无论是运费或费用应预付或声明或打算预付或在卸货港或目的地港或随后收取，承运人应绝对有权获得所有运费和费用，无论是否实际支付，并有权接收和保留它们在任何情况下，货物丢失或未丢失，或航程改变、破裂、受挫或放弃。
            无论货物是否损坏或丢失，或者包裹或惯用的货运单位是空的还是部分空的，都应支付全额运费。
            如果货物被拒绝入境或在目的地无人认领，商户应负责将货物退回原产地或处置和/或销毁货物的所有运费和费用。</p>
          <p>&nbsp;</p>
          <p>16.3 运费应在承运人收到货物时被视为已赚取，无论运费是打算预付还是在目的地收取。 12% 的利息自运费和费用到期日起计算。
            向货运代理、经纪人或除直接向承运人以外的任何人支付运费不应被视为向承运人付款。
            所有运费和费用均应以本文件中指定的货币全额支付，不得进行任何抵消、反索赔或扣减运费的支付应在本协议下到期。
            运费或费用或货物分类中的任何错误都可以更正，如果在更正时运费或费用更高，承运人可能会收取确定货物正确分类的额外金额和费用，更正运费并收取正确的运费。
            向任何货运代理、经纪人或其他第三方（承运人的代理人除外）付款不应被视为向承运人付款。</p>
          <p>&nbsp;</p>
          <p>16.4 承运人的运价表中规定的承运人发生的增加的费用可能会被征收附加费。
            此类费用包括但不限于燃油调整系数、货币调整系数、旺季、港口拥堵或其他目的地附加费、战争风险保险费以及支付分包商的其他费用或一般费率增加所需的费用。</p>
          <p>16.5 货方和对物货物应就所有运费、滞期费、共同海损、救助和其他费用，包括但不限于法庭费用、费用和收取款项所产生的合理律师费，对承运人承担连带责任。本文件或本文件的任何初步合同项下的到期承运人。
            商户同意按共同海损理算师要求的帐户支付任何款项，而不考虑商户对承运人享有的共同海损权利的看法。
            向货运代理、经纪人或除承运人或其授权代理人以外的任何人支付海运费和费用，不应视为向承运人支付的款项，付款人应自行承担风险。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>17. 留置权、遗弃货物、承运人持有或出售货物的权利</strong></p>
          <p>17.1
            承运人对货物享有留置权，该留置权应在交付后继续有效，包括所有运费、滞留运费、滞期费、损坏、损失、成本和费用、共同海损分摊款、费用以及任何其他应付或应收的款项或为货方和本合同的任何初步合同以及要求在先前账户上交付货物或已交付货物的人欠承运人的任何款项，无论是否与货物有关或是否以不同的提单运输，以及收回相同的成本和费用，并且可以保留货物直到所有此类费用和成本完全结清，或者私下或通过公开拍卖出售货物，恕不另行通知。
            如果销售收益不能弥补到期金额和产生的费用，承运人可以向货方追偿差额。</p>
          <p>&nbsp;</p>
          <p>17.2 如果货物在收到通知后不超过十四 (14)
            天的合理时间后无人认领，或在任何适用的仓单或提单中规定的时间，或在承运人认为货物将变质、腐烂时或一文不值，承运人可自行决定并受其留置权约束，且承运人不承担任何责任，出售、放弃或以其他方式处置此类货物，完全由商家承担风险和费用，或由商家承担费用储存此类货物。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>18. 生锈、冷凝和类似条件</strong></p>
          <p>集装箱内的表面生锈、氧化或冷凝或由于潮湿导致的任何类似状况不属于承运人的责任，除非由于承运人未能提供适航集装箱而导致货方进行合理检查并不会透露该状况。
            钢材表面的锈蚀、氧化或冷凝或木材上的水分构成良好的秩序和状况，对于此类情况，与相关货物的运输相关的适用提单不会有任何例外，除非货方如此指示承运人在货物交付给承运人之前的合理时间内写信。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>19. 运输方式和路线</strong></p>
          <p>货物可能会通过多种运输方式以及每种方式中的几种不同方式运输。 承运人可以使用任何方式（包括但不限于一艘或多艘船只、卡车、火车和飞机）来执行运输。
            商家同意，承运人可以使用任何直接或间接路线，而无需向商家发出此类路线的通知。 运输也可能在不通知商户的情况下中断。
            如果承运人自行决定，情况证明是正当的，则承运人可以在任何地方销毁货物、放弃货物或卸载货物并申报货物已交付，风险和费用由商户承担。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>20. 危险、有害或有毒货物</strong></p>
          <p>具有易燃、易爆、腐蚀性、放射性、有毒、危险、不稳定或危险性质的货物将被正确识别、包装和以其他方式准备由商户运输。
            货方应以法律要求的形式和方式在包装和容器的外部清楚和永久地标记货物的性质，并应向承运人或有关当局提交法律或承运人要求的所有必要文件以进行运输此类商品。
            承运人可以自行选择接受或拒绝任何提供运输的危险品。 货方应向承运人发出适当和及时的书面警告，告知承运人将装运此类货物，并向承运人提供有关正确处理和保养此类货物的指示。
            未以书面形式向承运人充分披露其性质和特性的任何此类货物，可随时在任何地方降落、扔到船外、销毁或变得无害，承运人或其他托运人或收货人不承担任何责任。
            即使进行了此类披露，如果承运人自行决定认为此类货物将对船舶或其他运输工具或其他货物或人员构成或变得危险或有害，则保证对此类货物进行相同的处置。
            货方应就承运人发生的与货物有关或全部或部分由货物引起的所有费用、损失、损害、责任、罚款、民事处罚和费用（包括律师费）向承运人作出赔偿。
            货方同意赔偿承运人，即使货方不知道也没有理由知道所装货物的危险倾向。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>21. 遵守法规、税收</strong></p>
          <p>
            商户应遵守海关、港口和其他当局的所有法律、法规或要求，并应承担和支付对货物或任何船舶或其他运输货物的运输工具征收的所有关税、税款、罚款、关税、费用或损失，因此或因任何非法、不正确或不充分的货物描述、标记、编号或地址而招致或遭受，并应就此向承运人作出赔偿。
            商户应负责对货物征收的所有会费、关税、罚款、税款和收费，包括领事费。 如果货物被任何政府拒绝出口或进口，商户应承担货物的退货运费和费用。
            货方、收货人、货物持有人、货物所有人及其委托人应就所有索赔、罚款、罚金、损害、成本和其他可能因任何违约而发生或强加给承运人的金额向承运人进行连带和个别赔偿。适用提单中与所涉货物运输有关的任何规定或任何法定或监管要求。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>22. 通知和交付</strong></p>
          <p>
            当货物准备好交付时，承运人将通过与相关货物的运输相关的适用提单或随附的舱单通知被确定为通知方的一方或收货人。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>23. 承运人的集装箱</strong></p>
          <p>货方应对承运人的集装箱和其他设备在货方、其代理人或任何承运人（承运人除外）拥有或控制期间发生的任何损失或损坏承担全部责任并赔偿承运人。或代表商家。
            商户应及时将空集装箱退还给承运商或其分包商。
            承运人在任何情况下均不对承运人的集装箱或其内装物在处理过程中因承运人的集装箱或其内装物造成的任何其他人的财产损失或损坏或伤害而对承运人进行赔偿并使承运人免受损害，或在商户、其代理人或由商户或代表商户聘用的任何承运人（承运人除外）占有或控制期间。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>24. 双方责任冲突</strong></p>
          <p>
            如果载运货物的船舶因双方船舶的疏忽或过失与另一艘船舶相撞，货方向另一艘船舶收取货物灭失或损坏的费用，而另一艘船舶获得承运人支付的损害赔偿金，商户将偿还承运人的这笔费用。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>25. 一般平均值</strong></p>
          <p>共同海损应根据 1994 年约克/安特卫普规则（其中第 XXII
            条除外）在承运人可能选择的美国港口或地点以及上述规则未规定的事项进行调整、声明和结算，根据承运人指定港口的法律和惯例。
            与这种调整有关，外币支付应按作出日期的通行汇率兑换成美国的法定货币，外币索赔的货物灭失或损坏备抵应按当时的通行汇率折算。在港口或最终卸货地点的最后一天卸货。
            必须在交付货物之前提供一般协议或保证以及承运人可能要求的此类额外担保。
            承运人可能认为足以作为额外担保以支付货物以及任何救助和特殊费用的现金押金，在不损害双方的最终责任的情况下，应由货物、托运人或收货人支付送货前的承运人。
            商户同意支付共同海损理算师提出的任何和所有对帐户付款的要求。 此类押金应由承运人选择以美国法定货币支付。
            在航程开始之前或之后，由于任何原因，无论是否由于承运人或其分包商的疏忽而导致的事故、危险、损坏或灾难，承运人对此不承担任何责任通过法令、合同或其他方式向货物、托运人或收货人提供的货物、托运人和收货人应与共同海损承运人共同承担共同海损性质的任何牺牲、损失或费用，这些牺牲、损失或费用可能产生或产生的费用，并应支付与货物有关的救助、一般和特殊费用。
            如果救助船由运输货物的承运人或其他水上承运人拥有和经营，则救助金应像该救助船属于第三方一样全额支付。
            货方指定承运人在任何救助程序中代表货物行事，除非货方安排单独代表。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>26. 整个协议和可分割性</strong></p>
          <p>根据本文件第 3 条的条款，本文件包含双方就相关货物的运输达成的完整协议。 Carrier
            的任何受雇人或代理人均无权终止、放弃或更改本文件的任何条款，除非此类终止、放弃或更改以书面形式并由 Carrier
            以书面形式特别授权或批准。
            本协议规定的条款是可分割的，如果本协议的任何部分或条款被认定为无效，则此类持有不影响本协议任何其他部分或条款的有效性或可执行性。</p>
          <p>&nbsp;</p>
          <p class="gt-block"><strong>27。 治理法律和管辖区</strong></p>
          <p>本文件应受美国联邦法律管辖，或者，如果联邦法律不适用，则受德克萨斯州法律管辖，尽管该法律有法律选择规则。
            由本文件引起的所有索赔或争议或问题，包括与责任限制有关的问题，应由美国德克萨斯州南区地方法院裁决，该法院对因本文件引起的所有争议具有专属管辖权，排除任何和所有其他法院的管辖权。
            如果美国德克萨斯州南区地方法院对争议没有标的管辖权，争议将由德克萨斯州哈里斯县自治市内的德克萨斯州法院裁决。
            本协议项下的所有索赔都必须向 Carrier（法律部门，Crane Worldwide Logistics, 1500 Rankin Road, Houston, Texas
            77073）提出。</p></div>
      </div>
    </div>

    <quotation></quotation>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import Quotation from "@/components/quotation.vue";

export default {
  name: "serive",
  components: {Quotation, CommonBanner}
}
</script>

<style scoped>

</style>