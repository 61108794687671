<template>
  <div>
    <common-banner :bannerData="lifeData.bannerData"></common-banner>


    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>您需要生命科学后勤帮助吗？</h1>
            <p>我们对关键通信的关注具有领先的端到端可见性，以确保安全交付超出医疗保健和生命科学行业的苛刻期望。
              我们的生命科学物流服务包括：</p>
            <ul>
              <li>交钥匙项目管理</li>
              <li>加快通关手续</li>
              <li>供应商管理</li>
              <li>温度控制货件/时间敏感货件</li>
              <li>产销监管链安全供应链管理</li>
              <li>门到门国际和国内加急</li>
              <li>FDA，USDA和全球海关合规咨询</li>
              <li>定制包装和板条箱满足各种温度要求</li>
              <li>运输网络优化</li>
            </ul>
            <h2>了解您的生命科学物流挑战</h2>
            <p>从高度关键的医疗设备和药品运输，包括监管链，白手套的交付和关键的温度控制要求，以及紧急救生手提服务。
              作为您的物流提供商，Crane Worldwide Logistics的生命科学团队将确保通过正确的解决方案应对您的物流挑战。</p>
            <h2>冠状病毒（COVID-19）支持</h2>
            <p class="gt-block">由于供应链面临着冠状病毒COVID-19大流行所造成的充满挑战的环境，全球生命科学物流团队正在为我们的客户提供医疗用品和紧急货运的支持，
              <strong><a
                  href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E8%B5%B7%E9%87%8D%E6%9C%BA%E5%85%B3%E5%BF%83-covid-19/">阅读更多</a></strong>。
              如果目前我们可以为您的生命科学物流业务提供支持，请与我们联系。&nbsp;</p></div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">了解更多......</h3>
          <press-list type="case"></press-list>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {lifeData} from "@/assets/mock/industry";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, CommonBanner},
  data() {
    return {
      lifeData
    }
  }
}
</script>

<style scoped>

</style>