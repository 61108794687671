<template>
  <div>
    <common-banner :bannerData="shipmentData.bannerData"></common-banner>

    <service-intro :infoData="shipmentData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1><strong>货件追踪变得容易。</strong></h1>
            <p>C-View 是我们的虚拟视点，客户可以通过 Crane Worldwide Logistics 自由访问和跟踪他们的货物。</p>
            <p>我们的平台允许客户访问特定的运输细节和他们所需的文件，查看财务支出并检查整体性能。 C-View
              是一种交互式工具，使客户可以直接通过电子邮件发送给他们的客户负责人，以便快速回复任何问题。</p>
            <p>可以为每个用户和客户明确设置 C-View 仪表板。 我们的技术团队可以通过 C-View
              中显示的货件开发独特的体验和视觉方面。</p>
            <p><img alt="笔记本电脑视图中的 c 视图平台" class="align_center hidpi"
                    src="https://www.craneww.com/site/assets/files/3071/c-view_laptop.575x0-is-hidpi.jpg" width="575">
            </p>
            <h3><strong>集中仪表板</strong></h3>
            <p>通过移动设备友好的仪表板可快速了解您当前的运输和装运状态。 轻松识别异常，以便您主动管理它们。</p>
            <p><img alt="C-View 桌面主页仪表板" class="align_center hidpi"
                    src="https://www.craneww.com/site/assets/files/3071/c-view_desktop_home_dashboard_1.594x0-is-hidpi.png"
                    width="594"></p>
            <h3><strong>货运追踪</strong></h3>
            <p>强大的搜索功能可让您完全访问所有跟踪和追踪以及货件详细信息，文档和发票，导出功能等。</p>
            <p><img alt="C-View 桌面主页仪表板替代视图" class="align_center hidpi"
                    src="https://www.craneww.com/site/assets/files/3071/c-view_desktop_home_dashboard_2.594x0-is-hidpi.png"
                    width="594"></p>
            <h3><strong>潜在异常</strong></h3>
            <p>预测性可见性可为您提供可能会影响装运的供应链中断的预警，例如天气事件，罢工等。</p>
            <p><img alt="C-View 桌面主仪表板附加视图" class="align_center hidpi"
                    src="https://www.craneww.com/site/assets/files/3071/c-view_desktop_home_dashboard_3.594x0-is-hidpi.png"
                    width="594"></p>
            <h3><strong>采购订单管理</strong></h3>
            <p>我们的采购订单管理系统PinPOint将PO订单项目详细信息集成到从订单开始到交付的整个物流流程中。</p>
            <p class="gt-block" style="text-align: center;"><a center=""
                                                               class="button button_size_lg button_color_secondary"
                                                               href="https://craneww.com/request-a-quote/">请求 C-View
              演示</a></p></div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">额外的资源</h3>
        <press-list></press-list>
      </div>
    </div>

  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {shipmentData} from "@/assets/mock/technology";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      shipmentData
    }
  }
}
</script>

<style scoped>

</style>