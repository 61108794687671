<template>
<div>
  <common-banner :banner-data="careersData.bannerData"></common-banner>
  <div class="section">
    <div class="container container_size_sm">
      <div class="section__body type"><h2><strong>物流职业</strong></h2>
        <p>我们的员工是我们的业务，我们致力于在整个物流职业生涯中实现最高的员工满意度。&nbsp;</p>
        <p class="gt-block">在 Crane Worldwide Logistics，我们认识到员工是我们最大的资产。和 <strong><a
            href="/%E5%9C%B0%E7%82%B9/">全球各地的地点</a></strong>，我们是一个以客户为导向的组织，重视以结果为导向、具有强烈紧迫感的个人。我们赋予员工果断、创新和创造力的能力。物流职业种类繁多，探索我们开放的机会！&nbsp;
        </p>
        <h3><strong>我们成为理想工作场所的使命始于您！</strong></h3>
        <p>在 Crane Worldwide Logistics，我们拥有独特的企业文化、为客户提供的物流解决方案以及我们迄今为止取得的成就，这要归功于我们自豪地称之为
          Crane 员工的优秀团队。 想开启您的职业道路吗？
          我们在国际物流领域拥有激动人心的机会，从入门级物流工作到经验丰富的物流管理职位等等......我们正在招聘，快来加入我们的团队吧！</p>
        <div class="card card_size_lg bg_primary-dark">
          <div class="card__body c_white">
            <div class="grid grid_md m_0 align-items_center">
              <div class="grid__item spacing"><h2 class="h3 c_white">准备好迎接挑战了吗？</h2>
                <p class="text_size_lg">寻找物流工作？ 申请Crane Worldwide Logistics的工作！</p></div>
              <div class="grid__item size_auto spacing text_align_center">
                <div><a class="button button_size_lg button_color_secondary"
                        href="https://recruiting2.ultipro.com/CRA1009CRWL">探索空缺职位</a>&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2><strong>人才管理</strong></h2>
        <p>
          Crane大学于2017年二月启动，其使命是为内部客户提供所有培训需求的中心地点，重点是合规性，工作指导，员工资源，专业和个人发展。</p>
        <p>我们将学习型组织建模并定义为一个能够发展其员工的知识，技能和能力的组织。
          一个组织，该组织创建一种持续学习和批判性思维的文化，以培养新思想并迅速满足不断变化的业务需求。</p>
        <p>所有员工都被期望并被授权发展他们的技能组合并分享知识，以推动组织提高效率和有效性。 在 Crane Worldwide
          Logistics，人才管理部门为内部和外部的关键培训计划提供支持，以努力支持每位员工在组织中为我们的外部客户提高效率和生产力。&nbsp;</p>
        <h3><strong>Crane Worldwide Logistics推动您的物流事业前进&nbsp;</strong></h3>
        <p>
          通过加入我们不断发展的，充满活力的全球组织来发展您的运输和物流事业，我们可以为您提供职业机会，以帮助您按照自己的节奏发展技能和进步。</p>
        <h3><strong>你想成为Crane的司机吗？</strong></h3>
        <p class="gt-block">我们的团队一直在美国各地寻找专业司机作为员工或业主运营商。 如果您想加入我们不断壮大的团队，请了解更多
          <strong><a href="https://drive4crane.com/">这里。</a></strong></p>
        <h3><strong>您是否知道Crane Worldwide Logistics提供领导力发展计划？&nbsp;</strong></h3>
        <p>我们的领导力发展计划（LDP）为我们的未来领导者提供了广泛的经验。 观看此视频以了解更多信息。</p>

        <h3><strong>平等机会</strong></h3>
        <p>Crane Worldwide
          Logistics为所有雇员和应聘者提供平等的就业和晋升机会，而无需考虑个人，种族，民族，国籍，性别，性取向，宗教，信仰，年龄，残疾或退伍军人身份。</p>
        <h3><strong>员工亮点</strong></h3>
        <p>&nbsp;</p>
        <div class="grid grid_md">
          <div class="grid__item grid__item_fill size_6">
            <div class="card">
              <div class="card__body text_align_center spacing"><img
                  class="radius_circle"
                  height="100" src="@/assets/images/about/careers-01.jpg" width="100">
                <h3 class="card__title">丹尼斯·凯塞多·比达尔</h3>
                <p class="text_subtle mt_0">人力资源和 GA 经理，哥伦比亚</p>
                <p>“我于 2012 年 XNUMX 月加入 Crane Worldwide Logistics，担任财务和行政经理。 然后，我有机会成为卡塔赫纳的分公司经理。
                  今天，我担任合规、项目和人力资源的国家主管。 我还是拉美地区 Crane Cares 的总管理经理。
                  我一直在寻找一家能够授权和挑战员工成为最好的专业人士的公司。 我们公司对员工成长和发展的承诺给我留下了深刻的印象。
                  我很荣幸能在这家公司结束我的职业生涯，因为我喜欢在这里工作。”</p></div>
            </div>
          </div>
          <div class="grid__item grid__item_fill size_6">
            <div class="card">
              <div class="card__body text_align_center spacing"><img
                  class="radius_circle" height="100"
                  src="@/assets/images/about/careers-02.jpg" width="100">
                <h3 class="card__title">斯科特鲍曼</h3>
                <p class="text_subtle mt_0">加拿大业务发展经理</p>
                <p>“当我于 2019 年 XNUMX 月首次加入 Crane 时，它在一家成熟的物流公司工作了很长时间，搬到新公司是一个艰难的决定。
                  快进两年，我为成为 Crane Worldwide 的一员感到无比自豪和感激。 Crane 体现了团队取得成功所需的一切。
                  拥有平易近人的领导团队和出色的队友，您无法要求更好的团队氛围。 我喜欢在 Crane Worldwide 度过的每一天。
                  鹤道是唯一的出路！”</p></div>
            </div>
          </div>
          <div class="grid__item grid__item_fill size_6">
            <div class="card">
              <div class="card__body text_align_center spacing"><img
                  class="radius_circle" height="100"
                  src="@/assets/images/about/careers-03.jpg" width="100">
                <h3 class="card__title">Megan Kelley</h3>
                <p class="text_subtle mt_0">企业应用副总裁</p>
                <p>“领导力发展计划使我能够体验和了解我们的业务的各个方面-从仓储，运营，财务，技术和管理。
                  这项计划的最好之处在于，作为一个年轻的专业人员，我一直感到有能力在Crane Worldwide产生影响。
                  能够成长为一家年轻而灵活的公司，这使我有机会向我们遍及全球的同事和行业领导者学习和合作。”</p></div>
            </div>
          </div>
          <div class="grid__item grid__item_fill size_6">
            <div class="card">
              <div class="card__body text_align_center spacing"><img
                  class="radius_circle"
                  height="100" src="@/assets/images/about/careers-04.jpg" width="100">
                <h3 class="card__title">伊万亚历山大</h3>
                <p class="text_subtle mt_0">全球副总裁，消费品零售</p>
                <p>“重要的是我加入了一个对教学和进步充满动力的雇主。 我想成为一个更全面的专业人士，并向我的同龄人学习。
                  在加入公司担任英国石油和天然气销售经理六年后，我现在 100%
                  专注于指导和建立一个世界级、快节奏的欧洲、中东、印度和非洲销售团队以及高级管理团队的一部分。 Crane
                  Worldwide Logistics 对我来说是一种很好的文化契合。”</p></div>
            </div>
          </div>
          <div class="grid__item grid__item_fill size_6">
            <div class="card">
              <div class="card__body text_align_center spacing"><img
                  class="radius_circle" height="100"
                  src="@/assets/images/about/careers-05.jpg" width="100">
                <h3 class="card__title">Grace Yang </h3>
                <p class="text_subtle mt_0">人力资源经理，中国上海</p>
                <p>“我喜欢Crane Worldwide Logistics，因为它是一个大家庭。 在过去的十年中，我们像家人一样一起工作，学习和发展。
                  无论何时，只要有需要，都可以随时获得支持和帮助。 谢谢你一直支持着我们，Crane！”</p></div>
            </div>
          </div>
          <div class="grid__item grid__item_fill size_6">
            <div class="card">
              <div class="card__body text_align_center spacing"><img
                  class="radius_circle"
                  height="100" src="@/assets/images/about/careers-06.jpg" width="100">
                <h3 class="card__title">Carmen Gutierrez</h3>
                <p class="text_subtle mt_0">墨西哥财务高级总监</p>
                <p>“我非常感谢过去 10 年来在墨西哥开始我与 Crane Worldwide 的旅程以来信任我的人。 Crane
                  拥有优秀的关键领导者和良好的声誉。 Crane 关心人和环境，拥有优秀的文化和价值观。 我有我的第二个家庭在工作！
                  在过去的十年中，公司取得了成功和成长，这是我继续为公司目标做出贡献的巨大动力。 我喜欢并喜欢在这里工作，感谢
                  Crane！”</p></div>
            </div>
          </div>
          <div class="grid__item grid__item_fill size_6">
            <div class="card">
              <div class="card__body text_align_center spacing"><img
                  class="radius_circle"
                  height="100" src="@/assets/images/about/careers-07.png" width="100">
                <h3 class="card__title">Artrell Harris</h3>
                <p class="text_subtle mt_0">德克萨斯州达拉斯仓库物流主管</p>
                <p>“我于2013年XNUMX月以物料搬运员的身份加入了Crane Worldwide，不知道将来我在公司的工作地点。
                  自加入Crane以来，我可以说是真正的见面并增加了一个家庭，如果我没有机会为这个令人惊叹的组织工作，我将是一个陌生的人。
                  在才华横溢的领导下，我得以逐步进入管理团队。 我热衷于提供优质的客户服务，并与全球Crane
                  Worldwide相关的每个人建立牢固的关系。 我可以说起重机之路已经成为我的方式。”</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <quotation></quotation>
</div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {careersData} from "@/assets/mock/about";
import Quotation from "@/components/quotation.vue";

export default {
  name: "careers",
  components: {Quotation, CommonBanner},
  data() {
    return {
      careersData
    }
  }
}
</script>

<style scoped>

</style>