<template>
  <div>
    <common-banner :banner-data="securityData.bannerData"></common-banner>

    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h3>当今的全球供应链是一个危险丛生的产业，面对着不断升级的安全威胁，而常规的监管项目仅能解决其中一部分的问题。&nbsp;&nbsp;</h3>
          <p>Crane Worldwide Logistics的主要关注点是供应链安全。 Crane Worldwide
            Logistics利用与全球政府机构和私营行业团体的紧密关系，为每个设施，每个仓库和每批货物提供最高级别的安全性，我们获得的许可证和证明证明了这一点（适用时）来自以下组织：</p>
          <p class="gt-block"><em>海关贸易反恐合作组织（C-TPAT），授权经济运营商（AEO），世界海关组织（WCO），运输资产保护协会（TAPA），安全贸易商业联盟（BASC），医药货物安全联盟，ASIS，和国际航空运输协会（IATA）</em>
          </p>
          <p>此外，Crane Worldwide
            Logistics与运输安全管理局（TSA）和国际上许多类似的航空货运当局紧密合作，认真履行其作为认证的间接航空承运人（IAC）的职责，以确保安全，有保障和可靠运输我们的客户货运。&nbsp;</p>
          <h3>海关反恐贸易伙伴关系（C-TPAT）和授权经济运营商（AEO）风格计划</h3>
          <p>在9/11事件之后，供应链永远发生了变化。 在某种程度上，除了加强解决航空货运安全的措施外，还制定了贸易安全计划以解决多模式商业中的安全问题。
            美国海关与边境保护局（CBP）是第一个制定名为“反恐海关贸易伙伴关系”计划的人。
            随后，世界海关组织（WCO）根据SAFE标准框架开发了授权经济运营商（AEO）计划，随后被全球许多海关当局全部或部分采用。
            这些通常称为AEO的贸易安全程序的功能类似，并且可以使用不同的名称，例如“保护合作伙伴”（PIP）/加拿大，“黄金名单”
            /“约旦”或“安全贸易伙伴关系”（STP）/新加坡。 &nbsp;</p>
          <p class="gt-block">有关美国海关与边境保护局 C-TPAT 计划的更多信息，请参阅 <a
              href="https://www.cbp.gov/border-security/ports-entry/cargo-security/ctpat">这里。</a>&nbsp;</p>
          <p>Crane Worldwide Logistics拥有数项AEO认证，并且我们还通过C-TPAT认证：1.）航空货运合并者，海洋中介/
            NVOCC，以及2.）作为有执照的报关行经纪人。 通过这些措施，Crane Worldwide
            Logistics确保内部安全措施的完整性，并将重要的安全信息传达给业务合作伙伴。</p></div>
      </div>
    </div>
    <quotation></quotation>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {securityData} from "@/assets/mock/about";
import Quotation from "@/components/quotation.vue";

export default {
  components: {Quotation, CommonBanner},
  data() {
    return {
      securityData
    }
  }
}
</script>

<style scoped>

</style>