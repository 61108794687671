<template>
  <div>
    <common-banner :bannerData="industrialData.bannerData"></common-banner>

    <service-intro :infoData="industrialData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>提前计划 - 支持您的业务的物流服务</h1>
            <p>Crane Worldwide Logistics了解工业和制造公司面临的物流挑战。 我们在工业/制造与工程领域非常精通。
              我们的供应链技术，流程和专门的客户管理确保我们提供完整的供应链解决方案，以应对客户的物流挑战。&nbsp;</p>
            <h2>工业物流服务</h2>
            <p>作为您的物流提供商，我们提供广泛的多方面服务：采购订单管理，机器/生产线移动，项目管理，并具有随时为您的行业服务的全球整合网络。
              我们在项目物流方面的专业知识可以为您提供超出规格和重型物流需求的支持。&nbsp;</p>
            <blockquote><p class="gt-block">你准备好迎接当前 <strong>大趋势</strong> 在工业市场中，例如：4IR、物联网、3D
              打印、机器人技术？</p>
              <p class="gt-block"><strong>联系我们的专家，讨论当今最先进的解决方案。&nbsp;</strong></p></blockquote>
            <h2>了解您的物流挑战</h2>
            <p>在Crane Worldwide Logistics，我们以敏锐的紧迫感而自豪。
              我们与客户合作，以了解现在和将来存在的行业挑战。</p></div>
        </div>
        <div class="container container_size_sm spacing">
          <hr class="hr">
          <h3 class="h3">了解更多......</h3>
          <press-list type="case"></press-list>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CommonBanner from "@/components/commonBanner.vue";
import {industrialData} from "@/assets/mock/industry";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      industrialData
    }
  }
}
</script>

<style scoped>

</style>