<template>
  <div class="section bg_shade-dark">
    <div class="section__container container">
      <div class="size_8_md mx_auto">
        <div class="card card_size_xl">
          <div class="card__body spacing c_white text_align_center"><h2 class="h3 c_white">加入Crane团队</h2>
            <p class="text_size_lg">我们的员工也是我们的客户，我们追求达到最高的员工满意度。</p>
            <p class="gt-block"><a class="button button_size_lg button_color_secondary"
                                   href="/about?type=careers">了解更多</a></p></div>
          <img alt="" class="card__background"
               height="800" src="@/assets/images/join.jpg" width="1000">
          <div class="card__screen"></div>
        </div>
      </div>
    </div>
    <img alt="" class="section__background"
         height="911" src="@/assets/images/join-bg.jpg" width="1530"></div>
</template>

<script>
export default {
  name: "join"
}
</script>

<style scoped>

</style>