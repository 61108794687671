<template>
  <div>
    <common-banner :bannerData="batteryData.bannerData"></common-banner>

    <service-intro :infoData="batteryData.info"></service-intro>
    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>电池物流：货运、仓储和运输</h1>
            <p>随着世界各地对电池的需求不断增加，汽车电动汽车市场和消费品（包括电池供电的手机和个人电脑）等行业需要安全可靠的空运、海运或公路运输。设备和装置必需的锂电池。&nbsp;</p>
            <p>从生产到回收，Crane Worldwide Logistics
              可以提供流程来支持您的业务满足电池运输和存储的复杂物流要求。&nbsp;</p>
            <p><img alt="电池生产到回收的工艺流程" class="align_center"
                    src="https://www.craneww.com/site/assets/files/3659/battery_logistics_supply_chain_image-1.848x0-is.png"
                    width="848"></p>
            <h3>&nbsp;</h3><h4>资源</h4>
            <p>在锂矿开采过程中，可为重型设备搬迁、矿山装卸、大包等超大件安全搬运提供后勤保障。
              此外，我们的物流专家可以为矿业项目提供国际运输和保税仓储服务。 完整的运输服务包括散货运输、拼箱/拆箱以及港口服务和交付到最终目的地。&nbsp;</p>
            <h4>旅行箱配件</h4>
            <p>在电池预生产阶段，我们的服务包括树脂管理和石墨、铝基板以及聚乙烯 (PE) 和聚丙烯 (PP) 隔膜的处理。
              此外，我们的国际运输、仓储和仓储能力可以为您的业务提供支持。&nbsp;</p><h4>电池制造商</h4>
            <p>从电池袋到圆柱形或棱柱形电池，我们的物流专家可以为电池制造商提供运输和物流服务。 这还包括电池组装、精加工和包装。
              电极制造可能很复杂，我们可以根据您的要求设计物流解决方案。&nbsp;</p><h4>电池制造商</h4>
            <p>包装组装过程中需要进行质量控制和测试，经常使用热界面材料、超声波焊接和激光焊接技术。
              在整个模块组装过程中，我们的增值服务可以支持电池制造商确保完全符合成品电池组的完成。&nbsp;</p><h4>
              产品制造商</h4>
            <p>交付安装到电动汽车中的电池组通常需要准时制或准时制配送服务，以符合高效的生产技术。 Crane Worldwide
              Logistics 可以支持您的额外增值要求，例如组件组装以支持您的生产设施。&nbsp;</p><h4>经销商</h4>
            <p>对于分销商，Crane Worldwide Logistics 可以协助包装和标签合规以及批发分销。&nbsp;&nbsp;</p><h4>回收利用</h4>
            <p>我们的工厂可以提供确保电池组有效回收的售后服务，包括锂电池运输的接收和分类等服务。</p>
            <h2>电池物流服务</h2>
            <p class="gt-block"><strong>交通</strong></p>
            <ul>
              <li>超大电池组运输（项目物流）</li>
              <li>模块、电池和电池组的集装箱装载</li>
              <li>端到端 <a
                  href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E9%94%82%E7%A6%BB%E5%AD%90%E7%94%B5%E6%B1%A0iata%E8%AE%A4%E8%AF%81/">CEIV认证</a>
                与战略航空公司合作伙伴的安全运输&nbsp;
              </li>
              <li>海运集装箱优化&nbsp;</li>
            </ul>
            <p class="gt-block"><strong>包装</strong></p>
            <ul>
              <li>外包装解决方案&nbsp;</li>
              <li>模块、电池、电池组以及预生产组件的封装解决方案&nbsp;</li>
              <li>报废电池的包装解决方案&nbsp;</li>
              <li>OEM 和 Tier1 生产材料电池的可重复使用解决方案 UN 认证&nbsp;</li>
            </ul>
            <p class="gt-block"><strong>商业咨询
            </strong></p>
            <ul>
              <li>从采矿到回收的端到端360度业务规划&nbsp;</li>
              <li>限制产品空运航线优化</li>
              <li>限制产品海运航线优化&nbsp;</li>
            </ul>
            <p class="gt-block"><strong>文档&nbsp;</strong></p>
            <ul>
              <li>端到端文档支持&nbsp;</li>
              <li>测试证书支持&nbsp;</li>
              <li>危险品证书&nbsp;</li>
            </ul>
            <div class="card card_size_lg bg_primary-dark">
              <div class="card__body c_white">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item spacing"><h2 class="h3 c_white">联系我们</h2>
                    <p class="text_size_lg">需要电池运输方面的支持？</p></div>
                  <div class="grid__item size_auto spacing text_align_center">
                    <div><a class="button button_size_lg button_color_secondary"
                            href="https://craneww.com/request-a-quote/">要求报价</a>&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <h2>电池包装</h2>
            <p><img alt="用盒子运输电池" class="align_right"
                    src="https://www.craneww.com/site/assets/files/3659/gettyimages-970445418.320x0-is.jpg" width="320">与大多数危险品或危险材料一样，电池和电池供电设备需要安全可靠的包装，以最大限度地降低运输过程中的风险。
              要安全地包装和运输电池，请务必遵循保护您的产品的指南。 为避免电池短路，建议包装材料，如吸塑包装和缓冲，并仔细选择外包装。&nbsp;
            </p>
            <p class="gt-block"><a href="https://www.iata.org/">国际航空运输协会</a>&nbsp;提供有关电池组运输的综合指南和包装建议，详情可在此处下载。&nbsp;
            </p>
            <p align="center" class="gt-block"><a center="" class="button button_size_lg button_color_secondary"
                                                  href="https://www.iata.org/contentassets/05e6d8742b0047259bf3a700bc9d42b9/lithium-battery-guidance-document-2021.pdf"
                                                  onclick="ga('send', { hitType: 'event', eventCategory: 'PDF', eventAction: 'download', eventLabel: 'IATA Battery Transportation' });">现在下载</a>
            </p>
            <hr>
            <h2><img alt="CEIV卓越物流锂电池印章" class="align_right"
                     src="https://www.craneww.com/site/assets/files/3659/ceiv-lithium-stamp_rgb_large.220x0-is.png"
                     width="220">IATA CEIV认证-锂离子电池</h2>
            <p>
              锂离子电池的运输和储存需要严格的安全协议，以确保锂离子电池、组件和包含锂离子电池的产品的正确处理、包装和运输方法。</p>
            <p>IATA 的 CEIV 认证计划专门针对锂离子运输，是一个严格的认证流程，可确保运营、人员和设施遵守 IATA
              专家提供的标准、政府法规和指南。 Crane Worldwide Logistics 在关键地点获得了 IATA 认证。&nbsp;</p>
            <p class="gt-block">您可以阅读更多关于认证奖励和锂离子电池运输的信息&nbsp;<a
                href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E9%94%82%E7%A6%BB%E5%AD%90%E7%94%B5%E6%B1%A0iata%E8%AE%A4%E8%AF%81/"><strong>这里。</strong></a><br>&nbsp;
            </p>
            <p>&nbsp;</p></div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">水户市民会馆 详细信息</h3>
        <press-list></press-list>
      </div>
    </div>

  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {batteryData} from "@/assets/mock/service";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "air",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      batteryData
    }
  }
}
</script>

<style scoped>

</style>