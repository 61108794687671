<template>
  <div>
    <div class="section by">
      <div class="container spacing_xl"><h1 class="h1 text_align_center">
        通过全球物流解决方案为我们的客户解决运输挑战，如果我们能提供帮助，请联系我们！</h1>
        <div class="grid grid_md">
          <div v-for="(item,index) in caseData.list" :key="index" class="grid__item size_12 size_3_sm">
            <div class="spacing">
              <a :href="'/caseDetail?id='+item.id">
                <img :src="item.bannerImage" alt="" class="radius" height="250" width="250">
              </a>
              <div>
                <a :href="'/caseDetail?id='+item.id">
                  <h3 class="h4">{{ item.title }}</h3>
                </a>
              </div>
              <p>{{ item.desc }}</p>
              <a :href="'/caseDetail?id='+item.id" class="button button_outline_secondary">
                <span>了解更多</span>
                <svg class="icon" role="img">
                  <use xlink:href="#icon-arrow-right"></use>
                </svg>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {caseData} from "@/assets/mock/news";

export default {
  data() {
    return {
      caseData
    }
  }
}
</script>

<style scoped>

</style>