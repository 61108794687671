<template>
  <div>
    <common-banner :banner-data="aboutData.bannerData"></common-banner>

    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h1><strong>物流领先的遗产&nbsp;</strong></h1>
          <p class="gt-block">2008年， <a
              href="/leader?id=1">Jim
            Crane</a>，一位物流行业的资深人士，创立了 Crane Worldwide，其愿景是为我们的客户提供最高水平的全球贸易，为我们的团队成员创造成就感，并提升我们工作所在的社区。&nbsp;
          </p>
          <p>Jim Crane 在物流领域的历史可以追溯到 1984 年，当时他创立了 Eagle Global Logistics (EGL)，到 10,000
            年，该公司从一家小型国内货运代理发展成为拥有 4 名员工和 2007 亿美元收入的全球顶级物流供应商。Jim 和他的团队前
            EGL 高管联合起来创办了一家公司，该公司有机会利用最新技术重新开始，并重新致力于卓越、客户服务和创新。</p>
          <p class="gt-block">在 Crane Worldwide Logistics，我们为自己令人瞩目的增长感到自豪，我们在十年内实现了 1
            亿美元的收入，业务遍及各大洲及世界各地 130 个国家/地区的 30 个地点。</p>
          <p class="gt-block">作为一家总部位于 休斯顿，美国德克萨斯州，我们致力于投资战略领域，这将使我们能够为全球合作伙伴提供更大的价值。
          </p>
          <div class="grid grid_md">
            <div class="grid__item grid__item_fill size_6" v-for="(item,index) in aboutData.navList" :key="index">
              <a class="listing__item"
                 :href="item.path">
                <svg aria-hidden="true" class="icon listing__icon" role="img">
                  <use xlink:href="#icon-arrow-right"></use>
                </svg>
                <div class="listing__body"><h3 class="listing__title">{{ item.name }}</h3>
                  <p>{{ item.desc }}</p>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {aboutData} from "@/assets/mock/about";

export default {
  components: {CommonBanner},
  data() {
    return {
      aboutData
    }
  }
}
</script>

<style scoped>

</style>