<template>
  <div>
    <common-banner :bannerData="landData.bannerData"></common-banner>

    <service-intro :infoData="landData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>美国运输管理、国内卡车运输服务等等...</h1>
            <p>提供货运服务。凭借规模庞大的国内卡车运输车队以及强大的国际公路货运合作伙伴关系来支持我们的卡车货运服务，我们提供从加急和门到门到整合和专用车队的各种公路货运解决方案。&nbsp;</p>
            <p>
              为了满足客户的动态需求，我们可以在地方、区域和国家层面安排车队管理。全美各地的国内卡车运输服务，满足您的所有运输需求。</p>
            <ul>
              <li>全方位的国内运输管理服务。</li>
              <li>本地国内取货和交付，区域商业运输，海运和铁路运输。</li>
              <li>紧急卡车运输和/或高价值地面运输的快速服务。</li>
              <li>根据需要进行专用的车队管理。</li>
            </ul>
            <h2>提供的服务包括：</h2>
            <ul>
              <li>遍布美国、加拿大和墨西哥的地面容量解决方案。</li>
              <li>卡车和铁路&nbsp;</li>
              <li>传统且高安全性&nbsp;</li>
              <li>团队/独奏</li>
              <li>单次装运的现货报价、短期通知和加急</li>
            </ul>
            <p class="gt-block"><strong>项目管理
            </strong> 从开始到结束，制定装载计划、模式优化、报告和可见性、卡车（所有类型）和铁路服务。&nbsp;</p>
            <h2>容量解决方案</h2>
            <p>我们在美国各地拥有地面货运解决方案，可以满足您的所有运力要求，包括：</p>
            <ul>
              <li><strong>厢型车/加急&nbsp;</strong>- 干货车、直货车、短跑货车、冷藏货车</li>
              <li><strong>特种货物</strong> - 开放式甲板、可伸缩拖车</li>
              <li><strong>OOG/重载 </strong>- 路线勘察、许可证、现场协调、起重机和索具。</li>
              <li><strong>高安全性 </strong>- GPS可见性、全套物流拖车、白手套服务</li>
              <li><strong>项目管理
              </strong>- 起重机和索具、现场勘察、库存报告、室内和室外存储
              </li>
              <li><strong>集装箱拖运</strong> - 港口/铁路拖运、转运、LFD管理</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">最新文章</h3>
        <press-list type="industry"></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import {landData} from "@/assets/mock/service";
import CommonBanner from "@/components/commonBanner.vue";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "land",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      landData
    }
  }
}
</script>

<style scoped>

</style>