<template>
  <div class="section bg_shade-dark">
    <div class="section__container container">
      <div class="size_8_md mx_auto">
        <div class="card card_size_lg bg_primary-dark">
          <div class="card__body c_white">
            <div class="grid grid_md m_0 align-items_center">
              <div class="grid__item spacing">
                <h2 class="h3 c_white">要求报价</h2>
                <p class="text_size_lg">让我们的客户服务人员将为您量身定制适合您的物流需求的解决方案。</p>
              </div>
              <div class="grid__item size_auto spacing text_align_center">
                <div>
                  <a class="button button_size_lg button_color_secondary"
                     href="/quotation">要求报价</a>
                </div>
                <div class="text_lead c_white">+1 888-870-2726</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img alt="" class="section__background"
         height="911" src="@/assets/images/quotation.jpg" width="1530"></div>
</template>

<script>
export default {
  name: "quotation"
}
</script>

<style scoped>

</style>