<template>
  <div>
    <common-banner :bannerData="trainData.bannerData"></common-banner>

    <service-intro :infoData="trainData.info"></service-intro>

    <div class="section">
      <div class="container container_size_sm spacing">
        <div class="grid grid_md">
          <div class="grid__item size_12 type"><h1>什么是铁路货运解决方案？</h1>
            <p>作为您的物流提供商，Crane Worldwide Logistics坚信成为客户的拥护者。 我们的目标是为您提供最高效的服务，满足您从中国到欧洲国家的物流需求。
              通过西伯利亚大铁路连接12,000公里的丝绸之路铁路轨道，是从中国到欧洲越来越流行的运输方式。
              因此，中国对欧洲的铁路货运解决方案正迅速成为流行的货运物流解决方案。</p>
            <p>Crane Worldwide Logistics 的团队已经认识到我们的国际客户面临着许多挑战，这些客户传统上使用空运和
              FCL（整箱装载）/LCL（小于集装箱装载）海运服务。
              从中国到欧洲的铁路货运集装箱运输解决方案一直是通过丝绸之路降低运输成本的关键，这是一种充分利用中亚中国铁路的环保解决方案。</p>
            <p>我们开发了一种铁路货运解决方案，可以为遇到的影响客户供应链的生产延迟、高库存水平、产能限制和冗长的运输时间等问题提供无缝服务。
              中国铁路集装箱运输可能是您货物的一种选择。&nbsp;</p>
            <p class="gt-block">Crane Worldwide Logistics提供了一种铁路货运解决方案，可将&nbsp;<a
                href="https://craneww.com/locations/p.r-china/" target="_blank"><strong>中国</strong></a> 和 <a
                href="https://craneww.com/locations/japan/" target="_blank"><strong>日本</strong></a>, <a
                href="https://craneww.com/locations/korea/" target="_blank"><strong>Korea</strong></a>及 <a
                href="https://craneww.com/locations/vietnam/" target="_blank"><strong>越南</strong></a> 到欧洲。 每 72
              小时，我们的客户就有机会使用从中国到欧洲的铁路解决方案。 路线是经过阿拉山口（16-18 天），而不是经常使用的满洲里位置（19-21
              天）。 我们将为您的业务需求选择最高效的铁路路线。 需要更多关于中国到欧洲火车路线的信息吗？ 保持联系。</p>
            <p class="gt-block">使用货运列车作为替代物流服务 <a href="https://www.semrush.com/solutions/air-freight/"
                                                                target="_blank">空运</a> or <a
                href="https://www.semrush.com/solutions/ocean-freight/" target="_blank">海运</a>，我们可以保证将库存水平降低40％。
              Crane Worldwide的铁路货运专家致力于为您的货物需求提供最佳的运输解决方案。</p>
            <h3>铁路货运-常见问题</h3>
            <p class="gt-block"><strong>使用铁路货运解决方案从中国到欧洲的运输时间需要多长时间？</strong><br>根据始发地/目的地，通常使用火车从终点站到终点站大约需要16-19天。
            </p>
            <p class="gt-block"><strong>火车在旅途中停了几站？</strong><br>由于规格不同，火车至少要停2个站才能将集装箱切换到其他有轨电车上。
              （俄罗斯规格：1,520mm;欧洲及中国规格：1,435mm）</p>
            <p class="gt-block"><strong>铁路货运列车多久运营一次？</strong><br>根据始发站的不同，每周有几次从中国飞往欧洲和从欧洲飞往中国的航班。
            </p>
            <p class="gt-block"><strong>使用铁路货运的货物安全性如何？</strong><br>铁路货运是安全的，边境航站楼的保护方式与海洋航站楼类似。
              行进中的火车无法停止，因此您的货物被盗的机会与海运相似。</p>
            <p class="gt-block"><strong>集装箱的载货要求是否与其他货运方式相同？</strong><br>对振动敏感的货物可用防振托盘保护。
              另外，铁路货运运营商在装货之前，期间和之后都需要照片，以确保无缝的海关流程。</p>
            <p class="gt-block"><strong>集装箱的最大有效载荷是多少？</strong><br>目前，每个集装箱的有效载荷是标准的，并且与海运集装箱类似，因为我们使用的是ISO
              40ft标准。 容器</p>
            <p class="gt-block"><strong>可以20英尺。 集装箱是通过铁路运输的？</strong><br>Crane Worldwide建议使用40英尺。
              铁路运输的容器，作为整个轨道车，必须向操作员预订。 一辆轨道车可容纳40英尺。 容器。 可以加载2 x 20ft。
              将集装箱装到一辆有轨电车上； 但是，铁路运营商倾向于在码头卸货，等待额外的20英尺，以最大程度地利用可用的铁路货运空间。
            </p>
            <h3>与您合作满足您所有的铁路货运需求。</h3>
            <p>我们的目标是与您一起了解当前和将来的物流挑战。 了解您的计划和预测您的未来需求将使团队能够提供无缝的门到门运输，从而满足您的铁路货运需求。
              可靠，安全且具有成本效益的铁路货运服务可以改变您的物流运作。 在这个不确定的时期，由于冠状病毒在世界范围内的影响，如果我们可以提供帮助，请联系以下！&nbsp;</p>
            <blockquote><p class="gt-block"><strong>您知道吗？</strong> 也可以从其他亚洲国家使用铁路货运服务。
              例如，我们可以通过铁路货运从越南运输到欧洲，您可以阅读更多 <strong><a
                  href="/%E7%9F%A5%E8%AF%86%E4%B8%AD%E5%BF%83/%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF%E5%92%8C%E4%BF%A1%E6%81%AF/%E4%B8%AD%E5%9B%BD%E9%93%81%E8%B7%AF%E8%B4%A7%E8%BF%90-a/">这里。</a></strong>
            </p></blockquote>
            <h3>冠状病毒（COVID-19）支持</h3>
            <p class="gt-block">由于供应链面临着由冠状病毒COVID-19大流行造成的充满挑战的环境，全球物流团队为我们的客户提供医疗用品和紧急货运需求的支持；
              读<strong><a href="https://craneww.com/knowledge-center/latest-news-and-info/crane-cares-covid-19/">&nbsp;更多</a></strong>。
              如果您正好也需要我们为您的物流业务提供支持，请与我们联系。&nbsp;</p>
            <div class="card card_size_lg bg_primary-dark">
              <div class="card__body c_white">
                <div class="grid grid_md m_0 align-items_center">
                  <div class="grid__item spacing"><h2 class="h3 c_white">接收最新的物流新闻</h2>
                    <p class="text_size_lg">所有最新最快的市场信息可以直接发送到您的收件箱</p></div>
                  <div class="grid__item size_auto spacing text_align_center">
                    <div><a class="button button_size_lg button_color_secondary"
                            href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/">成为会员</a>&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container container_size_sm spacing">
        <hr class="hr">
        <h3 class="h3">了解更多......</h3>
        <press-list></press-list>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {trainData} from "@/assets/mock/service";
import ServiceIntro from "@/components/serviceIntro.vue";
import PressList from "@/components/pressList.vue";

export default {
  name: "air",
  components: {PressList, ServiceIntro, CommonBanner},
  data() {
    return {
      trainData
    }
  }
}
</script>

<style scoped>

</style>