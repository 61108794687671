<template>
  <div>
    <common-banner :banner-data="complianceData.bannerData"></common-banner>

    <div class="section">
      <div class="container container_size_sm">
        <div class="section__body type"><h3>我们协助并建议您应对国际贸易挑战的最佳方法。 &nbsp;</h3>
          <p>
            我们的服务和高级操作系统（包括提供远程位置归档的功能）确保高度合规，同时提供及时发布，针对特定客户的报告和统计数据功能。</p>
          <p>我们的目标是确保您的交易得到正确处理和准确分类。
            所有数据都以电子方式捕获并报告给适当的政府机构，并提供给进口商可见性。</p>
          <p>作为一家守法合规的公司，Crane Worldwide Logistics的头等大事是货物安全。 Crane
            Worldwide充分利用与美国和世界各地政府机构的紧密关系，为每个工厂，每个仓库和每个货物提供最高级别的安全保障。
            为了履行其根据各种货物安全法规和标准维护安全供应链的职责，Crane
            Worldwide不断尝试寻找最有效的方法来保持100％的合规性，同时建立“最佳实践”。</p>
          <p>为了确保美国在客运和全货运飞机上的航空货运的持续安全，起重机世界物流与运输安全管理局（TSA）紧密合作，认真履行其作为认证的间接航空承运人（IAC）的职责。为客户的货运提供安全，可靠和可靠的服务。
            无论您是在下一次商务旅行中还是与我们一起运输航空货运，请放心，Crane Worldwide
            Logistics都会积极主动地为确保天空安全而不断努力。</p>
          <div class="card card_size_lg bg_primary-dark">
            <div class="card__body c_white">
              <div class="grid grid_md m_0 align-items_center">
                <div class="grid__item spacing"><h2 class="h3 c_white">接收最新的物流新闻</h2>
                  <p class="text_size_lg">所有最新最快的市场信息可以直接发送到您的收件箱</p></div>
                <div class="grid__item size_auto spacing text_align_center">
                  <div><a class="button button_size_lg button_color_secondary"
                          href="https://craneww.com/knowledge-center/latest-news-and-info/newsletter-signup/">成为会员</a>&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <quotation></quotation>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner.vue";
import {complianceData} from "@/assets/mock/about";
import Quotation from "@/components/quotation.vue";

export default {
  components: {Quotation, CommonBanner},
  data() {
    return {
      complianceData
    }
  }
}
</script>

<style scoped>

</style>